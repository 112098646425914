<script setup>
import { ref, computed, onMounted } from 'vue';
import { SignaturePad } from 'ui';
import TopazSignature from './TopazSignature.vue';
import EPadSignaturePad from './ePadSignaturePad.vue';

const WHITE_LIST_EPAD_HOSTNAMES = [
  'localhost',
  'dev.hospital.medosync.com',
  'uat.beacon.medosync.com',
  'beacon.medosync.com',
  'uat.hospital.medosync.com',
  'hospital.medosync.com'
];

const WHITE_LIST_EPAD_DEFAULT_HOSTNAMES = ['beacon.medosync.com', 'uat.hospital.medosync.com'];

const WHITE_LIST_BEACON_HOSTNAMES = ['uat.beacon.medosync.com', 'beacon.medosync.com'];

const props = defineProps({
  signature: {
    type: String,
    required: false
  },
  editMode: {
    type: Boolean,
    default: false
  },
  signatureHasWarnings: {
    type: Boolean,
    default: false
  }
});
const hostname = window.location.hostname;

const emit = defineEmits(['signature-captured', 'on-clear']);

const captureSignature = dataUrl => {
  emit('signature-captured', dataUrl);
};

const clearSignature = () => {
  emit('on-clear');
};

const signaturePadType = ref(null);

const selectSignatureType = type => {
  signaturePadType.value = type;
  localStorage.setItem('signaturePadType', type);
  closeDropdown();
};

onMounted(() => {
  if (!signaturePadType.value && WHITE_LIST_EPAD_DEFAULT_HOSTNAMES.includes(hostname)) {
    signaturePadType.value = 'epad';
  } else {
    if (localStorage.getItem('signaturePadType')) {
      signaturePadType.value = localStorage.getItem('signaturePadType');
    }
  }
});

const dropdownOpen = ref(false);

const closeDropdown = () => {
  dropdownOpen.value = false;
};

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

const dropdownText = computed(() => {
  if (signaturePadType.value === 'ipad') return 'Use iPad';
  else if (signaturePadType.value === 'topaz') return 'Use Signature Pad';
  else if (signaturePadType.value === 'epad') return 'Use Signature ePadLink';
  else return 'Select Signature Device';
});

const isBeacon = computed(() => {
  return WHITE_LIST_BEACON_HOSTNAMES.includes(hostname);
});
</script>

<template>
  <div>
    <div class="relative w-[300px] pb-10" v-click-outside="closeDropdown">
      <div
        class="flex items-center justify-between cursor-pointer border-medosync-blue px-5 py-3 w-full"
        :class="[dropdownOpen ? 'border-t-2 border-r-2 border-l-2 rounded-t-3xl' : 'border-2 rounded-full']"
        @click="toggleDropdown"
      >
        <i class="fa-solid fa-envelope"></i>
        <p>{{ dropdownText }}</p>
        <i class="fa-solid" :class="[dropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
      </div>

      <div
        :class="{ hidden: !dropdownOpen }"
        class="absolute z-[1000] bg-white flex flex-column justify-end w-full mb-4"
      >
        <div class="border-r-2 border-b-2 border-l-2 border-medosync-blue bg-white w-full overflow-auto">
          <ul class="bg-white text-sm text-gray-700">
            <template v-if="!isBeacon">
              <li
                @click="selectSignatureType('ipad')"
                class="block cursor-pointer px-2 py-1 hover:bg-[#00a3f4]/50 text-lg font-medium"
              >
                Use iPad
              </li>
              <li
                @click="selectSignatureType('topaz')"
                class="block cursor-pointer px-2 py-1 hover:bg-[#00a3f4]/50 text-lg font-medium"
              >
                Use Signature Pad
              </li>
            </template>
            <li
              v-else
              @click="selectSignatureType('epad')"
              class="block cursor-pointer px-2 py-1 hover:bg-[#00a3f4]/50 text-lg font-medium"
            >
              Use Signature ePadLink
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-show="signaturePadType">
      <p class="italic text-gray-700 my-5">
        Your signature appears in the field below.<br />
        You can recapture the signature or send the admission form.
      </p>
    </div>
    <SignaturePad
      v-if="signaturePadType === 'ipad'"
      id="signature-pad-element"
      class="mt-8 sm:mt-6"
      :signature="props.signature"
      :is-warning="props.signatureHasWarnings"
      :edit-mode="props.editMode"
      @signature-captured="captureSignature"
      @on-clear="clearSignature"
      :landScapeMode="false"
    />
    <TopazSignature
      v-if="signaturePadType === 'topaz'"
      class="mt-8 sm:mt-6 mb-4"
      :signature="props.signature"
      :edit-mode="props.editMode"
      @signature-captured="captureSignature"
    />
    <span
      v-if="signaturePadType === 'epad' && !WHITE_LIST_EPAD_HOSTNAMES.includes(hostname)"
      class="text-medosync-red font-bold"
    >
      ePadLink is not available. Please contact support for assistance and more information.
    </span>
    <EPadSignaturePad
      v-else-if="signaturePadType === 'epad' && WHITE_LIST_EPAD_HOSTNAMES.includes(hostname)"
      class="mt-8 sm:mt-6 mb-4"
      :signature="props.signature"
      :edit-mode="props.editMode"
      @signature-captured="captureSignature"
    />
  </div>
</template>
