<template>
  <div class="component-content-section">
    <div class="optional-nav">
      <SettingsMenu />
    </div>

    <div class="flex claim-title medosync-black"><span class="medosync-blue mr-2">Account Setting /</span> Add Tag</div>

    <div class="flex items-end">
      <div class="flex flex-col">
        <label class="mb-1 block text-sm font-medium text-gray-700">Tag Name</label>
        <input v-model="tagName" placeholder="Tag Name" type="text" />
      </div>

      <div class="ml-5">
        <label class="mb-1 block text-sm font-medium text-gray-700">Type</label>
        <select
          v-model="tagType"
          class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
          <option value="hospital">Hospital</option>
          <option value="user">User</option>
        </select>
      </div>

      <ColorPicker v-model="tagColor" class="ml-5" />
    </div>

    <div class="mt-5">
      <router-link class="inline-flex no-underline" to="/settings/tags-management">
        <button type="button" class="btn btn-hollow-blue btn-small">Cancel</button>
      </router-link>
      <button @click="createTag" type="button" class="btn btn-lightblue btn-small ml-5">Save</button>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import { userStore } from '@/store/user';

import ColorPicker from '@/components/misc/ColorPicker.vue';
import SettingsMenu from '@/views/settings/menu/Index.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'CreateTag',
  components: {
    ColorPicker,
    SettingsMenu,
    ToastComponent
  },
  data() {
    return {
      tagName: null,
      tagColor: null,
      tagType: 'hospital'
    };
  },
  methods: {
    async _build_request() {
      let requestData = {};

      const email = userStore.getters.user?.email;

      requestData.name = this.tagName;
      if (this.tagType == 'user') {
        requestData.namespace = { email: email, user_type: 'email', namespace: 'user' };
      }

      return requestData;
    },

    async createTag() {
      if (this.tagName == null || this.tagName == '')
        return this.$refs.toast.showToast('Tag Name required!', 'fa-solid fa-triangle-exclamation', 'error');

      const request_data = await this._build_request();
      console.log('request data', request_data);
      const oapi = new OpenAPIJSON();
      try {
        await oapi.tagCreate(request_data);
        this.$refs.toast.showToast('Tag Created!', 'fas fa-check', 'success');

        this.updatePreferences(request_data);
      } catch (err) {
        this.$refs.toast.showToast(err, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },

    async saveUserPreferences(data) {
      const oapi = new OpenAPIJSON();

      let response = await oapi.saveUserPreferences(JSON.stringify(data));

      if (response && response.status && response.status >= 300) {
        this.$refs.toast.showToast(response.data.status.reason, 'fa-solid fa-triangle-exclamation', 'error');
      } else {
        this.$refs.toast.showToast('Tags preference successfully saved!', 'fas fa-check', 'success');
        this.$router.push({ name: 'tagsManagement' });
      }
    },

    updatePreferences(tag) {
      if (this.tagColor) {
        tag.color = this.tagColor;
      } else {
        tag.color = '#00A7E1';
      }
      tag.active = true;

      let ls = JSON.parse(localStorage.getItem('preferences'));
      ls.tagsLayout.push(tag);
      localStorage.preferences = JSON.stringify(ls);
      this.saveUserPreferences(ls);
    }
  },

  mounted() {}
};
</script>

<style lang="scss" scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}
.roboto {
  font-family: 'Roboto';
}
.claim-title {
  font-size: 29px;
  font-weight: 600;
  background-color: #ededed;
  padding: 24px 64px;
  margin-bottom: 24px;
}
.btn {
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.btn-small {
  font-size: 14px;
  font-weight: 600;
}
.btn-lightblue {
  background-color: #00a7e1;
  color: #ffffff;
}
.btn-hollow-blue {
  background-color: rgba($color: #ffffff, $alpha: 0);
  color: #23a6f0;
  border: 1px solid #23a6f0;
}
.medosync-blue {
  color: #00a7e1;
}
.medosync-black {
  color: #1c1c1c;
}

input[type='text'] {
  border: 1px solid #00a7e1;
  border-radius: 5px;
  padding: 8px 16px;
}

select {
  border: 1px solid #00a7e1;
  border-radius: 5px;
  padding: 11px 16px;
}
</style>
