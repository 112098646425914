<template>
  <div
    ref="claimDetails"
    :class="[notesOpened ? 'third-width notes-transition-enter' : 'full-width notes-transition-exit']"
    class="py-8 px-6"
    v-if="claim"
  >
    <Breadcrumbs v-if="isTextBoxDisabled" :links="breadcrumbs" />
    <div v-else class="cursor-pointer w-fit" @click="backButton">
      <i class="fas fa-chevron-left mr-2.5"></i>
      back
    </div>

    <ClaimDetailsHeader
      :claim="claim"
      :filteredDetails="insurerPaymentFiles"
      :editMode="!isTextBoxDisabled"
      :allClaimFormsNum="allClaimForms?.length || 0"
      @init-claim="getClaim(true)"
      @refresh-files="refreshFiles"
      @scroll-to-field="scrollToField"
    />
    <TabNav
      v-if="isTextBoxDisabled"
      :navs="navs"
      :selected="selectedTab"
      :max-items="notesOpened ? navs.length - 1 : navs.length"
      :sub-menu-items="allClaimForms"
      :selected-claim-form-id="claimData?.claim_form_id"
      :claimId="claim?.claim_id"
      :claim-composite-id="claim?.claim_composite_id"
      @switch-tab="switchTab"
      @add-item="newClaimFormTabOpen = true"
      @select-item="selectClaimForm"
      @remove-item="removeClaimForm"
    />

    <div v-if="!newClaimFormTabOpen" class="flex flex-row justify-between items-center my-2">
      <div class="flex relative">
        <h2 :data-id-test="dataIdTest" class="text-xl font-medium cursor-pointer text-blue-900">
          {{ tabTitle }}<span v-if="currentClaimTypeName"> - {{ currentClaimTypeName }}</span>

          <i
            v-if="selectedTab == 'invoice' && isTextBoxDisabled"
            class="fa-solid fa-pen ml-2"
            @click="disableTextBox()"
            data-test-id="edit-pen-icon-invoice"
          ></i>
          <i
            v-if="selectedTab == 'compositeClaimform' && isTextBoxDisabled"
            class="fa-solid fa-pen ml-2"
            @click="disableTextBox()"
            data-test-id="edit-pen-icon-invoice"
          ></i>
          <i
            v-if="
              claimData &&
              selectedTab == 'compositeClaimform' &&
              claimData.claim_form_type != 'self_pay' &&
              allClaimForms?.length === 1 &&
              isTextBoxDisabled
            "
            class="fa-regular fa-trash-can ml-4"
            @click="deleteFirstForm"
            data-test-id="delete-claim-icon"
          ></i>
        </h2>
      </div>
      <!-- Patient FORM  -->

      <div
        data-id-test="preview-claim-form-btn"
        v-if="selectedTab == 'compositeClaimform'"
        class="cursor-pointer"
        @click="generateCompositePdf()"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview form</span>
      </div>
      <div
        data-id-test="preview-claim-form-btn"
        v-if="selectedTab == 'patientForm'"
        class="cursor-pointer"
        @click="generatePdf()"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview form</span>
      </div>

      <div
        data-id-test="preview-claim-form-btn"
        v-if="claim && selectedTab == 'invoice' && claim.insurance.insurer_name != 'Self Pay'"
        class="cursor-pointer"
        @click="handleInvoicePreview"
      >
        <i class="fa-regular fa-file-pdf fa-xl me-1"></i>
        <span class="underline text-sm pt-1">Preview invoice</span>
      </div>
    </div>

    <AdmissionClaimForm
      ref="claimForm"
      v-if="selectedTab === 'patientForm' && claimformData"
      :disableEditMode="false"
      :claimform="claimformData"
      :claim-section-warnings="claimSectionWarnings"
      :signature="patientSignature"
      :admission="claimData?.admission.on"
      @edit-mode="value => (formEditMode = value)"
      @set-signature="setAppointmentSignature"
      @update-patient-claimform="updatePatientClaimform"
      @generate-pdf="generatePdf"
      @refresh-data="refreshData"
    />

    <!-- v-if="selectedTab == 'claimForm' && claim.claim_requires_claimform" -->

    <CompositeClaimForm
      v-if="selectedTab == 'compositeClaimform' && !newClaimFormTabOpen && showForm"
      :claimInfo="claim"
      :claimDataPreviousState="claimDataPreviousState"
      @set-previous-form-state="setPreviousFormState"
      @update-header-info="updateHeaderInfo"
      ref="childComponentRefComposite"
    />

    <ClaiAttchments
      v-if="selectedTab == 'attachments'"
      :files="files"
      :claim="claim"
      :uploadVal="uploadVal"
      @remove-file-from-list="removeFileFromList"
      @refetch-files="listFiles"
    />
    <Invoice v-if="selectedTab == 'invoice'" :claim="claim" />
    <InsurerPaymentFiles
      v-if="selectedTab == 'paymentFiles'"
      :filteredData="insurerPaymentFiles"
      :appointmentId="claim?.app_id"
      :consultantId="claim?.admitted_by?.person_id"
      :claimId="claim?.claim_id"
      :claim="claim"
    />
    <SpinnerLoader v-if="isLoading" />
  </div>

  <DefaultModal
    v-if="removeSelectedItem"
    :showManageModal="true"
    :title="`Delete Claim Form `"
    :text="`Are you sure you want to delete this Consultant Claim?`"
    secondaryButtonText="Yes, delete <span class='block'>Claim Form</span>"
    primaryButtonText="Cancel"
    @secondary-changes="removeItem(removeSelectedItem)"
    @primary-changes="deleteFirstForm(true)"
    @close-modal="deleteFirstForm(true)"
  ></DefaultModal>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import _ from 'lodash';
import { AssocType } from 'enums';
import Breadcrumbs from './Breadcrumbs.vue';
import ClaimDetailsHeader from './ClaimDetailsHeader.vue';
import TabNav from './TabNav.vue';
import { OpenAPIJSON, OpenAPIPDF } from 'api/openapi';
import { notes_store } from '@/store/notes';
import loadingMixin from '@/components/mixins/loadingMixin';
import Invoice from '@/views/claims/invoices/Invoice.vue';
import ClaiAttchments from './ClaimAdditionalAttachments.vue';
import InsurerPaymentFiles from './InsurerPaymentFiles.vue';
import { mapState, mapMutations } from 'vuex';
import ToastComponent from '../../../components/claim-forms/components/ToastComponent.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import { nextTick } from 'vue';
import { ClaimPhase } from 'enums';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';
import AdmissionClaimForm from '../../admission/AdmissionClaimForm.vue';
import { mapFormValuesFromGet } from 'utils';
import { hospitalConfigStore } from '@/store/config';
import CompositeClaimForm from './CompositeClaimForm.vue';

const MAX_CLAIM_FORMS = 2;

export default {
  beforeRouteLeave(to, from, next) {
    this.disableTextBox();
    next();
  },
  name: 'CompositeClaimDetailsPage',
  props: {},
  components: {
    Breadcrumbs,
    ClaimDetailsHeader,
    TabNav,
    ClaiAttchments,
    Invoice,
    InsurerPaymentFiles,
    ToastComponent,

    SpinnerLoader,
    DefaultModal,
    AdmissionClaimForm,
    CompositeClaimForm
  },
  mixins: [loadingMixin],
  data() {
    return {
      WHITE_LIST_HOSPITAL_FORM: [
        'localhost',
        'dev.hospital.medosync.com',
        'uat.hospital.medosync.com',
        'hospital.medosync.com',
        'uat.beacon.medosync.com',
        'beacon.medosync.com'
      ],
      hostname: window.location.hostname,
      claimSectionWarnings: [],
      openApi: new OpenAPIJSON(),
      openApiPdf: new OpenAPIPDF(),
      claim: null,
      allClaimFetchedData: [],
      claimData: null,
      claimDataPreviousState: null,
      claimTypes: null,
      allClaimForms: [],
      breadcrumbs: [],
      claimformData: null,
      selectedTab: 'compositeClaimform',
      showManageModal: false,
      files: [],
      filesCount: 0,
      uploadVal: false,
      paymentFiles: [],
      insurerPaymentFiles: null,
      loadingClaimToggle: false,
      isLoading: false,
      newClaimFormTabOpen: false,
      newClaimFormOptions: [],
      showForm: true,
      removeSelectedItem: null,
      navs: [
        { type: 'patientForm', name: 'Patient Form', number: null, dataIdTest: 'patient-form-type', visible: false },
        {
          type: 'compositeClaimform',
          name: 'Composite Form',
          number: null,
          dataIdTest: 'composite-form-tab',
          visible: true
        }, // 0
        { type: 'invoice', name: 'Invoice', number: null, dataIdTest: 'invoice-tab', visible: true }, // 1
        {
          type: 'paymentFiles',
          name: 'Insurer Payment',
          number: 0,
          icon: null,
          dataIdTest: 'insurer-pymt-tab',
          visible: true
        }, // 2
        { type: 'attachments', name: 'Claim Documents', number: 0, dataIdTest: 'attachments-tab', visible: true } // 3
      ]
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },

    tabTitle() {
      return this.navs.find(nav => nav.type == this.selectedTab).name;
    },
    dataIdTest() {
      let dataIdTest = this.navs.find(nav => nav.type == this.selectedTab).dataIdTest;
      return 'page-title-' + dataIdTest;
    },
    notesOpened() {
      return notes_store.state.notesOpened;
    },
    insurerPaymentFilesIcon() {
      switch (this.claim.claim_state) {
        case 'pended_with_hospital':
          return 'Yellow Triangle';
        case 'rejected':
          return 'Red Triangle';
        case 'part_paid':
          return 'Yellow Triangle';
        case 'paid_in_full':
          return 'Green Triangle';
        default:
          return null;
      }
    },
    currentClaimTypeName() {
      if (!this.claimTypes) return null;
      return this.claimTypes?.find(type => type.claim_form_type === this.claimData?.claim_form_type)?.name;
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.getClaim();
      if (hospitalConfigStore.getters.has_product_access) {
        this.getAllClaims();
      }
      this.getClaimTypes();
      this.getBreadcrumbs();
      this.listFiles();
      this.enableTextBox();
      this.listInsurerPaymentFiles();
      this.filteredNavs();
      this.selectedTab = 'compositeClaimform';
    },
    ...mapMutations(['setTextBoxDisabled', 'setRevertModal']),
    disableTextBox() {
      this.claimDataPreviousState = JSON.parse(JSON.stringify(this.claimData));
      this.setTextBoxDisabled(false);
    },
    enableTextBox() {
      this.setTextBoxDisabled(true);
    },

    filteredNavs() {
      const hostname = window.location.hostname;
      this.navs = this.navs.map(nav => {
        if (nav.type === 'hospitalForm') {
          nav.visible = this.WHITE_LIST_HOSPITAL_FORM.includes(hostname);
        }
        return nav;
      });
    },

    generatePdf() {
      this.openApiPdf.claimformPdfPreview(this.fetchedClaimformData);
    },

    async generateCompositePdf() {
      await this.$refs.childComponentRefComposite.pdfDownloadCompositeForm();
    },

    navSetNumber(field_type_reference, value) {
      for (let idx = 0; idx < this.navs.length; idx++) {
        if (this.navs[idx].type === field_type_reference) {
          this.navs[idx].number = value;
        }
      }
    },
    navSetIcon(field_type_reference, icon) {
      for (let idx = 0; idx < this.navs.length; idx++) {
        if (this.navs[idx].type === field_type_reference) {
          this.navs[idx].icon = icon;
        }
      }
    },
    navClearIcon(field_type_reference) {
      for (let idx = 0; idx < this.navs.length; idx++) {
        if (this.navs[idx].type === field_type_reference) {
          this.navs[idx].icon = null;
        }
      }
    },
    navSetVisible(field_type_reference) {
      for (let idx = 0; idx < this.navs.length; idx++) {
        if (this.navs[idx].type === field_type_reference) {
          this.navs[idx].visible = true;
        }
      }
    },
    navSetHidden(field_type_reference) {
      for (let idx = 0; idx < this.navs.length; idx++) {
        if (this.navs[idx].type === field_type_reference) {
          this.navs[idx].visible = false;
        }
      }
    },
    showErrorMessage(text) {
      this.$refs.toast.showToast(text, 'fa-solid fa-triangle-exclamation', 'error');
    },

    async getAppointmentSignature(locator) {
      if (!locator) {
        this.showErrorMessage('Something went wrong getting appointment signature. Please try again and refresh page.');
        return;
      } else {
        try {
          const { signature } = await this.openApi.signature_patient_get(locator);
          this.patientSignature = signature;
        } catch (error) {
          this.claimSectionWarnings.push({
            title: 'Missing Signature',
            description: 'The claim form must be signed by the patient.',
            section: 'signature-pad-element-title',
            id: 'signature-pad-element'
          });
          //this.showErrorMessage('Something went wrong getting signature. Please try again and refresh page.');
        }
      }
    },

    async getPatientClaimform(locator) {
      if (!locator) {
        this.showErrorMessage('Something went wrong with data fetching. Please try again and refresh page.');
        return;
      } else {
        try {
          this.fetchedClaimformData = await this.openApi.claimform_get(this.claim.app_id, this.claim.claim_id, locator);
          this.claimformData = mapFormValuesFromGet(this.fetchedClaimformData);
        } catch (error) {
          this.showErrorMessage('Something went wrong with data fetching. Please try again and refresh page.');
        }
      }
    },

    async getAllClaims() {
      // if (this.claim.is_composite) {
      //   this.navSetVisible('patientForm');
      //   return;
      // }
      try {
        this.loading = true;
        const { claim_form_list } = await this.openApi.claimform_patient_list(this.claim.app_id);
        if (claim_form_list.length > 0) {
          await this.getAppointmentSignature(claim_form_list[0].locator);
          await this.getPatientClaimform(claim_form_list[0].locator.claim_form_id);
          this.navSetVisible('patientForm');
        } else {
          this.navSetHidden('patientForm');
        }
        this.setAddIcon();
      } catch {
        this.showErrorMessage('Something went wrong getting appointment details. Please try again and refresh page.');
      } finally {
        this.isLoading = false;
      }
    },

    async getClaimTypes() {
      const insurer_id = this.claim.insurance?.insurer_id;
      const claim_composite_id = this.claim.claim_composite_id;
      try {
        this.claimTypes = await this.openApi.getInsurerClaimForms(insurer_id, null, null, claim_composite_id);
        this.setNewClaimFormOptions(this.claimTypes);
        this.setAddIcon();
      } catch (err) {
        this.$refs.toast.showToast(
          'Something went wrong getting the claim form. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },

    deleteFirstForm(value) {
      if (value === true) {
        this.showForm = false;
      }
      this.showForm = !this.showForm;

      this.removeSelectedItem = null;
      if (!this.showForm) {
        this.removeSelectedItem = this.claimData;
      }
    },

    async removeItem(item) {
      try {
        await this.openApi.claimform_composite_delete(item.claim_composite_id, item.claim_form_id);

        this.$refs.toast.showToast('The claim form was deleted successfully.', 'fas fa-check', 'success');
        this.resetState();
        this.init();
      } catch (err) {
        this.$refs.toast.showToast(
          'An error occurred. Please contact the support.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
      this.removeSelectedItem = null;
    },

    resetState() {
      this.allClaimFetchedData = [];
      this.claimData = null;
      this.claimDataPreviousState = null;
      this.claimTypes = null;
      this.allClaimForms = [];
      this.breadcrumbs = [];
      this.selectedTab = 'claimForm';
      this.showManageModal = false;
      this.files = [];
      this.filesCount = 0;
      this.uploadVal = false;
      this.paymentFiles = [];
      this.insurerPaymentFiles = null;
      this.loadingClaimToggle = false;
      this.isLoading = false;
      this.newClaimFormTabOpen = false;
      this.newClaimFormOptions = [];
      this.showForm = true;
      this.removeSelectedItem = null;
    },
    addNewClaimToList({ claimForm, claimType }) {
      this.allClaimFetchedData.push(claimForm);
      claimType.claim_form_id = claimForm.claim_form_id;
      this.allClaimForms.push(claimType);

      this.selectClaimForm({
        claimConsultantId: claimForm.claim_id,
        claimFormId: claimForm.claim_form_id,
        appointmentId: claimForm.app_id
      });
      this.setNewClaimFormOptions(this.claimTypes);
      this.setAddIcon();
      this.newClaimFormTabOpen = false;
    },
    setNewClaimFormOptions(claimTypes) {
      this.allClaimForms = this.allClaimForms.map(claim => {
        const currentClaim = claimTypes.find(claimType => claimType.claim_form_type === claim.claim_form_type);
        if (currentClaim) {
          return { ...claim, display_name: currentClaim.name, claim_id: this.claim.claim_id };
        }
      });
      this.newClaimFormOptions = claimTypes.filter(
        claimType => !this.allClaimForms.some(claimForm => claimForm.claim_form_type === claimType.claim_form_type)
      );
    },
    setAddIcon() {
      if (this.newClaimFormOptions?.length && this.allClaimForms?.length < MAX_CLAIM_FORMS) {
        //this.navs[0].icon = 'Add';
        if (
          (this.newClaimFormOptions.length > 1 && this.allClaimForms[0]?.insurer_id === 6) ||
          this.newClaimFormOptions.length <= 1 ||
          this.allClaimForms.length === 0
        ) {
          if (this.claimformData) {
            //this.navSetIcon("invoice", "Add");
          } else {
            this.navSetIcon('claimForm', 'Add');
          }
        } else {
          this.navClearIcon('claimForm');
          this.navClearIcon('invoice');
        }
      } else {
        this.navClearIcon('claimForm');
        this.navClearIcon('invoice');
      }
    },

    async listInsurerPaymentFiles() {
      if (this.claim?.claim_phase === ClaimPhase.PRE_SUBMIT) {
        this.isLoading = false;
        this.insurerPaymentFiles = [];
      }
      try {
        this.isLoading = true;
        const res = await this.openApi.claim_statement_file_search(
          this.claim.claim_consultant.consultant_id,
          null,
          null,
          null,
          this.claim.invoice_no,
          null,
          null,
          this.claim.insurance.insurer_id,
          null
        );
        this.insurerPaymentFiles = res.items.filter(
          items => items.hospital_appointment_id === this.claim.hospital_appointment_id
        );
        this.isLoading = false;
      } catch (err) {
        this.$refs.toast.showToast(
          'Something went wrong getting files. Please try again and refresh page.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
        this.isLoading = false;
      }
      this.isLoading = false;
    },

    async listFiles() {
      try {
        let response = null;
        //console.log("listFiles: is single consultant ", configStore.getters.is_single_consultant_hospital);
        if (hospitalConfigStore.getters.is_single_consultant_hospital) {
          response = await this.openApi.file_list_by_id(this.claim.claim_id, AssocType.ClaimNested);
        } else {
          response = await this.openApi.file_list_by_id(this.claim.claim_composite_id, AssocType.CompositeClaim);
        }
        this.files = response.files;
        this.filesCount = response.files?.length;
        this.navSetNumber('attachments', this.filesCount);
      } catch (err) {
        console.log(err);
        this.$refs.toast.showToast(
          'Something went wrong getting files. Please try again and refresh page.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },

    refreshFiles() {
      this.listFiles();
      this.listInsurerPaymentFiles();
    },

    removeFileFromList(fileName) {
      this.files = this.files.filter(file => file.file_name !== fileName);
      this.filesCount = this.files.length;
      this.navSetNumber('attachments', this.filesCount);
    },
    async getClaim(setClaimFormOptions = false) {
      try {
        let response = await this._getClaim(this.openApi);
        this.claim = response.items[0];

        this.navSetIcon('paymentFiles', this.insurerPaymentFilesIcon);
        this.allClaimForms = await this.getAllClaimForms(this.claim.claim_composite_id);

        if (setClaimFormOptions) this.setNewClaimFormOptions(this.claimTypes);
        if (this.allClaimForms?.length) {
          this.claimData = await this.openApi.claimform_get(
            this.claim.app_id,
            this.claim.claim_id,
            this.allClaimForms[0].claim_form_id
          );
          this.addClaimToList(this.claimData);
        }
      } catch (err) {
        return this.$refs.toast.showToast(
          'Something went wrong getting the claim form. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    async _getClaim(oapi) {
      let claim_composite_id = parseInt(this.$route.params.id);
      return await oapi.searchCompositeClaims(null, null, claim_composite_id);
    },
    addClaimToList(claimData) {
      const claimForm = this.allClaimFetchedData.find(claim => claim.claim_form_id === claimData.claim_form_id);
      if (claimForm) {
        const index = this.allClaimFetchedData.findIndex(claim => claim.claim_form_id === claimData.claim_form_id);
        this.allClaimFetchedData.splice(index, 1, claimData);
      } else {
        this.allClaimFetchedData.push(claimData);
      }
    },
    async getAllClaimForms(id) {
      try {
        const { claim_forms } = await this.openApi.claim_form_composite_list({
          claim_ref: { id, type: 'id' }
        });
        return claim_forms;
      } catch (error) {
        return this.$refs.toast.showToast(
          'Something went wrong getting the claim form. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    async selectClaimForm({ claimId, claimFormId }) {
      try {
        if (this.claimData?.claim_form_id === claimFormId) return;
        const claimForm = this.allClaimFetchedData.find(claim => claim.claim_form_id === claimFormId);
        if (claimForm) {
          this.claimData = claimForm;
        } else {
          this.claimData = await this.openApi.claimform_get(this.claim.app_id, claimId, claimFormId);
          this.allClaimFetchedData.push(this.claimData);
        }
      } catch (err) {
        this.$refs.toast.showToast(
          'Something went wrong getting the claim form. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    closeForm() {
      this.newClaimFormTabOpen = false;
      this.init();
    },
    async removeClaimForm(claimFormId) {
      this.allClaimForms = this.allClaimForms.filter(claim => claim.claim_form_id !== claimFormId);
      if (this.claimData?.claim_form_id === claimFormId && this.allClaimForms?.length) {
        this.selectClaimForm({
          claimId: this.claim.claim_id,
          claimFormId: this.allClaimForms[0].claim_form_id,
          appointmentId: this.claim.app_id
        });
      } else if (!this.allClaimForms?.length) {
        this.claimData = null;
      }
      this.allClaimFetchedData = this.allClaimFetchedData.filter(claim => claim.claim_form_id !== claimFormId);
      this.setNewClaimFormOptions(this.claimTypes);
      this.setAddIcon();
    },
    async handleInvoicePreview() {
      try {
        await this.openApiPdf.invoicePdfPreview(this.claim);
      } catch (error) {
        this.$refs.toast.showToast(
          'Error getting the invoice PDF. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    getBreadcrumbs() {
      this.breadcrumbs = [
        { name: 'Home', to: '/navigator/compositeclaims/draft/all' },
        { name: 'All Claims', to: '/navigator/compositeclaims/draft/all' },
        { name: 'Claim ' + this.claim?.hospital_appointment_id, to: null }
      ];
    },
    switchTab(type) {
      if (type === this.selectedTab) return;
      if (type === 'claimForm' && this.newClaimFormTabOpen) {
        this.newClaimFormTabOpen = false;
      }
      this.selectedTab = type;
    },
    async scrollToField(elementId) {
      let claimTab = false;
      if (this.selectedTab !== 'claimForm') {
        claimTab = true;
        this.selectedTab = 'claimForm';
      }

      await nextTick();
      this.disableTextBox();

      await nextTick();
      setTimeout(
        () => {
          const element = document.getElementById(elementId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element.focus({ preventScroll: true, focusVisible: true });
          }
        },
        claimTab ? 400 : 0
      );
    },
    updateHeaderInfo({ appId, mrn }) {
      this.claim.hospital_appointment_id = appId;
      this.claim.patient.mrn = mrn;
    },
    async setPreviousFormState(newValue) {
      if (newValue) {
        this.claimDataPreviousState = JSON.parse(JSON.stringify(newValue));
        return;
      }

      this.claimData = this.claimDataPreviousState;
      this.claimDataPreviousState = null;
    },
    backButton() {
      if (this.newClaimFormTabOpen) {
        this.$refs.claimFormNew.closeAddingModal();
      } else {
        if (_.isEqual(this.claimData, this.claimDataPreviousState)) {
          this.setTextBoxDisabled(true);
          this.claimDataPreviousState = null;
        } else {
          this.setRevertModal(true);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.third-width {
  width: calc(100% - 400px);
}

.full-width {
  width: 100%;
}

.addAttachments {
  background-color: #d9d9d9;
  border-width: 0.1px;
}

.modalDelete {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.switch-container {
  width: 50px;
}
</style>
