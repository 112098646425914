<script setup lang="ts">
import { BaseInput, BaseDatePicker, BaseTextarea } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="laya-referral-details-title" title="Referral Details" />
    <BaseInput
      id="doctor-name"
      name="doctorName"
      class="mt-6"
      label="Name of doctor first attended"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('doctor-name')"
    />
    <BaseDatePicker
      id="first-consultation"
      name="firstTreatment"
      class="mt-6 sm:mt-12"
      label="Date"
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseTextarea
      id="doctor-address"
      name="doctorAddress"
      class="mt-6 sm:mt-12"
      label="Doctor's Address"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('doctor-address')"
    />
  </div>
</template>
