<script setup lang="ts">
import { computed, watch, defineEmits, ref } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  defaultValue: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  editMode: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['show-error']);

const { value, checked, handleChange, meta, errors } = useField(
  props.name,
  undefined,
  {
    type: 'checkbox',
    checkedValue: true,
    uncheckedValue: false,
  }
);

const showError = computed(() => {
  return meta.touched && errors.value.length;
});

watch(
  () => showError.value,
  (value) => {
    emit('show-error', value);
  }
);

value.value = props.defaultValue || false;
</script>

<template>
  <div>
    <div
      class="flex"
      :class="{ 'p-4 w-fit border border-medosync-red': showError }"
    >
      <input
        v-if="editMode"
        :id="id"
        :name="name"
        :checked="checked"
        type="checkbox"
        class="h-10 w-10 sm:h-12 sm:w-12 cursor-pointer text-medosync-blue rounded-lg border-2"
        :class="[showError ? 'border-medosync-red' : 'border-medosync-black']"
        @click="handleChange(value)"
      />
      <img
        v-else
        :id="id"
        :name="name"
        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAzNyAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrIj4KPGxpbmUgaWQ9IkxpbmUgMzMiIHgxPSIxLjQxNDIxIiB5MT0iMTMuMTA0NSIgeDI9IjEyLjAwNSIgeTI9IjIzLjY5NTMiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIGlkPSJMaW5lIDM0IiB4MT0iMTIuMTQwNiIgeTE9IjIzLjU5NTYiIHgyPSIzNC43MzY0IiB5Mj0iMC45OTk3NjUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjwvZz4KPC9zdmc+Cg=="
        class="h-6 w-6 sm:h-8 sm:w-8"
        alt="Checked"
      />

      <div class="ml-3 mt-2">
        <slot name="label"></slot>
        <slot name="additionalText"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
[type='checkbox']:checked {
  padding: 0.375rem 0.25rem;
  background-origin: content-box;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAzNyAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrIj4KPGxpbmUgaWQ9IkxpbmUgMzMiIHgxPSIxLjQxNDIxIiB5MT0iMTMuMTA0NSIgeDI9IjEyLjAwNSIgeTI9IjIzLjY5NTMiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIGlkPSJMaW5lIDM0IiB4MT0iMTIuMTQwNiIgeTE9IjIzLjU5NTYiIHgyPSIzNC43MzY0IiB5Mj0iMC45OTk3NjUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjwvZz4KPC9zdmc+Cg==);

  @screen sm {
    padding: 0.75rem 0.375rem;
  }
}
</style>
