<script setup>
import { useForm } from 'vee-validate';
import { object, string, date, boolean, array } from 'yup';

import PolicyDetails from './sections/vhi/PolicyDetails.vue';
import IllnessHistoryOncology from './sections/vhi/IllnessHistoryOncology.vue';
import InjurySection from './sections/vhi/InjurySection.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  initialData: {
    type: Object,
    default: () => ({})
  }
});

const { values, setValues, defineInputBinds } = useForm({
  validationSchema: object({
    policyNo: string(),
    patientForename: string().required(),
    patientSurname: string().required(),
    patientAddress: array().of(string()).required(),
    policyHolderName: string(),
    birthDate: date().required().min(new Date('1900-01-01')).max(new Date()),
    telephone: string().phone().required(),
    email: string().email(),

    doctorName: string(),
    firstConsultation: date(),
    doctorAddress: string(),
    firstTreatment: date(),
    similarIllness: boolean(),
    similarIllnessDate: date(),
    similarIllnessDetails: string(),
    otherSource: boolean(),
    otherSourceDetails: string(),
    weeksAppointment: string(),
    weeksAdmission: string(),
    privatePatient: boolean(),
    researchStudy: boolean(),

    injuryDate: date(),
    injuryPlace: string(),
    injuryDescription: string(),
    legalClaim: boolean(),
    solicitorName: string(),
    solicitorAddress: string()
  }),
  initialValues: {
    policyNo: props.initialData?.policyNo ?? undefined,
    patientForename: props.initialData?.patientForename ?? undefined,
    patientSurname: props.initialData?.patientSurname ?? undefined,
    patientAddress: props.initialData?.patientAddress ?? undefined,
    policyHolderName: props.initialData?.policyHolderName ?? undefined,
    birthDate: props.initialData?.birthDate ?? undefined,
    telephone: props.initialData?.telephone ?? undefined,
    email: props.initialData?.email ?? undefined,

    doctorName: props.initialData?.doctorName ?? undefined,
    firstConsultation: props.initialData?.firstConsultation ?? undefined,
    doctorAddress: props.initialData?.doctorAddress ?? undefined,
    firstTreatment: props.initialData?.firstTreatment ?? undefined,
    similarIllness: props.initialData?.similarIllness ?? undefined,
    similarIllnessDate: props.initialData?.similarIllnessDate ?? undefined,
    similarIllnessDetails: props.initialData?.similarIllnessDetails ?? undefined,
    otherSource: props.initialData?.otherSource ?? undefined,
    otherSourceDetails: props.initialData?.otherSourceDetails ?? undefined,
    weeksAppointment: props.initialData?.weeksAppointment ?? undefined,
    weeksAdmission: props.initialData?.weeksAdmission ?? undefined,
    privatePatient: props.initialData?.privatePatient ?? undefined,
    researchStudy: props.initialData?.researchStudy ?? undefined,

    injuryDate: props.initialData?.injuryDate ?? undefined,
    injuryPlace: props.initialData?.injuryPlace ?? undefined,
    injuryDescription: props.initialData?.injuryDescription ?? undefined,
    legalClaim: props.initialData?.legalClaim ?? undefined,
    solicitorName: props.initialData?.solicitorName ?? undefined,
    solicitorAddress: props.initialData?.solicitorAddress ?? undefined
  }
});

const similarIllness = defineInputBinds('similarIllness');
const otherSource = defineInputBinds('otherSource');
const legalClaim = defineInputBinds('legalClaim');

defineExpose({ values, setValues });
</script>

<template>
  <PolicyDetails id="vhi-oncology-policy-details" :edit-mode="editMode" :warnings="warnings" />
  <IllnessHistoryOncology
    id="vhi-oncology-illness-history"
    :edit-mode="editMode"
    :warnings="warnings"
    :similar-illness="similarIllness.value"
    :other-source="otherSource.value"
  />
  <InjurySection
    id="vhi-oncology-injury-section"
    :edit-mode="editMode"
    :warnings="warnings"
    :legal-claim="legalClaim.value"
  />
</template>
