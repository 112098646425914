<script setup>
import { onMounted, ref } from 'vue';
import { convertSmsToHtml } from '@/util/helper';
import { hospitalConfigStore } from '@/store/config';
import suneditor from 'suneditor';
import plugins from 'suneditor/src/plugins';
import 'suneditor/dist/css/suneditor.min.css';

const props = defineProps({
  content: {
    type: String,
    required: true
  },
  claimType: {
    type: String,
    required: true
  },
  claimTypeText: {
    type: String,
    required: true
  },
  formUrl: {
    type: String,
    required: true
  }
});
const emit = defineEmits(['saveContent', 'close']);

const editor = ref(undefined);
const isPhoneType = ref(props.claimType === 'phone');
const urlPlaceholderExample = `https://${hospitalConfigStore?.getters?.config?.domain_patient_registration}/12345678`;

const urlLength = () => {
  if (!hospitalConfigStore?.getters?.config?.domain_patient_registration) return 30;
  return urlPlaceholderExample.length;
};

onMounted(() => {
  editor.value = suneditor.create('wysiwyg-base', {
    mode: 'classic',
    value: isPhoneType.value ? convertSmsToHtml(props.content) : props.content,
    charCounter: true,
    // we can't define char counter type as 'sms' because it doesn't work with 'charCounterType' option
    // instead of using 160 chars for sms, we set max 150 chars for sms (for new lines and spaces)
    // 1 new line or space = 1 char
    maxCharCount: isPhoneType.value ? 160 - urlLength() : 0,
    minHeight: 120,
    height: 'auto',
    width: '100%',
    defaultStyle: 'font-size: 16px',
    plugins: plugins,
    resizeEnable: true,
    buttonList: isPhoneType.value
      ? []
      : [['bold', 'underline', 'italic', 'strike'], ['outdent', 'indent'], ['align', 'list'], ['link']]
  });
});

const saveContent = () => {
  emit('saveContent', editor.value.getContents());
};
</script>

<template>
  <div class="wysiwyg-container">
    <div class="flex justify-between">
      <span class="font-bold block">{{ claimTypeText }} to be sent</span>
      <div class="flex gap-5">
        <i
          data-test-id="patient-form-edit-textarea-cancel"
          class="fa-solid fa-xmark text-medosync-dark-red text-3xl cursor-pointer"
          @click="$emit('close')"
        ></i>
        <i
          data-test-id="patient-form-edit-textarea-confirm"
          class="fa-solid fa-check text-medosync-dark-green text-3xl cursor-pointer"
          @click="saveContent"
        ></i>
      </div>
    </div>
    <textarea data-test-id="patient-form-edit-textarea" id="wysiwyg-base"></textarea>
  </div>
</template>

<style lang="scss" scoped>
.wysiwyg-container {
  max-width: 730px;

  button {
    height: 48px;
    width: 165px;
    border-radius: 50px;

    &:hover:enabled {
      opacity: 0.85;
    }

    &.cancel {
      background: #cbd5e0;
    }

    &.confirm {
      background: #00a7e1;
    }
  }
}
</style>

<style>
.se-resizing-bar .se-char-counter-wrapper:after {
  content: ' characters';
}
</style>
