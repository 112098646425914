<template>
  <div class="menu-transition" :class="isActive ? 'component-content-section' : 'component-content-section-no-menu'">
    <div class="optional-nav">
      <SettingsMenu @set-menu="setMenuActive" />
    </div>

    <div>
      <div class="row">
        <div class="col-6 s-view-title">Groups</div>

        <div class="col-6">
          <div>
            <input
              v-model="searchString"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Search by name or medical number"
            />
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <div style="float: right" class="col-7">
        <div class="mt-3">
          <router-link to="Create-group">
            <button
              type="button"
              class="medo-bg-secondary inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Create Group +
            </button>
          </router-link>
        </div>
      </div>

      <div style="clear: both"></div>

      <div class="flex flex-col pl-6 pr-6 mt-5 ml-2">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table v-if="groups" class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Desc
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <!-- Odd row -->
                  <tr v-for="(group, index) in filteredGroups" :key="index" class="bg-white">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ group.aclGroupId }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ group.name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ group.description }}
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <router-link
                        :to="'/settings/edit-group/' + group.aclGroupId"
                        class="text-indigo-600 hover:text-indigo-900 medo-secondary"
                        >Edit</router-link
                      >
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <router-link
                        v-if="group.aclGroupId != 1"
                        :to="'/settings/edit-group/' + group.aclGroupId"
                        class="text-red-600 hover:text-red-900"
                        >Delete</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import api from 'api/api';
import SettingsMenu from '@/views/settings/menu/Index.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'UserGroups',
  components: {
    SettingsMenu,
    ToastComponent
  },
  data() {
    return {
      groups: [],
      searchString: '',
      isActive: true
    };
  },
  computed: {
    filteredGroups() {
      if (this.searchString.toLowerCase() == '') {
        return this.groups;
      } else {
        return this.groups.filter(item => {
          if (
            item.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
            item.aclGroupId.toString().toLowerCase().includes(this.searchString.toLowerCase())
          ) {
            return item;
          }
        });
      }
    }
  },
  methods: {
    async init() {
      await api
        .listAclGroups()
        .then(res => {
          this.groups = res.data.data.listAclGroup.groups;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },
    setMenuActive(bool) {
      this.isActive = bool;
    }
  },
  created() {},
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}

.custom-back {
  font-size: 28px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.65;
  }
}

.claim-title {
  font-size: 26px;
  font-weight: 600;
}

.custom-input {
  height: 30px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

.custom-select {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}
</style>
