<script setup lang="ts">
import { ref } from 'vue';
import type { Ref } from 'vue';
//import { userStore } from '@/store/user';
import { OpenAPIJSON } from 'api';
import { toBase64 } from 'utils';

import FullscreenOverlay from '@/components/modal/FullscreenOverlay.vue';
import DownloadPatientDataTemplate from '@/components/admission/DownloadPatientDataTemplate.vue';
import UploadFiles from '@/components/misc/UploadFiles.vue';

const emits = defineEmits(['close']);

const handleModalClose = (newFileName: string | null = null) => {
  file.value = null;
  emits('close', newFileName ? newFileName : null);
};

const removeFile = () => {
  file.value = null;
};

const file = ref<File | null>(null);
const isFileUploadSuccess: Ref<boolean | null> = ref(null);
const isLoading = ref(false);
const openApi = new OpenAPIJSON();

const uploadFile = async () => {
  isLoading.value = true;
  if (!file.value) return;
  try {
    const assoc = {
      assoc_type: 'consultant',
      type: 'id',
      //id: userStore.getters.user.person_id
      id: 17
    };
    const base64 = await toBase64(file.value);
    const currentTime = new Date().getTime().toString();
    const newFileName = `${currentTime.slice(-5)}-${file.value.name}`;
    await openApi.file_add_by_id(assoc, [
      {
        file_name: newFileName,
        data: base64,
        mime_type: file.value.type,
        file_category: 'other'
      }
    ]);
    isFileUploadSuccess.value = true;
  } catch (error) {
    isFileUploadSuccess.value = false;
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <FullscreenOverlay @close-modal="handleModalClose">
    <template #header>
      <h2 class="text-2xl font-semibold text-medosync-violet-highlight mb-5">Upload patient data</h2>
    </template>
    <template #body>
      <DownloadPatientDataTemplate />

      <div class="border-2 border-black p-4 my-5">
        <h3 class="text-xl font-medium mb-5">Upload patient data</h3>

        <UploadFiles
          v-if="!file"
          v-model="file"
          :multiple="false"
          :showButtons="true"
          :no-close="true"
          @cancel="handleModalClose"
        />
        <template v-if="file">
          <div class="h-[170px]">
            <div v-if="isFileUploadSuccess === null && !isLoading" class="flex flex-col">
              <div class="flex justify-between items-center bg-medosync-grey px-4 py-2">
                <h5>
                  {{ file.name }}
                </h5>
                <i class="fa-regular fa-trash-can cursor-pointer text-xl" @click="removeFile"></i>
              </div>
            </div>

            <div
              v-else-if="isFileUploadSuccess === null && isLoading"
              class="px-5 bg-[#F9F9F9] flex flex-col justify-around align-center h-full"
            >
              <h4>Your files are being uploaded. Please wait... this can take up to 5 minutes.</h4>
              <div class="flex justify-start items-center">
                <i class="fa-solid fa-spinner fa-spin-pulse text-medosync-blue fa-3x me-6" />
                <p>{{ file.name }}</p>
              </div>
            </div>

            <div
              v-if="isFileUploadSuccess === true && !isLoading"
              class="flex items-center border-2 border-medosync-dark-green bg-[#F9F9F9] p-4"
            >
              <i class="fa-solid fa-check text-medosync-dark-green fa-xl me-4" />
              <p data-test-id="uploaded-file-name-text">{{ file.name }}</p>
            </div>
          </div>

          <div class="flex justify-end items-center">
            <button
              v-if="isFileUploadSuccess === null"
              :disabled="isLoading"
              type="button"
              class="flex items-center bg-medosync-blue px-6 px-6 py-2 text-sm rounded-full"
              @click="uploadFile"
            >
              Upload File
            </button>
            <button
              v-if="isFileUploadSuccess === true"
              data-test-id="close-modal-button"
              type="button"
              class="flex items-center bg-medosync-blue px-6 px-6 py-2 text-sm rounded-full"
              @click="emits('close')"
            >
              Start working with uploaded data
            </button>
          </div>
        </template>
      </div>
    </template>
  </FullscreenOverlay>
</template>
