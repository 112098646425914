<template>
  <div
    v-if="contractView"
    class="menu-transition"
    :class="isActive ? 'component-content-section' : 'component-content-section-no-menu'"
  >
    <div class="optional-nav">
      <SettingsMenu @set-menu="setMenuActive" />
    </div>

    <div>
      <div class="flex">
        <div class="col s-view-title">Contracts</div>

        <div class="col-auto flex">
          <div class="col pr-1">
            <select
              v-model="selectedInsurer"
              class="custom-select block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option :value="null" disabled selected hidden>Select Insurer</option>
              <option v-for="insurer in sortInsurers" :key="insurer.insurerId" :value="insurer">
                {{ insurer.insurer_name }}
              </option>
            </select>
          </div>

          <div v-if="contracts && selectedInsurer" class="col pl-1">
            <input
              v-model="searchString"
              type="text"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input"
              placeholder="Search by procedure code or by description"
            />
          </div>
        </div>
      </div>
      <div class="flex mt-3">
        <button
          v-if="selectedInsurer"
          @click="openCloneModal"
          type="button"
          class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          ADD NEW CONTRACT
        </button>
      </div>

      <div v-if="contracts && selectedInsurer" class="col-12 flex flex-col mt-5">
        Contracts for {{ selectedInsurer.insurerName }}
        <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      NAME
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      START DATE
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      ACTION
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <template v-for="(item, index) in filteredContracts" :key="index">
                    <tr class="bg-white" :class="{ 'bg-green-100': index == contracts.length - 1 }">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.contract_name }}
                      </td>
                      <td class="px-6 py-4 text-sm font-medium text-gray-900">
                        {{ item.valid_from }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          @click="goToManageProcedureCodes(item)"
                          type="button"
                          class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          MANAGE PROCEDURE CODES
                        </button>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      v-if="showCloneModal"
      @close-modal="closeCloneModal"
      :hide-expand-button="false"
      :hide-header="true"
      :close-on-click-outside="false"
    >
      <template v-slot:body>
        <div class="px-2 pt-5">
          <h2 class="ml-1 title medo-secondary">Clone Existing Contract</h2>

          <div class="col-12 mt-6">
            <div class="label">Select existing contract</div>
            <select
              v-model="cloneContractId"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option v-for="(contract, index) in filteredContracts" :key="index" :value="contract.contract_id">
                {{ contract.contract_name }}
              </option>
            </select>
          </div>

          <div class="col-12 mt-6">
            <span>New Contract Name</span>
            <input
              v-model="cloneName"
              type="text"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input"
            />
          </div>

          <div class="col-12 mt-6">
            <div class="label">Select Insurer</div>
            <select
              v-model="cloneInsurerId"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option v-for="(insurer, index) in sortInsurers" :key="index" :value="insurer.insurer_id">
                {{ insurer.insurer_name }}
              </option>
            </select>
          </div>

          <div class="col-12 mt-6">
            <span>Start Date</span>
            <CustomDatePicker class="mt-3" v-model="cloneStartDate" />
          </div>
        </div>

        <div style="clear: both"></div>

        <div class="mt-4 pr-2 flex justify-end">
          <button
            @click="validateInput"
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            SAVE
          </button>
        </div>
      </template>
    </Modal>

    <Modal
      v-if="showWarningModal"
      @close-modal="closeWarningModal"
      :hide-expand-button="true"
      :close-on-click-outside="false"
    >
      <template v-slot:header>
        <h3 class="mt-4">Warning!</h3>
      </template>
      <template v-slot:body>
        If you proceed, this will create duplicate contract from the selected one. After new contract is created you can
        edit individual procedure codes by clicking "MANAGE PROCEDURE CODES".
        <div class="col-12 mt-3 flex justify-end">
          <button
            @click="closeWarningModal"
            type="button"
            class="inline-flex items-center mr-2 px-2.5 py-1.5 border border-blue-600 text-blue-600 text-xs font-medium rounded shadow-sm text-white bg-white-600 hover:bg-white-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
          >
            NO
          </button>
          <button
            @click="duplicateContract"
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            YES
          </button>
        </div>
      </template>
    </Modal>
  </div>
  <template v-else>
    <ManageContractProcedureCodes :selected-contract="selectedContract" @go-back="goToContracts" />
  </template>
</template>

<script>
//import api from 'api/api';
import { OpenAPIJSON } from 'api/openapi';
import { api_store } from '@/store';
import Modal from '@/components/modal/Modal';
import SettingsMenu from '@/views/settings/menu/Index';
import ManageContractProcedureCodes from '@/views/user-management/ManageContractProcedureCodes';

import CustomDatePicker from '@/components/claim-forms/components/Datepicker';

export default {
  name: 'ProcedureCodes',
  components: {
    ManageContractProcedureCodes,
    CustomDatePicker,
    Modal,
    SettingsMenu
  },
  data() {
    return {
      insurers: [],
      contracts: [],
      searchString: '',
      selectedInsurer: null,
      filteredProcedureCodes: null,
      contractView: true,
      isActive: true,

      showCloneModal: false,
      showWarningModal: false,

      cloneContractId: null,
      cloneName: null,
      cloneInsurerId: null,
      cloneStartDate: null,
      sort: 'asc',
      sortby: 'insurer_name'
    };
  },
  watch: {
    selectedInsurer() {
      this.fetch_json_contracts();
    },
    async searchString() {
      await this.fetch_json_procedure_codes();
    }
  },
  computed: {
    filteredContracts() {
      return this.filteredProcedureCodes
        ? this.contracts.filter(contract => this.filteredProcedureCodes.some(fpc => fpc == contract.contract_id))
        : this.contracts;
    },
    sortInsurers() {
      return [...this.insurers].sort((a, b) => {
        if (this.sortby == 'insurer_name') {
          if (this.sort == 'asc') {
            return a.insurer_name.localeCompare(b.insurer_name);
          } else {
            return b.insurer_name.localeCompare(a.insurer_name);
          }
        } else {
          if (this.sort == 'asc') {
            return a.insurer_id - b.insurer_id;
          } else {
            return b.insurer_id - a.insurer_id;
          }
        }
      });
    }
  },
  methods: {
    async init() {
      await this.listInsurers();
    },

    async listInsurers() {
      const oapi = new OpenAPIJSON();
      const request = await oapi.searchContracts();

      const uniqueIds = new Set();
      const uniqueInsurers = request.items.filter(insurer => {
        if (!uniqueIds.has(insurer.insurer_id)) {
          uniqueIds.add(insurer.insurer_id);
          return true;
        }
        return false;
      });

      this.insurers = uniqueInsurers;
    },

    async fetch_json_contracts() {
      const oapi = new OpenAPIJSON();
      const request = await oapi.searchContracts(this.selectedInsurer.insurer_id);
      this.contracts = request.items.sort(function (a, b) {
        return Number(new Date(a.valid_from)) - Number(new Date(b.valid_from));
      });
    },

    async fetch_json_procedure_codes() {
      const oapi = new OpenAPIJSON();
      const request = await oapi.searchProcedureCodes(
        null,
        null,
        null,
        this.selectedInsurer.insurerId,
        null,
        null,
        null,
        null,
        null,
        this.searchString
      );
      this.filteredProcedureCodes =
        this.searchString == '' ? null : [...new Set(request.items.map(item => item.insurer_contract_id))];
    },

    goToManageProcedureCodes(contract) {
      this.selectedContract = contract;
      this.contractView = false;
    },
    goToContracts() {
      this.contractView = true;
    },
    closeCloneModal() {
      this.showCloneModal = false;
      this.cloneContractId = null;
      (this.cloneName = null), (this.cloneInsurerId = null), (this.cloneStartDate = null);
    },
    openCloneModal() {
      this.showCloneModal = true;
      this.cloneInsurerId = this.selectedInsurer.insurerId;
    },
    validateInput() {
      if (this.cloneContractId == null) return this.$toast.error('Invalid contract!');
      if (this.cloneName == null || this.cloneName == '') return this.$toast.error('Invalid contract name!');
      if (this.cloneInsurerId == null) return this.$toast.error('Invalid insurer!');
      if (this.cloneStartDate == null) return this.$toast.error('Invalid start date!');

      this.showWarningModal = true;
    },
    closeWarningModal() {
      this.showWarningModal = false;
    },
    duplicateContract() {
      api_store.cache
        .dispatch('CLONE_CONTRACT', {
          contractId: this.cloneContractId,
          newContractName: this.cloneName,
          forInsurer: this.cloneInsurerId,
          startDate: this.cloneStartDate
        })
        .then(result => {
          console.log(result);
          this.closeWarningModal();
          this.closeCloneModal();
        })
        .catch(error => {
          console.log('Contract clone fetch error', error);
        });
    },

    setMenuActive(bool) {
      this.isActive = bool;
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.custom-back {
  font-size: 28px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.65;
  }
}

.claim-title {
  font-size: 26px;
  font-weight: 600;
}

.custom-input {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

.max-width-desc {
  max-width: 500px;
}

.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}
</style>
