<template>
  <Modal v-if="showManageModal" @close-modal="setModalOff()">
    <!-- Header for the 'Add Appointment Manually' Modal -->
    <template v-slot:header>
      <div class="flex row">
        <div class="col-span-3">
          <h3 class="text-lg text-left leading-6 font-medium medo-grey-600 my-2">Add appointment manually</h3>
          <p class="modal-paragraph mt-4 text-xs">To add an appointment manually, you can search for the MRN.</p>
          <p class="modal-paragraph text-xs">Afterwards, the associated Appointment ID will be shown.</p>
        </div>
      </div>
      <div class="flex row mt-3">
        <div class="col-span-3 w-full">
          <h3>MRN or patient name</h3>
          <SearchBoxDash @errorReturn="handleError" @valueReturn="handleValue" errorValue:errorValue class="w-full" />
          <div v-if="infoMrn" class="flex justify-between mt-4">
            <div class="w-1/3 text-left">
              <div class="flex flex-col">
                <span class="font-bold">Name:</span>
                <span>{{ infoPatientName }}</span>
              </div>
            </div>
            <div class="w-1/3 text-center">
              <div class="flex flex-col items-center">
                <span class="font-bold">Date of Birth:</span>
                <span>{{ humanDate(infoDob) }}</span>
              </div>
            </div>
            <div class="w-1/3"></div>
          </div>

          <div v-if="infoMrn" class="mt-6">
            <!-- <div>
              <div class="text-left text-sm mb-2">
                <label for="value" class="font-medium text-gray-700 mr-2"
                  >Appointment ID: <span class="required">*</span></label
                >
              </div>
              <div class="col-12">
                <input
                  id="add-claim-appid"
                  type="text"
                  data-test-id="new-claim-appointid"
                  class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  v-model="claim.hospital_appointment_id"
                />
              </div>
            </div> -->

            <div>
              <div class="sub-title mt-4">
                <span class="text-sm">Appointment Date:*</span>
                <div class="float-left col-12 mt-2 mb-4">
                  <DatePicker
                    :input-attr="{
                      id: id + '-date-picker',
                      'data-test-id': 'appointment-date-select-appointment-manually',
                      style: 'width: 210px; height: 34px; color: black; font-size: 13px;'
                    }"
                    v-model:value="formattedDate"
                    placeholder="dd-mm-yyyy"
                    value-type="YYYY-MM-DD"
                    format="DD-MM-YYYY"
                    @change="updateClaimDate"
                  ></DatePicker>
                </div>
              </div>

              <div class="sub-title mt-8">
                <span class="text-sm mt-2">Time of Appointment:*</span>
                <div class="float-left col-12 mt-2" id="admission-time-picker-wrapper">
                  <date-picker
                    :input-attr="{
                      'data-test-id': 'appointment-time-select',
                      style: 'width: 160px; height: 34px; color: black; font-size: 13px;'
                    }"
                    v-model:value="timerPickerAdmi"
                    :minute-step="1"
                    format="HH:mm"
                    show-second="00"
                    value-type="HH:mm:ss"
                    type="time"
                    placeholder="HH:mm"
                    @change="updateClaimTime"
                  ></date-picker>
                </div>
              </div>
            </div>

            <div class="float-left col-12 mt-2 mb-5">
              <div class="text-left text-sm mb-1">
                <label for="value" class="font-medium text-gray-700 mr-2"
                  >Insurer: <span class="required">*</span></label
                >
              </div>
              <InsuranceCompany
                id="add-claim-insurer"
                v-model="claim.insurance[0]"
                data-test-id="new-claim-insurer"
                @update:modelValue="insuranceChanged"
                class="w-full sm:w-3/4 lg:w-1/2"
                :manualydisabled="manualydisabled"
              />
            </div>

            <div v-if="claim.insurance[0].insurer?.id" class="sub-title">
              Claim Form Type:*
              <div class="float-left col-12 mt-2">
                <ClaimFormComp
                  :dataList="dataList"
                  :idReference="claim.insurance[0].insurer_id"
                  @updateCode="updateFormType"
                />
              </div>
            </div>

            <div v-if="claim.insurance[0].insurer?.id" class="col-12 mt-5 mb-5">
              <div class="col-12 float-left">
                <div class="float-left col-12 mt-2">
                  <InsurancePolicy
                    :insurer_id="claim.insurance[0].insurer?.id"
                    v-model="claim.insurance[0].policy_plan"
                    :policy_name="policy_name"
                    data_test_id="insurance-policy-name-input"
                    :on_date="new Date(claim.scheduled_on).toISOString().slice(0, 10)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4" v-if="errorStatusSearch">
            <p class="text-left text-xs text-red-500 mb-2">
              Unfortunately we cannot find an Appointment ID for this MRN that is unregistered and discharged.
            </p>
            <p class="text-left text-xs text-red-500">
              Please check the MRN against your hospital system and try again.
            </p>
            <p
              @click="addPatientManuallyModal()"
              class="text-right text-xs text-medosync-black underline cursor-pointer"
            >
              Add patient manually.
            </p>
          </div>
        </div>
      </div>

      <div class="flex justify-between mt-8">
        <button class="cancel" @click="setModalOff()">Cancel</button>
        <button class="confirm" :disabled="disabledButtonAddAppointment" @click="checkValue">Add appointment</button>
      </div>
    </template>
  </Modal>

  <Modal v-if="addPatientManually" @close-modal="setAddPatientManually()">
    <!-- Header for the 'Add Patient Manually' Modal -->
    <template v-slot:header>
      <div class="flex flex-col">
        <span @click="addAppointmetModal()" class="text-sm mb-2">
          <i class="fas fa-chevron-left"></i> back to “MRN and patient name search"
        </span>
        <div class="col-span-3">
          <h3 class="text-lg text-left leading-6 font-medium medo-grey-600 my-2">Add patient manually</h3>
          <p class="modal-paragraph mt-4 text-xs">
            If a patient does not exist in your system from i.e. previous appointments, you can add them here:
          </p>
        </div>

        <div class="mt-4">
          <div class="w-full flex flex-wrap">
            <div class="w-1/2 pr-2">
              <div class="mt-2">
                <label for="value" class="block text-left text-sm font-medium text-gray-700 mr-2"
                  >First Name: <span class="required">*</span></label
                >
                <input
                  ref="patientTypeahead"
                  id="add-claim-patient-firstname"
                  type="text"
                  data-test-id="firstName"
                  class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  v-model="claim.patient.first_name"
                />
              </div>
            </div>

            <div class="w-1/2">
              <div class="mt-2">
                <label for="value" class="block text-left text-sm font-medium text-gray-700 mr-2"
                  >Last Name: <span class="required">*</span></label
                >
                <input
                  id="add-claim-patient-lastname"
                  type="text"
                  data-test-id="lastName"
                  class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  v-model="claim.patient.last_name"
                  ref="patientTypeaheadLastName"
                />
              </div>
            </div>
          </div>

          <div class="w-full mt-4">
            <label for="value" class="block text-left text-sm font-medium text-gray-700 mr-2"
              >Date of Birth: <span class="required">*</span></label
            >
            <div data-test-id="date-of-birth">
              <date-picker
                id="add-claim-patient-dob"
                v-model:value="claim.patient.dob"
                value-type="YYYY-MM-DD"
                format="DD-MM-YYYY"
                ref="patientTypeaheadDob"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="float-left col-12 mt-2 mb-5">
            <div class="text-left text-sm mb-1">
              <label for="value" class="font-medium text-gray-700 mr-2">Insurer: <span class="required">*</span></label>
            </div>

            <InsuranceCompany
              id="add-claim-insurer"
              v-model="claim.insurance[0]"
              @update:modelValue="insuranceChanged"
              data-test-id="insurer-search-input"
              class="w-full sm:w-3/4 lg:w-1/2"
            />
          </div>

          <div v-if="claim.insurance[0].insurer?.id" class="sub-title">
            Claim Form Type:*
            <div class="float-left col-12 mt-2">
              <ClaimFormComp
                :dataList="dataList"
                :idReference="claim.insurance[0].insurer_id"
                @updateCode="updateFormType"
                data-test-id="claim-form-type-input"
              />
            </div>
          </div>

          <div v-if="claim.insurance[0].insurer?.id" class="col-12 mt-5 mb-5">
            <div class="col-12 float-left">
              <div class="float-left col-12 mt-2">
                <InsurancePolicy
                  id="add-claim-insurer-policy"
                  :insurer_id="claim.insurance[0].insurer?.id"
                  v-model="claim.insurance[0].policy_plan"
                  :policy_name="policy_name"
                  data_test_id="insurance-policy-name-input"
                />
              </div>
            </div>
          </div>

          <div>
            <div class="sub-title mt-4">
              <span class="text-sm">Appointment Date:*</span>
              <div class="float-left col-12 mt-2 mb-4">
                <DatePicker
                  :input-attr="{
                    id: id + '-date-picker',
                    'data-test-id': 'appointment-date-select-add-patient-manually',
                    style: 'width: 210px; height: 34px; color: black; font-size: 13px;'
                  }"
                  v-model:value="formattedDate"
                  placeholder="dd-mm-yyyy"
                  value-type="YYYY-MM-DD"
                  format="DD-MM-YYYY"
                  @change="updateClaimDate"
                ></DatePicker>
              </div>
            </div>
            <div class="sub-title mt-8">
              <span class="text-sm mt-2">Time of Appointment:*</span>
              <div class="float-left col-12 mt-2" id="admission-time-picker-wrapper">
                <date-picker
                  :input-attr="{
                    'data-test-id': 'appointment-time-select',
                    style: 'width: 160px; height: 34px; color: black; font-size: 13px;'
                  }"
                  v-model:value="timerPickerAdmi"
                  :minute-step="1"
                  format="HH:mm"
                  show-second="00"
                  value-type="HH:mm:ss"
                  type="time"
                  placeholder="HH:mm"
                  @change="updateClaimTime"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between mt-8 w-full">
          <button class="cancel" @click="setAddPatientManually()">Cancel</button>
          <button
            :disabled="disabledButtonAddPatient"
            data-test-id="add-patient-btn"
            class="confirm"
            @click="checkValuePatient()"
          >
            Add patient
          </button>
        </div>
      </div>
    </template>
  </Modal>

  <SpinnerLoader v-if="isLoading" />
  <BaseInsurerForm
    v-if="sendInsurerFormModal"
    :appointmentId="selectedAppointmentId"
    :patientInfo="patientInfo"
    @send-form="sendPatientRegistrationForm"
    @close="sendInsurerFormModal = false"
  />
  <div>
    <div
      class="tooltip"
      data-test-id="tooltip"
      v-if="tooltipText != undefined && tooltipText != '' && showTooltip"
      :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }"
    >
      <span>{{ getTooltipText(tooltipText) }}</span>
    </div>

    <div class="relative" style="max-width: 1300px">
      <div v-if="optionAddClick" class="flex items-center justify-start mt-4 mb-4">
        <i
          data-test-id="add-appointment-manual-plus"
          class="fa-solid fa-plus cursor-pointer mr-2"
          @click="showManageModal = true"
        ></i>
        <span
          data-test-id="add-appointment-manual-text"
          class="text-grey cursor-pointer"
          @click="showManageModal = true"
          >Add appointment manually</span
        >
      </div>

      <table v-if="data && data.length > 0" class="">
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="header.key"
              class="header-cell-table"
              :style="{ width: header.width + 'px' }"
            >
              {{ header.label }}

              <div>
                <i
                  v-if="header.icon"
                  class="fa-sharp fa-solid fa-rotate-right"
                  style="cursor: pointer"
                  @click="refreshDashboard()"
                ></i>
              </div>

              <ComboBoxDash
                v-if="header.selectedBox"
                data-test-id="form-state-filter"
                headers="preadmit_state"
                :data="summary"
                title="Form State"
                @get-filtered="getFilteredComboBoxDash"
                :reset="reset"
              ></ComboBoxDash>

              <div v-if="header.selectDate">
                <div id="scheduled_on-time-picker-wrapper">
                  <DatePicker
                    v-if="header.key === 'scheduled_on'"
                    :input-attr="{
                      id: id + '-date-picker',
                      'data-test-id': 'scheduled_on-date-select',
                      style:
                        'background-color: #cacdd6; width: 150px; height: 34px; border: none; box-shadow: none;  text-align: center; color: black; font-size: 13px;'
                    }"
                    data-test-id="scheduled_on-date-select"
                    @change="getFilteredDatePicker(selectedItens)"
                    v-model:value="selectedItens"
                    :placeholder="header.labelDate"
                    value-type="YYYY-MM-DD"
                    format="DD-MM-YYYY"
                    :disabled-date="disableDates"
                  ></DatePicker>
                </div>
                <div id="admission_on-time-picker-wrapper">
                  <DatePicker
                    v-if="header.key === 'admitted_on'"
                    :input-attr="{
                      id: id + '-date-picker',
                      'data-test-id': 'admitted_on-date-select',
                      style:
                        'background-color: #cacdd6; width: 150px; height: 34px; border: none; box-shadow: none; text-align: center; color: black; font-size: 13px; '
                    }"
                    data-test-id="admitted_on-date-select"
                    @change="getFilteredDatePickerAdmission(admissionItens)"
                    v-model:value="admissionItens"
                    :placeholder="header.labelDate"
                    :disabled-date="disableDatesAdmission"
                    value-type="YYYY-MM-DD"
                    format="DD-MM-YYYY"
                  ></DatePicker>
                </div>
                <div id="discharge-time-picker-wrapper">
                  <DatePicker
                    v-if="header.key === 'discharged_on'"
                    :input-attr="{
                      id: id + '-date-picker',
                      'data-test-id': 'discharged_on-date-select',
                      style:
                        'background-color: #cacdd6; width: 150px; height: 34px; border: none; box-shadow: none; text-align: center; color: black; font-size: 13px; '
                    }"
                    data-test-id="discharged_on-date-select"
                    @change="getFilteredDatePickerDischarge(dischargedItens)"
                    v-model:value="dischargedItens"
                    :placeholder="header.labelDate"
                    :disabled-date="disableDatesDischarge"
                    value-type="YYYY-MM-DD"
                    format="DD-MM-YYYY"
                  ></DatePicker>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIndex) in claims"
            :key="rowIndex"
            :class="rowIndex % 2 === 0 ? 'even-row' : 'odd-row'"
            @mouseover="highlightRow(rowIndex)"
            @mouseout="resetRowColor(rowIndex)"
            style="cursor: pointer"
            @click="lineClick(row)"
            :data-test-id="'access-dashboard-row-' + rowIndex"
          >
            <td
              @mouseover="showItemTooltip($event, row[header.status])"
              @mouseout="hideItemTooltip"
              v-for="(header, index) in headers"
              :key="header.key"
              :class="{ 'table-cell-left': header.position, 'table-cell': !header.position }"
              :style="{ width: header.width + 'px' }"
              :data-test-id="'access-dashboard-row-' + rowIndex + ' collumn ' + index"
            >
              {{ row[header.position] }}
              <label v-if="header.type2" class="flex items-center space-x-2 cursor-pointer">
                <input
                  :data-test-id="id + '-checkbox-' + rowIndex"
                  type="checkbox"
                  class="hidden"
                  :checked="isCheck(row)"
                  @change="toggleCheck(row)"
                />
                <div
                  class="w-5 h-5 m-1 rounded-full border-2 border-grey-600 flex items-center justify-center transition duration-300"
                  :class="row.processedOn ? 'bg-white border-green-600' : 'bg-white border-gray-400'"
                >
                  <i v-if="row.processedOn" class="text-green-600 fas fa-check"></i>
                </div>
              </label>
              <i
                :data-test-id="id + '-check-data-' + rowIndex"
                v-if="row[header.status] === 'Check Data'"
                class="fa-regular fa-circle-exclamation fa-xl my-2 flex items-center justify-center text-center min-h-[30px] m-auto leading-3"
                style="color: #b25f08; z-index: 9998"
              ></i>

              <div v-for="check in row.checks" :key="check" class="check-item">
                <div v-if="row[header.checkdata]">
                  <i :class="getIconClass(check)" style="color: #b25f08; z-index: 9998"></i>
                </div>
              </div>

              <div v-if="header.checkdata_state">
                <i
                  :data-test-id="id + '-check-data-' + rowIndex"
                  :class="getIconAttributes(getNestedValue(row, header.checkdata_state)).class"
                  :style="getIconAttributes(getNestedValue(row, header.checkdata_state)).style"
                ></i>
              </div>

              <div @click.stop="envelopeClick(row)">
                <i
                  :data-test-id="id + '-mail-icon-' + rowIndex"
                  v-if="showMessageIcon(row[header.doneState]) && WHITE_LIST_ENVELOP_HOSTNAMES.includes(hostname)"
                  style="cursor: pointer"
                  class="fa-regular fa-envelope fa-xl my-2 flex items-center justify-center text-center min-h-[30px] m-auto leading-3"
                ></i>
              </div>

              <FormStatus class="my-2" v-if="header.status === true" :status="row[header.key]" :key="row[header.key]" />
              <span :data-test-id="id + '-date-' + rowIndex" v-if="header.date">
                {{ appointmentDate(getNestedValue(row, header.key)) }}
              </span>
              <span :data-test-id="id + '-date-appointment-' + rowIndex" v-if="header.dob && !header.date">
                {{ humanDate(getNestedValue(row, header.key)) }}
              </span>
              <span
                :data-test-id="id + '-text-first-' + rowIndex"
                v-if="!header.type && !header.type2 && !header.date && !header.dob && !header.status && !header.icon"
              >
                {{ getNestedValue(row, header.key) }}
              </span>
              <span
                :data-test-id="id + '-text-second-' + rowIndex"
                v-if="!header.type && !header.type2 && !header.date"
              ></span>
              <span :data-test-id="id + '-text-third-' + rowIndex" v-if="!header.type && !header.type2 && !header.date">
                {{ row[header.key3] }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="data?.length === 0 && !searchTerm"
        class="bg-medosync-white p-6 flex flex-col items-center justify-center border-2 border-medosync-blue"
      >
        <img src="@/assets/img/medosync-logo-w-text.png" class="w-52 mb-5" />
        <p class="text-center text-medosync-black font-bold mb-6">There are currently no appointments available.</p>
        <!-- Function currently unavailable -->
        <!-- <button
          data-test-id="upload-patient-data-btn"
          type="button"
          class="flex justify-center items-center bg-medosync-blue px-16 py-3 rounded-full mb-8 w-64"
          @click="uplodaModal"
        >
          <i class="fa-solid fa-upload fa-xl pr-3"></i>
          <span data-test-id="upload-patient-data-btn" class="block whitespace-nowrap">Upload patient data</span>
        </button> -->

        <button
          @click="addPatientManuallyModal()"
          type="button"
          class="flex justify-center items-center bg-white border border-medosync-blue text-black px-16 py-3 rounded-full mb-8 w-64"
        >
          <i class="fa-regular fa-plus fa-xl pr-3 text-black"></i>
          <span class="block whitespace-nowrap">Add patient manually</span>
        </button>

        <button
          type="button"
          class="flex justify-center items-center bg-white border border-medosync-blue text-black px-16 py-3 rounded-full w-64"
          @click="showManageModal = true"
        >
          <i class="fa-regular fa-plus fa-xl pr-3 text-black"></i>
          <span class="block whitespace-nowrap">Add appointment manually</span>
        </button>
      </div>

      <div
        v-if="data?.length === 0 && searchTerm"
        class="bg-medosync-white p-6 flex flex-col justify-center border-2 border-medosync-blue"
      >
        <p class="text-left text-medosync-black mb-16">
          We are sorry. We were not able to find an appointment with the keyword: {{ searchTerm }}
        </p>
        <div class="flex justify-center w-full">
          <button
            type="button"
            data-test-id="upload-patient-data-btn"
            class="flex justify-center items-center bg-medosync-blue px-16 py-3 rounded-full mb-8 w-64"
            @click="showManageModal = true"
          >
            <i class="fa-regular fa-plus fa-xl pr-3 text-black"></i>
            <span class="block whitespace-nowrap">Add appointment manually</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue';
import StringUtility from 'utils/stringUtility';
import ComboBoxDash from '@/components/claim-forms/components/ComboBoxDash.vue';
import FormStatus from '@/components/claim-forms/components/FormStatus.vue';
import BaseInsurerForm from '@/components/modal/SendInsurerForm/BaseInsurerForm.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import Modal from '@/components/modal/Modal';
import SearchBoxDash from '@/components/claim-forms/components/SearchBoxDash.vue';
import InsuranceCompany from '@/components/form-fields/InsuranceCompany.vue';
import InsurancePolicy from '@/components/form-fields/InsurancePolicy.vue';
import { OpenAPIJSON } from 'api/openapi';
import ClaimFormComp from './ClaimFormComp.vue';
import { PreAdmitState } from 'enums';

// Props
const props = defineProps({
  id: { default: 'new-table-component' },
  headers: { default: null },
  data: { default: null },
  consultantId: { default: '' },
  optionAddClick: { default: false },
  searchTerm: { default: '' },
  datePickerdata: { default: null },
  datePickerdataAdmission: { default: null },
  datePickerdataDischarge: { default: null },
  summary: { default: null },
  dataSummary: { default: [] },
  dataSummaryAdmission: { default: [] },
  dataSummaryDischarge: { default: [] },
  refreshIcon: { default: false }
});

// Emits
const emit = defineEmits(['lineClick', 'filteredItens', 'open-upload-modal']);

// Reactive State
const state = reactive({
  highlightedRow: -1,
  isLoading: true
});

const WHITE_LIST_ENVELOP_HOSTNAMES = [
  'localhost',
  'dev.hospital.medosync.com',
  'uat.hospital.medosync.com',
  'hospital.medosync.com',
  'uat.beacon.medosync.com',
  'beacon.medosync.com'
];
const hostname = window.location.hostname;

// Computed Properties for button disabled states
const disabledButtonAddAppointment = computed(() => {
  return (
    !claim.value.insurance[0].insurer?.id ||
    !claim.value.insurance[0].policy_plan?.id ||
    !formattedDate.value ||
    !timerPickerAdmi.value
  );
});

const disabledButtonAddPatient = computed(() => {
  return (
    !claim.value.patient.first_name ||
    !claim.value.patient.last_name ||
    !claim.value.patient.dob ||
    !claim.value.insurance[0].insurer?.id ||
    !claim.value.insurance[0].policy_plan?.id ||
    !formattedDate.value ||
    !timerPickerAdmi.value
  );
});

// References and States
const selectedItens = ref('');
const manualydisabled = ref(true);
const admissionItens = ref('');
const dischargedItens = ref('');
const addPatientManually = ref(false);
const timerPickerAdmi = ref('');
const toast = ref(null);
const errorStatusSearch = ref('');
const infoMrn = ref('');
const infoPatientName = ref('');
const infoDob = ref('');
const sendInsurerFormModal = ref(false);
const patientInfo = ref(null);
const showTooltip = ref(false);
const tooltipText = ref('');
const tooltipX = ref(0);
const tooltipY = ref(0);
const showManageModal = ref(false);
const errorValue = ref(false);
const claims = computed(() => props.data);
const policy_name = ref('');
//const dateValue = ref([]);
const formattedDate = ref(null);
const selectedAppointmentId = ref(null);
const dataList = ref([
  { id: 14, name: 'Self Pay', code: 'self_pay' },
  { id: 4, name: 'Laya Healthcare ', code: 'laya_inpatient' },
  { id: 5, name: 'Irish Life Hospital', code: 'irish_life_hospital' },
  { id: 5, name: 'Irish Life Outpatient Scan', code: 'irish_life_outpatient_scan' },
  { id: 5, name: 'Irish Life Hospital 2022', code: 'irish_life_hospital2022' },
  { id: 6, name: 'VHI Hospital', code: 'vhi_hospital' },
  { id: 6, name: 'VHI MRI', code: 'vhi_mri' },
  { id: 6, name: 'VHI Oncology', code: 'vhi_oncology' },
  { id: 6, name: 'VHI CT', code: 'vhi_c_t' },
  { id: 6, name: 'VHI Surgical', code: 'vhi_surgical' },
  { id: 9, name: 'ESB', code: 'e_s_b' },
  { id: 7, name: 'Garda', code: 'garda' },
  { id: 11, name: 'HSE Treatment Referral', code: 'hse_treatment_referral' },
  { id: 8, name: 'Prison Officers', code: 'prison_officers' },
  { id: 10, name: 'Cork University Hospital', code: 'cork_university_hospital' },
  { id: 11, name: 'HSE', code: 'h_s_e' },
  { id: 12, name: 'National Treatment Purchase Fund', code: 'national_treatment_purchase_fund' }
]);

const formType = ref('');

const claim = ref({
  patient: {
    mrn: '',
    title: '',
    first_name: '',
    last_name: '',
    dob: '',
    gender: '',
    contact: {
      postal_address: [''],
      telephone_landline: null,
      telephone_mobile: null,
      telephone_fax: null,
      email: null
    }
  },
  hospital_appointment_id: null,
  scheduled_on: new Date().toISOString().substring(0, 10),

  insurance: [
    {
      insurer: {
        type: 'name',
        name: ''
      },
      policy_plan: {
        type: 'name',
        name: ''
      },
      policy_number: '',
      claim_form_type: {
        type: 'forms',
        cf_types: []
      }
    }
  ]
});

// Methods for UI actions
const uplodaModal = () => {
  emit('open-upload-modal');
};
const disableDates = date => {
  const allowedDates = props.dataSummary.map(dateStr => {
    let d = new Date(dateStr);
    d.setHours(0, 0, 0, 0);
    return d.getTime();
  });

  let dateTimestamp = new Date(date);
  dateTimestamp.setHours(0, 0, 0, 0);
  dateTimestamp = dateTimestamp.getTime();

  return !allowedDates.includes(dateTimestamp);
};

const disableDatesAdmission = date => {
  const allowedDates = props.dataSummaryAdmission.map(dateStr => {
    let d = new Date(dateStr);
    d.setHours(0, 0, 0, 0);
    return d.getTime();
  });

  let dateTimestamp = new Date(date);
  dateTimestamp.setHours(0, 0, 0, 0);
  dateTimestamp = dateTimestamp.getTime();

  return !allowedDates.includes(dateTimestamp);
};

const disableDatesDischarge = date => {
  const allowedDates = props.dataSummaryDischarge.map(dateStr => {
    let d = new Date(dateStr);
    d.setHours(0, 0, 0, 0);
    return d.getTime();
  });

  let dateTimestamp = new Date(date);
  dateTimestamp.setHours(0, 0, 0, 0);
  dateTimestamp = dateTimestamp.getTime();

  return !allowedDates.includes(dateTimestamp);
};

const isValidDate = dateString => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

  if (!dateRegex.test(dateString)) {
    return false;
  }

  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

const updateClaimDate = date => {
  manualydisabled.value = true;
  policy_name.value = '';
  claim.value.insurance[0] = {};
  if (date) {
    manualydisabled.value = false;
  } else {
    manualydisabled.value = true;
  }

  if (!timerPickerAdmi.value) {
    toast.value?.showToast('Please Add Time.', 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }

  claim.value.datePickerAdmi = date;
  updateClaimTime(timerPickerAdmi.value);
};

const updateClaimTime = time => {
  claim.value.scheduled_on = `${formattedDate.value}T${time}`;
};

const resetClaims = () => {
  formType.value = '';
  claim.value = {
    patient: {
      mrn: '',
      title: '',
      first_name: '',
      last_name: '',
      dob: '',
      gender: '',
      contact: {
        postal_address: [''],
        telephone_landline: null,
        telephone_mobile: null,
        telephone_fax: null,
        email: null
      }
    },
    hospital_appointment_id: null,
    scheduled_on: new Date().toISOString().substring(0, 10),

    insurance: [
      {
        insurer: {
          type: 'name',
          name: ''
        },
        policy_plan: {
          type: 'name',
          name: ''
        },
        policy_number: '',
        claim_form_type: {
          type: 'forms',
          cf_types: []
        }
      }
    ]
  };

  formattedDate.value = null;
  timerPickerAdmi.value = '';
};

const insuranceChanged = () => {
  claim.value.insurance[0].policy_plan = {};
  policy_name.value = '';
};

const sendPatientRegistrationForm = async value => {
  sendInsurerFormModal.value = false;
  if (value.success) {
    toast.value?.showToast('Registration form sent.', 'fas fa-check', 'success');
  } else {
    toast.value?.showToast(
      'An error occurred. Please contact the support.',
      'fa-solid fa-triangle-exclamation',
      'error'
    );
  }

  try {
    // @TODO - use only instance of OpenAPIJSON - initialize it in the setup
    const oapi = new OpenAPIJSON();
    await oapi.registration_state_change({
      appointment: {
        appointment: {
          type: 'id',
          appointment_id: selectedAppointmentId.value
        }
      },
      reason: 'UI CHANGE',
      target_state: PreAdmitState.AwaitingReplayForPatient
    });
    // @TODO -  on success change only this appointment status, there is no need to refresh all the data
  } catch (error) {
    console.error(error);
  }
};

const appointmentCreate = async () => {
  claim.value.insurance[0].policy_number = '';
  const oapi = new OpenAPIJSON();
  try {
    const response = await oapi.appointment_create(claim.value);
    if (addPatientManually.value === true) {
      toast.value?.showToast('Appointment and Patient created.', 'fas fa-check', 'success');
    } else {
      toast.value?.showToast('Appointment created.', 'fas fa-check', 'success');
    }

    getAppointmentDetailsRefresh(response.appointment_id);
    setModalOff();
    setAddPatientManually();
  } catch (error) {
    toast.value?.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
  }
};
//HARDCODED TO FIONA
const getMedics = async () => {
  try {
    const oapi = new OpenAPIJSON();
    const res = await oapi.searchDoctors(null, null, null, null, false);

    if (res.length === 1) {
      dataList.value = [
        { id: 14, name: 'Self Pay', code: 'self_pay' },
        { id: 4, name: 'Laya Healthcare', code: 'laya_inpatient' },
        { id: 5, name: 'Irish Life Hospital 2022', code: 'irish_life_hospital2022' },
        { id: 6, name: 'VHI Surgical', code: 'vhi_surgical' },
        { id: 9, name: 'ESB', code: 'e_s_b' },
        { id: 7, name: 'Garda', code: 'garda' },
        { id: 11, name: 'HSE Treatment Referral', code: 'hse_treatment_referral' },
        { id: 8, name: 'Prison Officers', code: 'prison_officers' },
        { id: 10, name: 'Cork University Hospital', code: 'cork_university_hospital' },
        { id: 11, name: 'HSE', code: 'h_s_e' },
        { id: 12, name: 'National Treatment Purchase Fund', code: 'national_treatment_purchase_fund' }
      ];
    }
  } catch (error) {
    console.error('Error fetching doctors:', error);
  }
};

const getAppointmentDetailsRefresh = async newValue => {
  const oapi = new OpenAPIJSON();
  try {
    const response = await oapi.appointment_search({
      appointment_id: newValue
      // pre_admission: true
    });

    if (response.items && Array.isArray(response.items) && response.items.length > 0) {
      const clonedResponseItems = JSON.parse(JSON.stringify(response.items));
      const dataValue = Array.isArray(props.data) ? JSON.parse(JSON.stringify(props.data)) : [];
      const updatedDataValue = [...clonedResponseItems, ...dataValue];
      emit('refreshFirstPosition', updatedDataValue);
    }
  } catch (error) {
    console.error(error);
  }
};

// More UI actions
const checkValue = () => {
  checkDate();
};
const checkValuePatient = () => {
  claim.value.patient.gender = 'unspecified';
  claim.value.patient.mrn = null;
  claim.value.hospital_appointment_id = null;
  checkDob();
};

const checkDate = () => {
  const result = isValidDate(claim.value.scheduled_on);
  if (!result) {
    toast.value?.showToast('Invalid date format or date is not valid.', 'fa-solid fa-triangle-exclamation', 'error');
  } else {
    appointmentCreate();
  }
};

const checkDob = () => {
  if (!claim.value.patient.dob) {
    toast.value?.showToast('Please Add DOB.', 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }
  const dob = claim.value.patient.dob;
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(dob)) {
    toast.value?.showToast('Invalid DOB format. Please use yyyy-mm-dd.', 'fa-solid fa-triangle-exclamation', 'error');
    return;
  }
  const date = new Date(dob);
  const [year, month, day] = dob.split('-').map(Number);
  if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
    toast.value?.showToast(
      'Invalid DOB date. Please provide a valid date.',
      'fa-solid fa-triangle-exclamation',
      'error'
    );
    return;
  }
  // Date is valid
  checkDate();
};

const setModalOff = () => {
  resetClaims();
  infoDob.value = '';
  infoMrn.value = '';
  infoPatientName.value = '';
  errorStatusSearch.value = '';
  errorValue.value = false;
  showManageModal.value = false;
  claim.value.patient.first_name = '';
  claim.value.patient.last_name = '';
  claim.value.patient.dob = '';
};

const addPatientManuallyModal = () => {
  addPatientManually.value = true;
  claim.value.patient.first_name = '';
  claim.value.patient.last_name = '';
  claim.value.patient.dob = '';
};

const addAppointmetModal = () => {
  addPatientManually.value = false;
  showManageModal.value = true;
};

const setAddPatientManually = () => {
  resetClaims();
  claim.value.patient.first_name = '';
  claim.value.patient.last_name = '';
  claim.value.patient.dob = '';
  addPatientManually.value = false;
  infoDob.value = '';
  infoMrn.value = '';
  infoPatientName.value = '';
  errorStatusSearch.value = '';
  errorValue.value = false;
  showManageModal.value = false;
};

const getNestedValue = (obj, path) => {
  if (!path) return undefined;
  const parts = path.replace(/\[(\w+)\]/g, '.$1').split('.');
  return parts.reduce((acc, part) => {
    if (acc && part in acc) {
      return acc[part];
    } else {
      return undefined;
    }
  }, obj);
};

const getTooltipText = tooltipText => {
  const tooltips = {
    do_not_check: 'Insurance Policy Checker is deactivated.',
    patient_signature: 'Missing Patient Signature',
    queed: 'Insurance Policy State is checked',
    valid_and_matching: 'Insurance Policy is valid',
    nearly_and_matching:
      'Insurance Policy does not match with insurance details. Please check patient’s data in your hospital system.',
    does_not_match: 'Insurance Policy is invalid.',
    queued_service_unavailable: 'Insurance Policy State is checked if the service is available again.',
    search_bar_above: null
  };

  return tooltips[tooltipText] || '';
};

const getIconAttributes = check => {
  let iconClass;
  let iconStyle = { zIndex: '0' };

  switch (check) {
    case 'do_not_check':
      iconClass = 'fa-duotone fa-circle';
      break;
    case 'queed':
      iconClass = 'fa-solid fa-circle-dashed';
      break;
    case 'valid_and_matching':
      iconClass = 'fa-solid fa-circle-check';
      iconStyle.color = '#006000';
      break;
    case 'nearly_and_matching':
      iconClass = 'fa-solid fa-circle-exclamation';
      iconStyle.color = '#F07B01';
      break;
    case 'does_not_match':
      iconClass = 'fa-solid fa-circle-xmark';
      iconStyle.color = '#AD0000';
      break;
    case 'queued_service_unavailable':
      iconClass = 'fa-solid fa-circle-dashed';
      break;
  }

  return {
    class: `${iconClass} fa-xl my-2 flex items-center justify-center text-center min-h-[30px] m-auto leading-3`,
    style: iconStyle
  };
};

// Error Handling
const handleError = errorStatus => {
  errorStatusSearch.value = errorStatus;
};

const updateFormType = handlecode => {
  formType.value = handlecode;
};

watch(formType, newValue => {
  const insurance = claim.value.insurance[0];
  if (!insurance.claim_form_type) {
    insurance.claim_form_type = {
      type: 'forms',
      cf_types: []
    };
  }
  insurance.claim_form_type.cf_types = [newValue];
});

watch(
  () => props.datePickerdata,
  newVal => {
    selectedItens.value = newVal;
  },
  { immediate: true }
);

watch(
  () => props.datePickerdataAdmission,
  newVal => {
    admissionItens.value = newVal;
  },
  { immediate: true }
);

watch(
  () => props.datePickerdataDischarge,
  newVal => {
    dischargedItens.value = newVal;
  },
  { immediate: true }
);

// Handle values from search
const handleValue = value => {
  if (!value) {
    infoMrn.value = '';
    infoPatientName.value = '';
    infoDob.value = '';
    resetClaims();
    return;
  }
  claim.value.patient = value;
  infoMrn.value = value.mrn;
  infoPatientName.value = value.full_name;
  infoDob.value = value.dob;
};

const showMessageIcon = preAdmitState => {
  if (
    preAdmitState === PreAdmitState.RequirementsAreNotMet ||
    preAdmitState === PreAdmitState.AwaitingReplayForPatient ||
    preAdmitState === PreAdmitState.ReviewPatientResponse ||
    preAdmitState === PreAdmitState.NeedsRevision
  ) {
    return true;
  }
};

const envelopeClick = item => {
  patientInfo.value = item?.patient;
  selectedAppointmentId.value = item?.appointment_id;
  sendInsurerFormModal.value = true;
};

const lineClick = item => {
  emit('lineClick', item);
};

const showItemTooltip = (event, text) => {
  showTooltip.value = true;
  tooltipText.value = Array.isArray(text) ? text[0] : text;
  tooltipX.value = event.pageX;
  tooltipY.value = event.pageY;
};

const hideItemTooltip = () => {
  showTooltip.value = false;
};

const getFilteredComboBoxDash = filterState => {
  emit('filteredItens', filterState);
};

const getFilteredDatePicker = filterState => {
  admissionItens.value = null;
  dischargedItens.value = null;
  emit('filteredItensDatePicker', filterState);
};

const getFilteredDatePickerAdmission = filterState => {
  dischargedItens.value = null;
  selectedItens.value = null;
  emit('filteredItensDatePickerAdmission', filterState);
};

const getFilteredDatePickerDischarge = filterState => {
  admissionItens.value = null;
  selectedItens.value = null;
  emit('filteredItensDatePickerDischarge', filterState);
};

const refreshDashboard = () => {
  emit('refresh');
};

const humanDate = date => {
  return StringUtility.formatDate(date);
};

const appointmentDate = date => {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');
  return `${day}-${month}-${year} - ${hours}:${minutes}`;
};

const highlightRow = index => {
  state.highlightedRow = index;
};

const resetRowColor = () => {
  state.highlightedRow = -1;
};

const getIconClass = check => {
  switch (check) {
    case 'patient_signature':
      return 'fa-regular fa-circle-exclamation fa-xl my-2 flex items-center justify-center text-center min-h-[30px] m-auto leading-3';

    default:
      return '';
  }
};

onMounted(() => {
  state.language = navigator.language || navigator.userLanguage;
  state.claims = props.data;
  getMedics();
});
</script>

<style scoped lang="scss">
.header-cell-table {
  background-color: #cacdd6;
  color: black;
  font-weight: bold;
  text-align: center;
  white-space: normal;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: normal;
  margin: 5px;
  height: 40px;
}

.table-cell {
  word-wrap: break-word;
  vertical-align: top;
  text-align: center;
  border-bottom: 1px solid #d1d1d1;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  padding-left: 5px;
}

.table-cell-left {
  word-wrap: break-word;
  vertical-align: top;
  border-bottom: 1px solid #d1d1d1;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}

.even-row {
  background-color: white;
}

.odd-row {
  background-color: #eeeff3;
}

tr:hover {
  background-color: #00a7e1;
}

.resize-cell {
  width: 10px;
  height: 15px;
  cursor: col-resize;
  background-color: #ccc;
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.resize-icon {
  font-size: 14px;
  color: #333;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip {
  position: absolute;
  background-color: #707070;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  max-width: 100px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  z-index: 999000;
  pointer-events: none;
  transform: translate(10px, -50%);
}

button {
  height: 48px;
  width: 250px;
  border-radius: 50px;

  &.cancel {
    background: #cbd5e0;
  }

  &.confirm {
    background: #00a7e1;
  }
}
.mx-input-wrapper .mx-input {
  background-color: #808080 !important;
}

::v-deep input[data-test-id='scheduled_on-date-select']::placeholder {
  color: black !important;
  font-size: 13px !important;
  font-family: sans-serif !important;
}

::v-deep input[data-test-id='discharged_on-date-select']::placeholder {
  color: black !important;
  font-size: 13px !important;
  font-family: sans-serif !important;
}

::v-deep input[data-test-id='admitted_on-date-select']::placeholder {
  color: black !important;
  font-size: 13px !important;
  font-family: sans-serif !important;
}

::v-deep #admission-time-picker-wrapper .mx-input-wrapper .mx-icon-calendar svg {
  position: relative;
  left: -52px; /* Adjust this value to move the icon left */
}

::v-deep #scheduled_on-time-picker-wrapper .mx-input-wrapper {
  position: relative;
  left: 14px; /* Adjust this value to move the icon left */
}
::v-deep #scheduled_on-time-picker-wrapper .mx-input-wrapper .mx-icon-calendar svg {
  position: relative;
  left: -22px; /* Adjust this value to move the icon left */
}
::v-deep #scheduled_on-time-picker-wrapper .mx-input-wrapper .mx-icon-clear svg {
  position: relative;
  left: -22px; /* Adjust this value to move the icon left */
}

::v-deep #discharge-time-picker-wrapper .mx-input-wrapper {
  position: relative;
  left: 12px; /* Adjust this value to move the icon left */
}
::v-deep #discharge-time-picker-wrapper .mx-input-wrapper .mx-icon-calendar svg {
  position: relative;
  left: -22px; /* Adjust this value to move the icon left */
}

::v-deep #discharge-time-picker-wrapper .mx-input-wrapper .mx-icon-clear svg {
  position: relative;
  left: -22px; /* Adjust this value to move the icon left */
}

::v-deep #admission_on-time-picker-wrapper .mx-input-wrapper {
  position: relative;
  left: 12px; /* Adjust this value to move the icon left */
}
::v-deep #admission_on-time-picker-wrapper .mx-input-wrapper .mx-icon-calendar svg {
  position: relative;
  left: -22px; /* Adjust this value to move the icon left */
}
::v-deep #admission_on-time-picker-wrapper .mx-input-wrapper .mx-icon-clear svg {
  position: relative;
  left: -22px; /* Adjust this value to move the icon left */
}
</style>
