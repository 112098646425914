<script setup lang="ts">
import { BaseInput, BaseAddressInput, BaseListInput } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="pomas-membership-details-title" title="Membership Details" />
    <BaseInput
      id="patient-name"
      name="patientName"
      class="mt-6"
      label="Patient Name*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-name')"
    />
    <BaseAddressInput
      id="patient-address"
      name="patientAddress"
      class="mt-6 sm:mt-12"
      label="Address*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-address')"
    />
    <BaseInput
      id="patient-partner"
      name="patientPartner"
      class="mt-6 sm:mt-12"
      label="Spouse / Partner"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-partner')"
      :enableValidation="false"
    />
    <BaseListInput
      id="patient-children"
      name="patientChildren"
      class="mt-6 sm:mt-12"
      label="Children "
      placeholder="Child"
      :edit-mode="editMode"
      :input-list-length="6"
      :enableValidation="false"
    />
  </div>
</template>
