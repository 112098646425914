<template>
  <div class="nav-section" :class="navActive ? 'opened-menu' : 'closed-menu'">
    <div :class="navActive ? 'collapse-menu' : 'collapse-menu-off'" class="mb-5" @click="toggleMenu">
      <i v-if="navActive" class="fas fa-chevron-left"></i>
      <i v-if="!navActive" class="fas fa-chevron-right py-2"></i>
      <span v-if="navActive" class="ml-3 nav-title">Settings</span>
    </div>

    <div v-show="navActive" @click='goTo(item.link)' v-for='(item, index) in items' :key='index' class="nav-item" :class="getRouteName == item.link ? 'nav-active-bg' : ''">
      <div class="item-name">
        {{item.name}}
      </div>
      <div class="item-icon">
        <i class="nav-icon-size" :class="item.icon"></i>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Settings-menu',
  components: {

  },
  data () {
    return {
        items: [
            { name: 'Claims Table', icon: 'fas fa-table', link: '/settings/claims-table' },
            { name: 'Workflow', icon: 'fas fa-list-check', link: '/settings/menu-management' },
            { name: 'Tags', icon: 'fas fa-tag', link: '/settings/tags-management' },
            //{ name: 'Manage Tags Settings', icon: 'fas fa-tasks', link: '/settings/tags' },
            { name: 'Manage Users', icon: 'fas fa-users', link: '/settings/manage-users' },
            { name: 'Manage Groups', icon: 'fas fa-users-viewfinder', link: '/settings/groups' },
            { name: 'Manage Procedure Codes', icon: 'fas fa-barcode', link: '/settings/procedure-codes' },
        ],
        navActive: true,
    }
  },

  methods: {
    goTo (link) {
      this.$router.push({path: link})
    },
    toggleMenu () {
      this.navActive = !this.navActive;
      this.$emit('set-menu', this.navActive)
    },
  },

  computed: {
    getRouteName () {
      switch (this.$route.name) {
        case 'Doctors-dashboard': return '/settings/manage-users'
        case 'Create-user': return '/settings/manage-users'
        case 'Edit-doctor': return '/settings/manage-users'
        case 'Groups': return '/settings/groups'
        case 'Create-group': return '/settings/groups'
        case 'Edit-group': return '/settings/groups'
        default: return this.$route.fullPath
      }
    }
  },

  mounted () {

  }
}
</script>

<style lang='scss' scoped>
  .opened-menu {
    width: 210px;
  }
  .closed-menu {
    width: 30px;
  }
  .nav-section {
    height: 100vh;
    padding-top: 32px;
    font-size: 14px;
    transition: 0.5s;
    overflow: hidden;
    .nav-item {
      display: flex;
      justify-content: space-between;
      width: 200px;
      margin-bottom: 15px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 20px;
      font-family: 'Roboto';
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #0C276C;
      cursor: pointer;

      :hover {
        opacity: 0.75;
      }

      .item-name {
        float: left;
        width: 100px;
      }

      .item-icon {
        display: flex;
        height: 100%;
        margin-right: 10px;
        font-size: 16px;
        font-weight: 400;
        align-items: center;
        align-self: center;
      }

    }

    .nav-item-active {
      border-left: 5px solid #23A6F0;
      color: #23A6F0;
    }

    .item-name {
      padding-top: 2px;
    }

    .collapse-menu {
      margin-top: 20px;
      padding-left: 10px;
      text-align: left;
      cursor: pointer;
      transition: all 0.25s;
      
      &:hover {
        opacity: 0.65;
      }
    }

    .collapse-menu-off {
      margin-top: 20px;
      padding-left: 10px;
      text-align: left;
      cursor: pointer;
      transition: all 0.25s;
      background-color: #00A7E1;

      &:hover {
        opacity: 0.65;
      }
    }

  }

  .nav-title {
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 600;
    color: #0C276C;
  }

</style>