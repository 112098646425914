<template>
  <div
    class="menu-transition overflow-hidden"
    :class="isActive ? 'component-content-section' : 'component-content-section-no-menu'"
  >
    <div class="optional-nav">
      <SettingsMenu @set-menu="setMenuActive" />
    </div>

    <div v-if="selectedLogin">
      <div class="claim-title medo-secondary">
        <router-link to="/settings/manage-users">
          <i class="fas fa-chevron-left medo-primary mr-5 custom-back"></i>
        </router-link>
        Edit {{ selectedLogin.userName }}
      </div>

      <div style="clear: both"></div>

      <!-- start -->
      <div class="col-12 mt-5">
        <div class="col-4 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Password:</label>
          </div>
          <div class="mt-1 pb-2">
            <button
              @click="resetPasswordStart = !resetPasswordStart"
              type="button"
              class="medo-bg-secondary inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Reset Password
            </button>
          </div>
          <div class="mt-2" v-if="resetPasswordStart">
            <div class="flex justify-between">
              <label for="email" class="block text-sm font-medium text-gray-700">New Password:</label>
            </div>
            <input
              v-model="newPassword"
              type="text"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby="email-optional"
            />
            <button
              @click="changePassword"
              type="button"
              class="medo-bg-secondary mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4 overflow-auto">
        <div class="col-3 pr-5">
          <div class="flex">
            <label for="email" class="block text-sm font-medium text-gray-700">Disable User:</label>

            <input
              v-model="isDisabled"
              type="checkbox"
              class="ml-2 custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
        </div>
      </div>
      <!-- end -->

      <div style="clear: both"></div>

      <div v-if="logins != null" class="col-6 mt-3">
        <h4 class="text-lg font-medium">Assign doctors to admin</h4>
        <vSelect multiple v-model="selectedLogins" label="userName" :options="logins"></vSelect>
      </div>

      <div style="clear: both"></div>

      <div class="sub-title mt-5 medo-secondary font-bold text-lg">Person Details</div>

      <div style="clear: both"></div>

      <div class="col-12 mt-5 overflow-auto">
        <div class="col-4 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Title:</label>
          </div>
          <div class="mt-1">
            <select
              v-model="person.title"
              class="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="CONSULTANT">Consultant</option>
              <option value="DOCTOR">Doctor</option>
              <option value="NURSE">Nurse</option>
              <option value="MR">Mr</option>
              <option value="MS">Ms</option>
              <option value="MISS">Miss</option>
              <option value="MRS">Mrs</option>
              <option value="MASTER">Master</option>
              <option value="PROFESSOR">Professor</option>
              <option value="UNSPECIFIED">Unspecified</option>
            </select>
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <!-- start -->
      <div class="col-12 mt-5 overflow-auto">
        <div class="col-4 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">First Name:</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <input
              v-model="person.firstName"
              type="text"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby="email-optional"
            />
          </div>
        </div>

        <!--
            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Middle Name:</label>
                </div>
                <div class="mt-1">
                    <input v-model='person.middleNames' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>
            -->

        <div class="col-4">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Last Name:</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <input
              v-model="person.lastName"
              type="text"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby="email-optional"
            />
          </div>
        </div>
      </div>
      <!-- end -->

      <div style="clear: both"></div>

      <!-- start -->
      <div class="col-12 mt-5">
        <div class="col-4 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Gender:</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-5">
            <select
              v-model="person.gender"
              class="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
              <option value="UNSPECIFIED">Unspecified</option>
              <option value="OTHER">Other</option>
            </select>
          </div>
        </div>

        <div class="col-6 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Date of Birth:</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <CustomDatePicker v-model="person.dateOfBirth" />
          </div>
        </div>
      </div>
      <!-- end -->

      <div style="clear: both"></div>

      <div class="col-12 mt-5 overflow-auto">
        <div class="col-4 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">IMC Medical Number:</label>
          </div>
          <div class="mt-1">
            <input
              v-model="person.medicAttributes.medicalNumber"
              type="text"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby="email-optional"
            />
          </div>
        </div>

        <div class="col-4 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Clinician Speciality:</label>
          </div>
          <div class="mt-1">
            <select
              v-model="selectedSpeciality"
              class="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option v-for="(spec, index) in specialisations" :key="index" :value="spec.specialisationType">
                {{ spec.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <div class="sub-title mt-5 medo-secondary font-bold text-lg">Clinic Address</div>

      <div style="clear: both"></div>

      <PostalAddress v-model="person.clinicContact.postalAddress" />

      <!-- start -->
      <div class="col-12 mt-5 overflow-auto">
        <div class="col-4 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Fax Number (optional)</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <input
              v-model="person.clinicContact.telephoneFax"
              type="text"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby="email-optional"
            />
          </div>
        </div>
      </div>
      <!-- end -->

      <div style="clear: both"></div>

      <!-- start -->
      <div class="col-12 mt-5 overflow-auto">
        <div class="col-4 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Mobile Phone (optional)</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <input
              v-model="person.clinicContact.telephoneMobile"
              type="text"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby="email-optional"
            />
          </div>
        </div>

        <div class="col-4 pr-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Office Phone (optional)</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <input
              v-model="person.clinicContact.telephoneLandline"
              type="text"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              aria-describedby="email-optional"
            />
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <div class="col-12 mt-8 text-right">
        <button
          @click="updateLogin"
          type="button"
          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Edit User
        </button>
      </div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import api from 'api/api';
import { api_store } from '@/store';

import SettingsMenu from '@/views/settings/menu/Index.vue';
import CustomDatePicker from '@/components/claim-forms/components/Datepicker.vue';
import vSelect from 'vue-select';
import PostalAddress from '@/components/form-fields/PostalAddress.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'Edit-doctor',
  components: {
    PostalAddress,
    CustomDatePicker,
    SettingsMenu,
    vSelect,
    ToastComponent
  },
  data() {
    return {
      resetPasswordStart: false,
      newPassword: '',
      logins: null,
      medics: null,
      hospitals: null,
      selectedLogins: null,
      selectedLogin: null,
      isDisabled: null,
      specialisations: null,
      selectedSpeciality: null,

      person: {
        title: 'UNSPECIFIED',
        firstName: '',
        middleNames: '',
        lastName: '',
        preferredFirstName: '',
        preferredLastName: '',
        gender: 'UNSPECIFIED',
        dateOfBirth: new Date(),
        medicAttributes: { medicalNumber: '' },
        deceasedDate: null,
        clinicContact: {
          postalAddress: [],
          telephoneLandline: null,
          telephoneMobile: null,
          telephoneFax: null,
          email: null
        },
        departmentContact: {
          postalAddress: [],
          telephoneLandline: null,
          telephoneMobile: null,
          telephoneFax: null,
          email: null
        }
      },
      isActive: true
    };
  },
  computed: {},
  methods: {
    generateNewPassword() {
      var chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      var passwordLength = 12;
      var password = '';

      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }

      this.newPassword = password;
    },

    findLogin() {
      this.selectedLogin = this.logins.find(login => login.loginAccountId == this.$route.params.id);
      if (this.selectedLogin) this.isDisabled = this.selectedLogin.confirmed == null;

      this.selectedLogins = this.logins.filter(item =>
        this.selectedLogin.associates.some(associate => associate == item.personId)
      );
    },

    async init() {
      await api
        .listLogins()
        .then(res => {
          this.logins = res.data.data.listLogins.logins;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      await api_store.cache
        .dispatch('LIST_MY_CONSULTANTS', {})
        .then(res => {
          this.medics = res;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      await api
        .listMedicSpecialisationTypes()
        .then(res => {
          this.specialisations = res.data.data.listMedicSpecialisationTypes.specs;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      await api
        .listHospitals(1)
        .then(res => {
          this.hospitals = res.data.data.listHospitals.hospital;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      this.findLogin();
      this.generateNewPassword();
    },

    mapAssociates() {
      return this.selectedLogins ? this.selectedLogins.map(login => login.personId) : null;
    },

    async updateLogin() {
      var updatedLogin = { loginAccountId: this.selectedLogin.loginAccountId };

      const mappedAssociates = this.mapAssociates();
      if (mappedAssociates != null) updatedLogin.associates = mappedAssociates;

      await api
        .updateLogin(updatedLogin)
        .then(res => {
          if (res.data.errors)
            this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
          else this.$refs.toast.showToast('Updated user succesfully!', 'fas fa-check', 'success');
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });

      if (this.isDisabled) {
        await api
          .deactivateUser(
            this.selectedLogin.hospitalId,
            this.getHospitalGroupId(this.selectedLogin.hospitalId),
            this.selectedLogin.emailAddress,
            this.selectedLogin.loginAccountId
          )
          .then(res => {
            if (res.data.errors)
              this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
            else this.$refs.toast.showToast('User deactivated!', 'fas fa-check', 'success');
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
      } else {
        await api
          .activateUser(
            this.selectedLogin.hospitalId,
            this.getHospitalGroupId(this.selectedLogin.hospitalId),
            this.selectedLogin.emailAddress,
            this.selectedLogin.loginAccountId
          )
          .then(res => {
            if (res.data.errors)
              this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
            else this.$refs.toast.showToast('User activated!', 'fas fa-check', 'success');
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
      }
    },

    async changePassword() {
      const newPasswordObject = {
        hospital: { hospitalId: this.selectedLogin.hospitalId },
        account: { loginId: this.selectedLogin.loginAccountId },
        password: this.newPassword
      };
      await api
        .setUserPassword(newPasswordObject)
        .then(res => {
          if (res.data.errors)
            this.$refs.toast.showToast(res.data.errors[0].message, 'fa-solid fa-triangle-exclamation', 'error');
          else this.$refs.toast.showToast('Updated password succesfully!', 'fas fa-check', 'success');
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },

    getHospitalGroupId(hospitalId) {
      return this.hospitals.find(hospital => hospital.hospitalId == hospitalId).hospitalGroupId;
    },

    setMenuActive(bool) {
      this.isActive = bool;
    }
  },
  created() {},
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}

.custom-back {
  font-size: 28px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.65;
  }
}

.claim-title {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 600;
}

.custom-input {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

.custom-select {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}
</style>
