<template>
    <div class="menu-transition overflow-hidden" :class="isActive ? 'component-content-section' : 'component-content-section-no-menu'">
        <div class="optional-nav">
          <SettingsMenu @set-menu="setMenuActive" />
        </div>

        <div>

        <div class="claim-title medo-secondary">
            <router-link to="/settings/manage-users">
                <i class="fas fa-chevron-left medo-primary mr-5 custom-back"></i> 
            </router-link>
            Create New User
        </div>

        <div style="clear: both"></div>

        <div class="sub-title mt-5 medo-secondary font-bold text-lg">
          Login Details
        </div>

        <div style="clear: both"></div>

        <!-- start -->
        <div class="col-12 mt-5">
            <div class='col-4 pr-5 mt-2' title="Name that appears in the user interface and audit logging">
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Username:</label>
                </div>
                <div class="mt-1">
                    <input v-model="login.username" type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>

            <div class='col-4 pr-5 mt-2' title="For account sign-in">
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Email:</label>
                </div>
                <div class="mt-1">
                    <input v-model="login.email" type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>
        </div>
        <div class="col-12 mt-5">
          <div class='col-4 pr-5 mt-2' title="Set password for account sign-in">
            <div class="flex justify-between">
              <label for="email" class="block text-sm font-medium text-gray-700">Password:</label>
            </div>
            <div class="mt-1">
              <input v-model="login.password" type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
            </div>
          </div>

            <div class='col-4 pr-5 mt-2'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Phone Number (optional):</label>
                </div>
                <div class="mt-1">
                    <input v-model="login.mobile" type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>
        </div>
        <!-- end -->

        <div style="clear: both"></div>

        <div class="sub-title mt-5 medo-secondary font-bold text-lg">
          Person Details
        </div>

        <div style="clear: both"></div>

        <div class="col-12 mt-5 overflow-auto">
            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Title:</label>
                </div>
                <div class="mt-1">
                    <select v-model='person.title' class="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                        <option value="CONSULTANT">Consultant</option>
                        <option value="DOCTOR">Doctor</option>
                        <option value="NURSE">Nurse</option>
                        <option value="MR">Mr</option>
                        <option value="MS">Ms</option>
                        <option value="MISS">Miss</option>
                        <option value="MRS">Mrs</option>
                        <option value="MASTER">Master</option>
                        <option value="PROFESSOR">Professor</option>
                        <option value="UNSPECIFIED">Unspecified</option>
                    </select>
                </div>
            </div>
        </div>

        <div style="clear: both"></div>

        <!-- start -->
        <div class="col-12 mt-5 overflow-auto">
            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">First Name:</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <input v-model='person.firstName' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>

            <!--
            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Middle Name:</label>
                </div>
                <div class="mt-1">
                    <input v-model='person.middleNames' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>
            -->

            <div class='col-4'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Last Name:</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <input v-model='person.lastName' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>
        </div>
        <!-- end -->

        <div style="clear: both"></div>

        <!-- start -->
        <div class="col-12 mt-5">
            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Gender:</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-5">
                  <select v-model='person.gender' class="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
										<option value="UNSPECIFIED">Unspecified</option>
                    <option value="OTHER">Other</option>
                  </select>
                </div>
            </div>

            <div class='col-6 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Date of Birth:</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <CustomDatePicker v-model="person.dateOfBirth" />
                </div>
            </div>
        </div>
        <!-- end -->

        <div style="clear: both"></div>

        <div class="col-12 mt-5 overflow-auto">
            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">IMC Medical Number:</label>
                </div>
                <div class="mt-1">
                    <input v-model='person.medicAttributes.medicalNumber' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>

            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Clinician Speciality:</label>
                </div>
                <div class="mt-1">
                  <select v-model='selectedSpeciality' class="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option v-for="(spec,index) in specialisations" :key="index" :value="spec.specialisationType">{{spec.name}}</option>
                  </select>
                </div>
            </div>
        </div>

        <div style="clear: both"></div>

        <div class="sub-title mt-5 medo-secondary font-bold text-lg">
          Contact Details
        </div>

        <div style="clear: both"></div>

        <!-- start -->
        <div class="col-12 mt-5">
            <div class='col-6 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Valid from:</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <CustomDatePicker v-model="person.contact.validFrom" />
                </div>
            </div>
        </div>
        <!-- end -->

        <div style="clear: both"></div>


        <!-- start -->
        <div class="col-12 mt-5 overflow-auto">
            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">City (required)</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <input v-model='person.contact.city' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>

            <div class='col-4 pr-5'>
              <div class="flex justify-between">
                <label for="email" class="block text-sm font-medium text-gray-700">Country (required)</label>
                <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
              </div>
              <div class="mt-1">
                <input v-model='person.contact.country' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
              </div>
            </div>
        </div>
        <!-- end -->

        <div style="clear: both"></div>


        <!-- start -->
        <div class="col-12 mt-5 overflow-auto">
            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">County (optional)</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <input v-model='person.contact.county' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>

            <div class='col-4 pr-5'>
              <div class="flex justify-between">
                <label for="email" class="block text-sm font-medium text-gray-700">Post Code (optional)</label>
                <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
              </div>
              <div class="mt-1">
                <input v-model='person.contact.postCode' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
              </div>
            </div>
        </div>
        <!-- end -->

        <div style="clear: both"></div>

        <!-- start -->
        <div class="col-12 mt-5 overflow-auto">
            <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Business Name (optional)</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <input v-model='person.contact.businessName' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>

            <div class='col-4 pr-5'>
              <div class="flex justify-between">
                <label for="email" class="block text-sm font-medium text-gray-700">Fax Number (optional)</label>
                <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
              </div>
              <div class="mt-1">
                <input v-model='person.contact.faxNumber' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
              </div>
            </div>
        </div>
        <!-- end -->

        <div style="clear: both"></div>

        <!-- start -->
        <div class="col-12 mt-5 overflow-auto">
          <div class='col-4 pr-5'>
            <div class="flex justify-between">
              <label for="email" class="block text-sm font-medium text-gray-700">Mobile Phone (optional)</label>
              <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
            </div>
            <div class="mt-1">
              <input v-model='person.contact.mobilePhone' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
            </div>
          </div>

          <div class='col-4 pr-5'>
            <div class="flex justify-between">
              <label for="email" class="block text-sm font-medium text-gray-700">Office Phone (optional)</label>
              <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
            </div>
            <div class="mt-1">
              <input v-model='person.contact.officePhone' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
            </div>
          </div>
        </div>

        <div class="col-12 mt-5 overflow-auto">
          <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Address Line 1 (optional)</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <input v-model='person.contact.street' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>

          <!-- start -->
            <div class='col-4 pr-5'>
              <div class="flex justify-between">
                <label for="email" class="block text-sm font-medium text-gray-700">Address Line 2 (optional)</label>
                <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
              </div>
              <div class="mt-1">
                <input v-model='person.contact.mobilePhone' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
              </div>
            </div>
        </div>
          <!-- end -->

        <div class="col-12 mt-5 overflow-auto">
          <div class='col-4 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Address Line 3 (optional)</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <input v-model='person.contact.suburb' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>
        </div>

        <div style="clear: both"></div>

        <div class="sub-title mt-5 medo-secondary font-bold text-lg">
          Insurer Specific Consultant Identifier Codes
        </div>

        <div style="clear: both"></div>

        <p>These codes will appear on the footer of the invoice and on the claim forms. Leave blank any fields where it is not applicable.</p>

        <!-- start -->
        <div class="col-12 mt-5">
            <div v-for="(contract, index) in contracts" :key="index" class='col-4 pr-5 mt-2'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">{{contract.name}}</label>
                </div>
                <div class="mt-1">
                    <input v-model="personInsurers[contract.name]" type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>
        </div>
        <!-- end -->

        <div style="clear: both"></div>


<!--         <div v-if="logins != null" class="col-6 mt-3">
            <h4 class="text-lg font-medium">
              Assign doctors to admin
            </h4>
            <vSelect multiple v-model="selectedLogins" label="userName" :options="logins"></vSelect>
        </div> -->


        <div class="col-12 mt-8 text-right">
          <button v-if="!successfulEmail || !successfulPersonId" @click="submitUser" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Create User
          </button>
        </div>

      </div>
      <ToastComponent ref="toast"></ToastComponent>
    </div>
</template>

<script>
import api from 'api/api'
import axios from 'axios'

import SettingsMenu from '@/views/settings/menu/Index.vue'
import CustomDatePicker from '@/components/claim-forms/components/Datepicker.vue'
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
//import vSelect from 'vue-select'


export default {
  name: 'Create-user',
  components: {
    SettingsMenu,
    CustomDatePicker,
    ToastComponent,
    //vSelect
  },
  data () {
    return {
      contracts: null,
      selectedLogins: null,
      specialisations: null,
      selectedSpeciality: "",

      person: {
        title: "UNSPECIFIED",
        firstName: "",
        middleNames: "",
        lastName: "",
        preferredFirstName: "",
        preferredLastName: "",
        gender: "UNSPECIFIED",
        dateOfBirth: new Date(),
        medicAttributes: { medicalNumber: ""},
        deceasedDate: null,
        contact: {
          contactType: "PERSONAL",
          validFrom: new Date(),
          fao: "",
          businessName: "",
          houseNumber: "",
          houseName: "",
          street: "",
          suburb: "",
          city: "",
          county: "",
          country: "Ireland",
          postCode: "",
          homePhone: "",
          faxNumber: "",
          officePhone: "",
          mobilePhone: "",
          email: "",
          vatNumber: "",
          taxNumber: ""
        }
      },

      login: {
        username: "",
        email: "",
        password: "",
        mobile: "",
        hospital: {hospitalId: null},
				aclGroup: {aclGroupId: 7}
      },

      personInsurers: {},

      successfulEmail: null,
      successfulPersonId: null,
      isActive: true,
    }
  },
  computed: {

  },
  methods: {
    async init () {
        let response = await axios.get(import.meta.env.VITE_BASE_SERVER + 'me', {
        headers: { 
          Authorization: localStorage.getItem('_token')
        }
      })

      this.login.hospital.hospitalId = parseInt(response.data.hospital_id)

        await api.listInsurersContracts()
            .then((res) => {
            this.contracts = res.data.data.listInsurerContracts.contracts;
            })
            .catch((error) => {
              this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
            });

        await api.listMedicSpecialisationTypes()
            .then((res) => {
            this.specialisations = res.data.data.listMedicSpecialisationTypes.specs;
            })
            .catch((error) => {
              this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
            });
    },

    async createLogin () {
        await api.createUser(this.login)
            .then(() => {
				this.successfulEmail = this.login.email;
            })
            .catch((error) => {
              this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
            });
    },

    async createPerson () {
        await api.createPerson(this.person)
            .then((res) => {
                this.successfulPersonId = res.data.data.createPerson.personId;
            })
            .catch((error) => {
              this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
            });
    },

    async LinkPersonWithLogin () {
        await api.assignLoginToPersonWithEmail(this.successfulEmail, this.successfulPersonId)
            .then(() => {
              this.$refs.toast.showToast('New User Added!', 'fas fa-check', 'success');
            })
            .catch((error) => {
              this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
            });
    },

    async submitUser () {
        const responseUser = await api.createUser(this.login);
        const responsePerson = await api.createPerson(this.person);

        if(responseUser.data.data.createUser.success)
            this.successfulEmail = this.login.email;

        if(responsePerson.data.data.createPerson.personId)
            this.successfulPersonId = responsePerson.data.data.createPerson.personId;

        if(this.successfulEmail && this.successfulPersonId) {
            this.LinkPersonWithLogin();
        }
    },
    setMenuActive (bool) {
      this.isActive = bool;
    },
  },
  created () {
    
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang='scss' scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #EDEDED;
}

  .custom-back {
      font-size: 28px;
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
          opacity: 0.65;
      }
  }

  .claim-title  {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 600;
  }

  .custom-input {
    height: 40px;
    padding-left: 8px;
    line-height: 40px;
    border: 1px solid #dedede;
  }

  .custom-select {
    height: 40px;
    padding-left: 8px;
    line-height: 40px;
    border: 1px solid #dedede;
  }
</style>