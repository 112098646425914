export const titleOptions = {
  Mr: 'Mr.',
  Ms: 'Ms.',
  Miss: 'Miss.',
  Mrs: 'Mrs.',
  Dr: 'Dr.',
  Prof: 'Prof.'
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const mapFormValuesFromGet = values => {
  return {
    patientName: values.patient?.full_name ?? null,
    patientSurname: values.patient?.last_name ?? null,
    patientForename: values.patient?.first_name ?? null,
    birthDate: values.patient?.dob ?? null,
    mrn: values.patient?.mrn ?? null,
    policyNo: values.insurance?.membership_no1 ?? null,
    memberNo2: values.insurance?.membership_no2 ?? null,
    patientAddress: values.patient?.contact?.postal_address ?? null,
    telephone: values.patient?.contact?.telephone_mobile ?? null,
    email: values.patient?.contact?.email ?? null,
    policyHolderName: values.insurance?.policy_holder ?? null,
    patientTitle: values.patient?.title ?? null,
    claim_form_type: values.claim_form_type ?? null,
    hospitalName: values.hospital_name ?? null,

    treatmentSettingGP: values.questions?.treatment_setting_gp_rooms ?? null,
    treatmentSettingConsultantRooms: values.questions?.treatment_setting_consultant_rooms ?? null,
    treatmentSettingOther: values.questions?.treatment_setting_other ?? null,
    admissionFormSigned: values.questions?.signed_and_dated_form_on_admission_or_hospital_stay ?? null,

    // history of illness
    doctorName: values.questions?.doctor_first_attended_name ?? null,
    doctorNumber: values.questions?.doctor_first_attended_number ?? null,
    firstConsultation: values.history_of_illness?.first_consultation_date ?? null,
    doctorAddress: values.questions?.doctor_first_attended_address ?? null,
    firstTreatment: values.history_of_illness?.date_patient_told_treatment_needed ?? null,
    similarIllness: values.history_of_illness?.similar_illness ?? null,
    similarIllnessDate: values.history_of_illness?.similar_illness_date ?? null,
    similarIllnessDetails: values.history_of_illness?.similar_illness_details ?? null,
    otherSource: values.history_of_illness?.expenses_claimable_from_other_source ?? null,
    otherSourceDetails: values.history_of_illness?.expenses_claimable_from_other_source_details ?? null,
    weeksAppointment: values.history_of_illness?.num_weeks_waiting_for_appointment ?? null,
    weeksAdmission: values.history_of_illness?.num_weeks_waiting_for_admission ?? null,
    weeksScan: values.history_of_illness?.num_weeks_waiting_for_scan ?? null,
    privatePatient: values.history_of_illness?.elected_to_be_private_patient ?? null,
    privatePatientFacility: values.history_of_illness?.xfer_from_elected_to_be_private_patient ?? null,
    researchStudy: values.history_of_illness?.related_to_clinical_research ?? null,

    // unused currently
    firstSymptomsDate: values.history_of_illness?.first_symptoms_date ?? null,
    electedToBePrivatePatientDate: values.history_of_illness?.elected_to_be_private_patient_date ?? null,
    xferToElectedToBePrivatePatient: values.history_of_illness?.xfer_to_elected_to_be_private_patient ?? null,
    treatmentResultOfAccident: values.history_of_illness?.treatment_result_of_accident ?? null,
    similarIllnessId: values.history_of_illness?.similar_illness_id ?? null,

    // injury
    injuryDate: values.injury.date_of_injury ?? null,
    injuryPlace: values.injury.place_of_injury ?? null,
    injuryDescription: values.injury.how_injury_occurred ?? null,
    solicitorName: values.injury.solicitor_name ?? null,
    solicitor: values.questions.claim_expenses_through_solicitor ?? null,
    solicitorAddress: values.injury.solicitor_address ?? null,
    causedByThirdParty: values.injury.caused_by_third_party ?? null,
    thirdPartyLiability: values.injury.third_party_liability ?? null,
    thirdPartyDetails: values.injury.third_party_details ?? null,
    thirdPartyInsurer: values.injury.third_party_insurer ?? null,
    pursueLegalClaim: values.injury.pursue_legal_claim ?? null,
    roadTrafficAccident: values.injury.road_traffic_accident ?? null,
    roadTrafficAccidentDate: values.injury.road_traffic_accident_date ?? null,
    onDuty: values.injury.on_duty ?? null,
    onDutyDate: values.injury.on_duty_date ?? null,
    sporting: values.injury.sporting ?? null,
    sportingDate: values.injury.sporting_date ?? null,
    atWork: values.injury.at_work ?? null,
    atWorkDate: values.injury.at_work_date ?? null,
    fromWar: values.injury.from_war ?? null,
    fromWarDate: values.injury.from_war_date ?? null,
    pursuePiabClaim: values.injury.pursue_piab_claim ?? null,
    pursuePiabClaimContact: values.injury.pursue_piab_claim_contact ?? null,
    patientPartner: values.questions.patient_partner_name ?? null,
    patientChildren: values.questions.patient_children_names ?? null,

    // form_declaration
    signatureDate: values.form_declaration?.date_of_signing ?? null
  };
};

export const mapFormValuesForUpdate = values => {
  if (!values) return null;
  return {
    ...(values.hospitalName !== undefined && { hospital_name: values.hospitalName }),
    patient: {
      ...(values.patientName !== undefined && { full_name: values.patientName }),
      ...(values.patientSurname !== undefined && { last_name: values.patientSurname }),
      ...(values.patientForename !== undefined && { first_name: values.patientForename }),
      ...(values.birthDate !== undefined && { dob: convertDate(values.birthDate) }),
      ...(values.mrn !== undefined && { mrn: values.mrn }),

      ...(values.patientTitle !== undefined && { title: values.patientTitle }),
      contact: {
        ...(values.patientAddress !== undefined && {
          postal_address: values.patientAddress
        }),
        ...(values.telephone !== undefined && { telephone_mobile: values.telephone }),
        ...(values.email && { email: values.email })
      }
    },
    insurance: {
      ...(values.policyNo !== undefined && { membership_no1: values.policyNo }),
      ...(values.memberNo2 !== undefined && { membership_no2: values.memberNo2 }),
      ...(values.policyHolderName !== undefined && {
        policy_holder: values.policyHolderName
      })
    },
    history_of_illness: {
      ...(values.firstConsultation !== undefined && {
        first_consultation_date: values.firstConsultation
      }),
      ...(values.firstTreatment !== undefined && {
        date_patient_told_treatment_needed: values.firstTreatment
      }),
      ...(values.similarIllness !== undefined && {
        similar_illness: values.similarIllness
      }),
      ...(values.similarIllnessDate !== undefined && {
        similar_illness_date: values.similarIllnessDate
      }),
      ...(values.similarIllnessDetails !== undefined && {
        similar_illness_details: values.similarIllnessDetails
      }),
      ...(values.otherSource !== undefined && {
        expenses_claimable_from_other_source: values.otherSource
      }),
      ...(values.otherSourceDetails !== undefined && {
        expenses_claimable_from_other_source_details: values.otherSourceDetails
      }),

      ...(values.weeksAppointment !== undefined && {
        num_weeks_waiting_for_appointment: parseInt(values.weeksAppointment)
      }),
      ...(values.weeksAdmission !== undefined && {
        num_weeks_waiting_for_admission: parseInt(values.weeksAdmission)
      }),
      ...(values.weeksScan !== undefined && {
        num_weeks_waiting_for_scan: parseInt(values.weeksScan)
      }),

      ...(values.privatePatient !== undefined && {
        elected_to_be_private_patient: values.privatePatient
      }),
      ...(values.privatePatientFacility !== undefined && {
        xfer_from_elected_to_be_private_patient: values.privatePatientFacility
      }),
      ...(values.researchStudy !== undefined && {
        related_to_clinical_research: values.researchStudy
      }),

      // unused currently
      ...(values.firstSymptomsDate !== undefined && {
        first_symptoms_date: values.firstSymptomsDate
      }),
      ...(values.electedToBePrivatePatientDate !== undefined && {
        elected_to_be_private_patient_date: values.electedToBePrivatePatientDate
      }),
      ...(values.xferToElectedToBePrivatePatient !== undefined && {
        xfer_to_elected_to_be_private_patient: values.xferToElectedToBePrivatePatient
      }),
      ...(values.treatmentResultOfAccident !== undefined && {
        treatment_result_of_accident: values.treatmentResultOfAccident
      }),
      ...(values.similarIllnessId !== undefined && {
        similar_illness_id: values.similarIllnessId
      })
    },
    injury: {
      ...(values.injuryDate !== undefined && { date_of_injury: values.injuryDate }),
      ...(values.injuryPlace !== undefined && { place_of_injury: values.injuryPlace }),
      ...(values.injuryDescription !== undefined && {
        how_injury_occurred: values.injuryDescription
      }),
      ...(values.solicitorName !== undefined && { solicitor_name: values.solicitorName }),
      ...(values.solicitorAddress !== undefined && {
        solicitor_address: values.solicitorAddress
      }),
      ...(values.causedByThirdParty !== undefined && {
        caused_by_third_party: values.causedByThirdParty
      }),
      ...(values.thirdPartyLiability !== undefined && {
        third_party_liability: values.thirdPartyLiability
      }),
      ...(values.thirdPartyDetails !== undefined && {
        third_party_details: values.thirdPartyDetails
      }),
      ...(values.thirdPartyInsurer !== undefined && {
        third_party_insurer: values.thirdPartyInsurer
      }),
      ...(values.pursueLegalClaim !== undefined && {
        pursue_legal_claim: values.pursueLegalClaim
      }),
      ...(values.roadTrafficAccident !== undefined && {
        road_traffic_accident: values.roadTrafficAccident
      }),
      ...(values.roadTrafficAccidentDate !== undefined && {
        road_traffic_accident_date: values.roadTrafficAccidentDate
      }),
      ...(values.onDuty !== undefined && { on_duty: values.onDuty }),
      ...(values.onDutyDate && { on_duty_date: values.onDutyDate }),
      ...(values.sporting !== undefined && { sporting: values.sporting }),
      ...(values.sportingDate && { sporting_date: values.sportingDate }),
      ...(values.atWork !== undefined && { at_work: values.atWork }),
      ...(values.atWorkDate && { at_work_date: values.atWorkDate }),
      ...(values.fromWar !== undefined && { from_war: values.fromWar }),
      ...(values.fromWarDate && { from_war_date: values.fromWarDate }),
      ...(values.pursuePiabClaim !== undefined && {
        pursue_piab_claim: values.pursuePiabClaim
      }),
      ...(values.pursuePiabClaimContact !== undefined && {
        pursue_piab_claim_contact: values.pursuePiabClaimContact
      })
    },
    questions: {
      ...(values.solicitor !== undefined && { claim_expenses_through_solicitor: values.solicitor }),
      ...(values.treatmentSettingGP !== undefined && {
        treatment_setting_gp_rooms: values.treatmentSettingGP
      }),
      ...(values.treatmentSettingConsultantRooms !== undefined && {
        treatment_setting_consultant_rooms: values.treatmentSettingConsultantRooms
      }),
      ...(values.treatmentSettingOther !== undefined && {
        treatment_setting_other: values.treatmentSettingOther
      }),

      ...(values.doctorName !== undefined && {
        doctor_first_attended_name: values.doctorName
      }),
      ...(values.doctorNumber !== undefined && {
        doctor_first_attended_number: values.doctorNumber
      }),
      ...(values.doctorAddress !== undefined && {
        doctor_first_attended_address: values.doctorAddress
      }),
      ...(values.admissionFormSigned !== undefined && {
        signed_and_dated_form_on_admission_or_hospital_stay: values.admissionFormSigned
      }),
      ...(values.patientPartner !== undefined && { patient_partner_name: values.patientPartner }),
      ...(values.patientChildren !== undefined && { patient_children_names: values.patientChildren })
    },
    ...(values.signatureDate !== undefined &&
      values.signatureDate !== null && {
        form_declaration: {
          ...(values.signatureDate !== undefined && {
            date_of_signing: values.signatureDate + 'T00:00:00'
          }),
          ...(values.signature !== undefined && { signature: values.signature.split(',')[1] })
        }
      })
  };
};

export const updateCombinedValues = (combinedValues, mappedValues, currentUpdateData) => {
  if (mappedValues?.patient?.contact.postal_address) {
    combinedValues.patient.contact.postal_address = mappedValues.patient.contact.postal_address;
  }
  return combinedValues;
};

const convertDate = date => {
  if (date) {
    if (date.startsWith('date ')) {
      date = date.replace('date ', '');
    }
    if (date.includes('-')) {
      return date;
    }
    const dateParts = date.split('/');
    return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
  }
  return null;
};

const mapDoctorName = nameString => {
  if (!nameString) return null;
  const nameParts = nameString.split(' ');
  if (nameParts.length === 2) {
    return {
      type: 'name',
      first_name: nameParts[0],
      last_name: nameParts[1]
    };
  } else if (nameParts.length === 3) {
    return {
      type: 'name',
      title: nameParts[0],
      first_name: nameParts[1],
      last_name: nameParts[2]
    };
  }
};

export const insurerName = type => {
  switch (type.toLowerCase()) {
    case 'garda':
      return 'Garda';
    case 'e_s_b':
      return 'ESB';
    case 'prison_officers':
      return 'Prison Officers';
    case 'irish_life_hospital':
    case 'irish_life_hospital2022':
      return 'Irish Life Hospital';
    case 'laya_inpatient':
      return 'Laya Inpatient';
    case 'vhi_c_t':
      return 'VHI CT';
    case 'vhi_hospital':
      return 'VHI Hospital';
    case 'vhi_mri':
      return 'VHI MRI';
    case 'vhi_oncology':
      return 'VHI Oncology';
    case 'vhi_surgical':
      return 'VHI Surgical';
    default:
      return '';
  }
};
