<script setup lang="ts">
import { BaseDateInput, BaseInput, BaseInputWithLines, BaseAddressInput, BasePhoneNumberInput, BaseCheckbox } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  treatmentSettingGP: {
    type: Boolean,
    default: false
  },
  treatmentSettingOther: {
    type: Boolean,
    default: false
  },
  treatmentSettingConsultantRooms: {
    type: Boolean,
    default: false
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="vhi-policy-details-surgical-title" title="Policy Details" />
    <BaseInputWithLines
      id="policy-no"
      name="policyNo"
      label="Quote Policy No."
      :max-length="8"
      helper-text="from you Vhi membership card"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('policy-no')"
    />

    <BaseInput
      id="patient-name"
      name="patientForename"
      class="mt-6 sm:mt-12"
      label="Patient's First name*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-name')"
    />
    <BaseInput
      id="patient-surname"
      name="patientSurname"
      class="mt-6 sm:mt-12"
      label="Patient's Surname*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-surname')"
    />
    <BaseAddressInput
      id="patient-address"
      name="patientAddress"
      class="mt-6 sm:mt-12"
      label="Patient's Address*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-address')"
    />
    <BaseDatePicker
      id="date-of-treatment"
      name="dateOfTreatment"
      class="mt-6 sm:mt-12"
      label="Date of Treatment"
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseInput
      id="policy-holder-name"
      name="policyHolderName"
      class="mt-6 sm:mt-12"
      label="Policy Holder's Name"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('policy-holder-name')"
    />
    <BaseDateInput name="birthDate" class="mt-6 sm:mt-12" label="Patient's Date of Birth*" :edit-mode="editMode" />
    <BasePhoneNumberInput
      id="telephone"
      name="telephone"
      class="mt-6 sm:mt-12"
      label="Contact Telephone No."
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('telephone')"
    />
    <BaseInput
      id="email"
      name="email"
      class="mt-6 sm:mt-12"
      label="Email Address"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('email')"
    />
    <BaseRadioButton
      name="treatmentSetting"
      class="mt-6 sm:mt-12"
      :options="{
        'GP Surgery': 'gp-surgery',
        'Consultant\'s room': 'consultants-room',
        Other: 'other'
      }"
      label="Treatment Setting"
      :edit-mode="editMode"
    />
    <!-- <BaseRadioButton
      name="treatmentSetting"
      class="mt-6 sm:mt-12"
      :options="{
        'GP Surgery': 'gp-surgery',
        'Consultant\'s room': 'consultants-room',
        Other: 'other'
      }"
      label="Treatment Setting"
      :edit-mode="editMode"
    /> -->

    <div class="ml-2 mt-4">
      <label class="text-sm sm:text-base font-bold">Treatment Setting</label>
      <div class="flex flex-wrap gap-4 sm:mt-4">
        <BaseCheckbox
          id="treatment-claim-2"
          name="treatmentSettingConsultantRooms"
          label="Consultant's room"
          :edit-mode="editMode"
          :defaultValue="treatmentSettingConsultantRooms"
        ></BaseCheckbox>
        <BaseCheckbox
          id="treatment-claim-3"
          name="treatmentSettingOther"
          label="Other"
          :edit-mode="editMode"
          :defaultValue="treatmentSettingOther"
        ></BaseCheckbox>
        <BaseCheckbox
          id="treatment-claim-1"
          name="treatmentSettingGP"
          label="GP Surgery"
          :edit-mode="editMode"
          :defaultValue="treatmentSettingGP"
        ></BaseCheckbox>
      </div>
    </div>
  </div>
</template>
