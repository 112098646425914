import axios from 'axios';
import { AssocType } from 'enums';

export class OpenAPIDateRange {
  start; // string
  end; // string

  /**
   *
   * @param {string | null} start_date
   * @param {string | null} end_date
   */
  constructor(start_date, end_date) {
    this.start = start_date;
    this.end = end_date;
  }

  /**
   * Construct a OpenAPIDateRange object that is inclusive for the days within a single month or year.
   *
   * @param {number} year
   * @param {number | null} month  If set to NULL then the date range is created for the year.  Otherwise
   *   the month number should be 1 = Jan, 12 = Dec
   * @return {OpenAPIDateRange}
   */
  static from(year, month) {
    if (month === null) {
      const start = new Date(Date.UTC(year, 0, 1)).toISOString().slice(0, 10);
      const end = new Date(Date.UTC(year, 12, 0)).toISOString().slice(0, 10);
      return new OpenAPIDateRange(start, end);
    } else {
      const start = new Date(Date.UTC(year, month, 1)).toISOString().slice(0, 10);
      // Zero day on the next month returns last day of previous month
      const end = new Date(Date.UTC(year, month + 1, 0)).toISOString().slice(0, 10);
      return new OpenAPIDateRange(start, end);
    }
  }

  /**
   *
   * @return {{}, {string: {start: string | null, end: string | null}}}
   */
  to_dict() {
    if (this.start !== null || this.end !== null) {
      return { start: this.start, end: this.end };
    } else {
      return {};
    }
  }
}

/**
 *
 * @param {OpenAPIDateRange | null} date_range
 * @return {{}|{}|{string: {start: (string|null), end: (string|null)}}}
 */
function req_payment_date(date_range) {
  return date_range === null ? {} : { payment_date: date_range.to_dict() };
}

/**
 *
 * @param {OpenAPIDateRange | null} date_range
 * @return {{}|{}|{string: {start: (string|null), end: (string|null)}}}
 */
function req_submission_date(date_range) {
  return date_range === null ? {} : { submission_date: date_range.to_dict() };
}

function req_claim_state(claim_state) {
  if (claim_state === null) {
    return {};
  } else {
    return {
      claim_state: Array.isArray(claim_state) ? claim_state : [claim_state]
    };
  }
}

function req_claim_id(claim_id) {
  if (claim_id === null) {
    return {};
  } else {
    return {
      claim: {
        claim_type: 'id',
        id: claim_id
      }
    };
  }
}

function req_claim(claim_id) {
  if (claim_id === null) {
    return {};
  } else {
    return {
      claim: {
        claim_type: 'id',
        claim_id: claim_id
      }
    };
  }
}

function req_insurer_id(insurer_id) {
  if (insurer_id === null) {
    return {};
  } else {
    return {
      insurer: {
        type: 'id',
        id: insurer_id
      }
    };
  }
}

function req_insurer(insurer_id, insurer_name) {
  if (insurer_id === null && insurer_name === null) return {};
  else if (insurer_id) {
    return {
      insurer: {
        type: 'id',
        id: insurer_id
      }
    };
  } else if (insurer_name) {
    return {
      insurer: {
        type: 'name',
        name: insurer_name
      }
    };
  }
}

function req_insurer_contract_id(insurer_contract_id) {
  if (insurer_contract_id === null) {
    return {};
  } else {
    return {
      insurer_contract: {
        type: 'id',
        id: insurer_contract_id
      }
    };
  }
}

function req_consultant_id(consultant_id) {
  if (consultant_id === null) {
    return {};
  } else {
    return {
      consultant: {
        type: 'id',
        id: consultant_id
      }
    };
  }
}

/**
 *
 * @param {string} consultant_name
 * @return {{consultant: {id, type: string}}|{}}
 */
function req_consultant_name(consultant_name) {
  if (consultant_name === null) {
    return {};
  } else {
    return {
      consultant: {
        type: 'name',
        id: consultant_name
      }
    };
  }
}

/**
 *
 * @param {number} consultant_person_id
 * @return {{consultant: {id, type: string}}|{}}
 */
function req_consultant_person_id(consultant_person_id) {
  if (consultant_person_id === null) {
    return {};
  } else {
    return {
      consultant: {
        type: 'personid',
        id: consultant_person_id
      }
    };
  }
}

/**
 *
 * @param {number | null} consultant_id
 * @param {string| null} consultant_name
 * @param {number | null} consultant_person_id
 * @return {{}|{}|{consultant: {id: *, type: string}}}
 */
function req_consultant(consultant_id = null, consultant_name = null, consultant_person_id = null) {
  if (consultant_id === null && consultant_name === null && consultant_person_id === null) return {};
  else if (consultant_id) return req_consultant_id(consultant_id);
  else if (consultant_name) return req_consultant_name(consultant_name);
  else if (consultant_person_id) return req_consultant_person_id(consultant_person_id);
}

/**
 *
 * @param {number} consultant_claim_id
 * @return {{consultant_claim: {id, type: string}}|{}}
 */
function req_consultant_claim_id(consultant_claim_id) {
  if (consultant_claim_id === null) {
    return {};
  } else {
    return {
      consultant_claim: {
        type: 'id',
        id: consultant_claim_id
      }
    };
  }
}

function req_appointment_id(appointment_id) {
  if (appointment_id === null) {
    return {};
  } else {
    return {
      appointment: {
        type: 'id',
        appointment_id: appointment_id
      }
    };
  }
}

function req_claim_ref(claim_id, consultant_id = null) {
  if (consultant_id === null && claim_id === null) return {};
  else if (consultant_id === null) {
    return {
      claim_ref: {
        type: 'id',
        id: claim_id
      }
    };
  } else {
    return {
      claim_ref: {
        type: 'pair',
        claim: {
          claim_type: 'id',
          claim_id: claim_id
        },
        consultant: {
          type: 'id',
          id: consultant_id
        }
      }
    };
  }
}

function req_date_range(start_date, end_date) {
  return {
    start: start_date,
    end: end_date
  };
}

/**
 *
 * @param {number | null} consultant_id
 * @param {string| null} consultant_name
 * @param {number | null} consultant_person_id
 * @param {number | null} insurer_id
 * @param {string| null} insurer_name
 * @param {string| null} rollup
 * @param {string| null} claim_status
 * @param file
 * @return {{file, claim_status, rollup}}
 */

function req_reporting(
  consultant_id,
  consultant_name,
  consultant_person_id,
  insurer_id,
  insurer_name,
  rollup,
  claim_status,
  file
) {
  return {
    ...req_consultant(consultant_id, consultant_name, consultant_person_id),
    ...req_insurer(insurer_id, insurer_name),
    rollup: rollup,
    claim_status: claim_status,
    file: file
  };
}

function req_consultant_file(consultant_id, consultant_file_name) {
  if (consultant_id === null || consultant_file_name === null) return {};
  else {
    return {
      consultant_file: {
        file_name: consultant_file_name,
        assoc: {
          assoc_type: 'consultant',
          type: 'id',
          id: consultant_id
        }
      }
    };
  }
}

function req_sort_by(sort_by, desc) {
  if (sort_by) {
    return {
      sort_by: desc ? `desc:${sort_by}` : `asc:${sort_by}`
    };
  } else {
    return { sort_by: null };
  }
}

function req_message(message_id) {
  if (message_id)
    return {
      message: {
        type: 'id',
        id: message_id
      }
    };
  else {
    return {};
  }
}

function req_assocs(assoc_id) {
  if (assoc_id) {
    return {
      assocs: [
        {
          assoc_type: 'consultantclaim',
          type: 'id',
          id: assoc_id
        }
      ]
    };
  } else {
    return {};
  }
}

export class OpenAPI {
  // The content type encoding that we want to receive from the API
  content_type() {
    return 'application/json';
  }
  content_type_plain() {
    return 'text/plain';
  }
  // The data structure type that we expect in the body of the API response.
  response_type() {
    return 'json';
  }

  _build_url(endpoint) {
    return import.meta.env.VITE_OPENAPI_URL + endpoint;
  }

  //Call an OpenAPI endpoint, throws an exception with the error if there is one.
  async _call(endpoint, requestData) {
    let response = await axios.post(this._build_url(endpoint), requestData, {
      responseType: this.response_type(),
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('_token'),
        accept: this.content_type(),
        'Content-Type': 'application/json'
      }
    });
    if (response.data.status === 'error') {
      throw response;
    }

    return response.data;
  }

  //Call an OpenAPI endpoint, throws an exception with the error if there is one.
  async _plain(endpoint, requestData) {
    let response = await axios.post(this._build_url(endpoint), requestData, {
      responseType: this.response_type(),
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('_token'),
        accept: this.content_type_plain(),
        'Content-Type': 'text/plain'
      }
    });
    if (response.data.status === 'error') {
      throw response.data.status.reason;
    }
    return response.data;
  }

  async _get(endpoint) {
    let response = await axios.get(this._build_url(endpoint), {
      responseType: this.response_type(),
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('_token'),
        accept: this.content_type(),
        'Content-Type': 'application/json'
      }
    });
    if (response.data.status === 'error') {
      throw response;
    }
    return response.data;
  }

  // POST
  async savePreferences(requestData) {
    return await this._call('/v1/user/preferences/save', requestData);
  }

  async searchProcedureCodes(
    claim_ref_id,
    consultant_id,
    appointment_id,
    insurer_id,
    insurer_name,
    insurer_contract_id,
    on_date,
    proc_code,
    proc_desc,
    general,
    procedure_codes,
    category,
    not_in_category,
    pagination
  ) {
    const requestData = {
      ...req_claim_ref(claim_ref_id, consultant_id),
      ...req_appointment_id(appointment_id),
      ...req_insurer(insurer_id, insurer_name),
      ...req_insurer_contract_id(insurer_contract_id),
      on_date: on_date,
      proc_code: proc_code,
      proc_desc: proc_desc,
      general: general,
      procedure_codes: procedure_codes,
      category: category,
      not_in_category: not_in_category,
      pagination: pagination
    };
    return await this._call('/v1/search/procedurecode', requestData);
  }

  async searchInsurances(insurer_name, pagination) {
    let requestData = {};
    if (insurer_name) requestData.insurer_name = insurer_name;
    if (pagination) requestData.pagination = pagination;
    return await this._call('/v1/search/insurance', requestData);
  }

  async searchPatients(mrn, full_name, first_name, last_name, pagination) {
    const requestData = {
      mrn: mrn,
      full_name: full_name,
      first_name: first_name,
      last_name: last_name,
      pagination: pagination
    };
    return await this._call('/v1/search/patient', requestData);
  }

  async searchContracts(insurer_id, insurer_name, contract_name, pagination) {
    const requestData = {
      ...req_insurer(insurer_id, insurer_name),
      contract_name: contract_name,
      pagination: pagination
    };
    return await this._call('/v1/search/insurance_contracts', requestData);
  }

  async searchInvoice(
    claim_state,
    claim_phase,
    claim_id,
    insurer_id,
    insurer_name,
    consultant_id,
    consultant_name,
    consultant_person_id,
    pagination
  ) {
    const requestData = {
      ...req_claim_state(claim_state),
      ...req_claim_id(claim_id),
      ...req_insurer(insurer_id, insurer_name),
      ...req_consultant(consultant_id, consultant_name, consultant_person_id),
      claim_phase: claim_phase,
      pagination: pagination
    };
    return await this._call('/v1/search/invoices', requestData);
  }

  async setContractInsuranceProcedureCode(insurer_id, insurer_name, insurer_contract_id, price_list) {
    const requestData = {
      ...req_insurer(insurer_id, insurer_name),
      ...req_insurer_contract_id(insurer_contract_id),
      price_list: price_list
    };
    return await this._call('/v1/set/contract/insurance/procedurecode', requestData);
  }

  // POST/PUT
  async reportConsulantInsurerPayments(requestData) {
    return await this._call('/v1/report/consultant/insurer/payments', requestData);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null} insurer_id
   * @param {string| null} insurer_name
   * @param {string| null} rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async reportConsulantClaimAged(
    consultant_id,
    consultant_name,
    consultant_person_id,
    insurer_id,
    insurer_name,
    rollup,
    submission_date,
    claim_status,
    file
  ) {
    const requestData = {
      ...req_reporting(
        consultant_id,
        consultant_name,
        consultant_person_id,
        insurer_id,
        insurer_name,
        rollup,
        claim_status,
        file
      ),
      ...req_submission_date(submission_date)
    };
    return await this._call('/v1/report/consultant/claim/aged', requestData);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null} insurer_id
   * @param {string| null} insurer_name
   * @param {string| null} rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async reportConsulantClaimSubmitted(
    consultant_id,
    consultant_name,
    consultant_person_id,
    insurer_id,
    insurer_name,
    rollup,
    submission_date,
    claim_status,
    file
  ) {
    const requestData = {
      ...req_reporting(
        consultant_id,
        consultant_name,
        consultant_person_id,
        insurer_id,
        insurer_name,
        rollup,
        claim_status,
        file
      ),
      ...req_submission_date(submission_date)
    };
    return await this._call('/v1/report/consultant/claim/submitted', requestData);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null}insurer_id
   * @param {string| null}insurer_name
   * @param {string| null}rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {OpenAPIDateRange | null} payment_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async paidInvoices(
    consultant_id,
    consultant_name,
    consultant_person_id,
    insurer_id,
    insurer_name,
    rollup,
    submission_date,
    payment_date,
    claim_status,
    file
  ) {
    let requestData = {
      ...req_reporting(
        consultant_id,
        consultant_name,
        consultant_person_id,
        insurer_id,
        insurer_name,
        rollup,
        claim_status,
        file
      ),
      ...req_submission_date(submission_date),
      ...req_payment_date(payment_date)
    };
    return await this._call('/v1/report/consultant/invoices/paid', requestData);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null} insurer_id
   * @param {string| null} insurer_name
   * @param {string| null} rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async reportConsulantClaimOutstandingByMonth(
    consultant_id,
    consultant_name,
    consultant_person_id,
    insurer_id,
    insurer_name,
    rollup,
    submission_date,
    claim_status,
    file
  ) {
    const requestData = {
      ...req_reporting(
        consultant_id,
        consultant_name,
        consultant_person_id,
        insurer_id,
        insurer_name,
        rollup,
        claim_status,
        file
      ),
      ...req_submission_date(submission_date)
    };
    return await this._call('/v1/report/consultant/claim_outstanding/by/month', requestData);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null} insurer_id
   * @param {string| null} insurer_name
   * @param {string| null} rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async reportConsulantClaimOutstandingByInsurer(
    consultant_id,
    consultant_name,
    consultant_person_id,
    insurer_id,
    insurer_name,
    rollup,
    submission_date,
    claim_status,
    file
  ) {
    const requestData = {
      ...req_reporting(
        consultant_id,
        consultant_name,
        consultant_person_id,
        insurer_id,
        insurer_name,
        rollup,
        claim_status,
        file
      ),
      ...req_submission_date(submission_date)
    };
    return await this._call('/v1/report/consultant/claim_outstanding/by/insurer', requestData);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null} insurer_id
   * @param {string| null} insurer_name
   * @param {string| null} rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async reportConsulantClaimOutstandingByProcedure(
    consultant_id,
    consultant_name,
    consultant_person_id,
    insurer_id,
    insurer_name,
    rollup,
    submission_date,
    claim_status,
    file
  ) {
    const requestData = {
      ...req_reporting(
        consultant_id,
        consultant_name,
        consultant_person_id,
        insurer_id,
        insurer_name,
        rollup,
        claim_status,
        file
      ),
      ...req_submission_date(submission_date)
    };
    return await this._call('/v1/report/consultant/claim_outstanding/by/procedure', requestData);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null} insurer_id
   * @param {string| null} insurer_name
   * @param {string| null} rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async reportConsulantClaimOutstandingPended(
    consultant_id,
    consultant_name,
    consultant_person_id,
    insurer_id,
    insurer_name,
    rollup,
    submission_date,
    claim_status,
    file
  ) {
    const requestData = {
      ...req_reporting(
        consultant_id,
        consultant_name,
        consultant_person_id,
        insurer_id,
        insurer_name,
        rollup,
        claim_status,
        file
      ),
      ...req_submission_date(submission_date)
    };
    return await this._call('/v1/report/consultant/claim_outstanding/pended', requestData);
  }

  async reportConsulantClaimOutstandingByRejected(requestData) {
    return await this._call('/v1/report/consultant/claim_outstanding/by/rejected', requestData);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null} insurer_id
   * @param {string| null} insurer_name
   * @param {string| null} rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async reportConsulantClaimOutstandingRejected(
    consultant_id,
    consultant_name,
    consultant_person_id,
    insurer_id,
    insurer_name,
    rollup,
    submission_date,
    claim_status,
    file
  ) {
    const requestData = {
      ...req_reporting(
        consultant_id,
        consultant_name,
        consultant_person_id,
        insurer_id,
        insurer_name,
        rollup,
        claim_status,
        file
      ),
      ...req_submission_date(submission_date)
    };
    return await this._call('/v1/report/consultant/claim_outstanding/rejected', requestData);
  }

  async reportConsulantClaimOutstandingAwaitingSignoff(
    consultant_id,
    consultant_name,
    consultant_person_id,
    insurer_id,
    insurer_name,
    admitted_start,
    admitted_end,
    discharge_start,
    discharge_end
  ) {
    const requestData = {
      ...req_consultant(consultant_id, consultant_name, consultant_person_id),
      ...req_insurer(insurer_id, insurer_name),
      admitted: {
        start: admitted_start,
        end: admitted_end
      },
      discharge: {
        start: discharge_start,
        end: discharge_end
      }
    };
    return await this._call('/v1/report/consultant/claim_outstanding/awaiting_signoff', requestData);
  }


  // claims
  async searchCompositeClaims(
      claim_id,
      patient_id,
      claim_composite_id,
      hospital_appointment_id,
      invoice_no,
      general,
      mrn,
      full_name,
      first_name,
      last_name,
      consultant_id,
      claim_state,
      tags,
      sort_by,
      pagination
  ) {
    const consultant = consultant_id
        ? {
          type: 'id',
          id: consultant_id
        }
        : null;
    const requestData = {
      claim_id: claim_id,
      patient_id: patient_id,
      claim_composite_id: claim_composite_id,
      hospital_appointment_id: hospital_appointment_id,
      invoice_no: invoice_no,
      general: general,
      mrn: mrn,
      full_name: full_name,
      first_name: first_name,
      last_name: last_name,
      consultant: consultant,
      claim_state: claim_state,
      tags: tags,
      sort_by: sort_by,
      pagination: pagination
    };
    return await this._call('/v1/search/claims/composite', requestData);
  }


  // claims
  async searchClaims(
    claim_id,
    patient_id,
    claim_consultant_id,
    hospital_appointment_id,
    invoice_no,
    general,
    mrn,
    full_name,
    first_name,
    last_name,
    consultant_id,
    claim_state,
    tags,
    sort_by,
    pagination
  ) {
    const consultant = consultant_id
      ? {
          type: 'id',
          id: consultant_id
        }
      : null;
    const requestData = {
      claim_id: claim_id,
      patient_id: patient_id,
      claim_consultant_id: claim_consultant_id,
      hospital_appointment_id: hospital_appointment_id,
      invoice_no: invoice_no,
      general: general,
      mrn: mrn,
      full_name: full_name,
      first_name: first_name,
      last_name: last_name,
      consultant: consultant,
      claim_state: claim_state,
      tags: tags,
      sort_by: sort_by,
      pagination: pagination
    };
    return await this._call('/v1/search/claims/consultant', requestData);
  }

  async searchClaimsMobile(requestData) {
    return await this._call('/v1/mobile/search/claims/consultant', requestData);
  }

  async getCompositeClaimCount(start_date, end_date, consultants) {
    const requestData = {
      start_date: start_date,
      end_date: end_date,
      consultants: consultants
    };
    return await this._call('/v1/claim/composite/count/states', requestData);
  }

  async getClaimCount(start_date, end_date, consultants) {
    const requestData = {
      start_date: start_date,
      end_date: end_date,
      consultants: consultants
    };
    return await this._call('/v1/claim/consultant/count/states', requestData);
  }

  async getCompositeClaimTagsCount(start_date, end_date, claim_state) {
    const requestData = {
      start_date: start_date,
      end_date: end_date,
      claim_state: claim_state
    };
    return await this._call('/v1/claim/composite/count/tags', requestData);
  }

  async getClaimTagsCount(start_date, end_date, claim_state) {
    const requestData = {
      start_date: start_date,
      end_date: end_date,
      claim_state: claim_state
    };
    return await this._call('/v1/claim/consultant/count/tags', requestData);
  }

  //claim form types
  async getInsurerClaimForms(insurer_id, insurer_name, claim_id, claim_consultant_id, appointment_id) {
    const requestData = {
      ...req_insurer(insurer_id, insurer_name),
      ...req_claim(claim_id),
      ...req_consultant_claim_id(claim_consultant_id),
      ...req_appointment_id(appointment_id)
    };
    return await this._call('/v1/insurance/claimforms', requestData);
  }

  async setClaimFeatures(requestData) {
    return await this._call('/v1/claim/setfeatures', requestData);
  }

  //policy plans
  async getInsurancePolicyPlans(insurer_id, insurer_name, on_date, policy_name, pagination) {
    const requestData = {
      ...req_insurer(insurer_id, insurer_name),
      on_date: on_date,
      policy_name: policy_name,
      pagination: pagination
    };
    return await this._call('/v1/search/insurance_policyplans', requestData);
  }

  //GET
  async claimSetFeatures(claim_id, claimform, invoice) {
    let requestData = {
      claim_id: claim_id
    };
    if (claimform !== undefined) {
      requestData['feature_claim_form'] = claimform;
    }
    if (invoice !== undefined) {
      requestData['feature_invoice'] = invoice;
    }
    return await this._call('/v1/claim/setfeatures', requestData);
  }

  async tagCreate(requestData) {
    return await this._call('/v1/tag/create', requestData);
  }

  async tagDelete(requestData) {
    return await this._call('/v1/tag/delete', requestData);
  }

  async associateTag(requestData) {
    return await this._call('/v1/tag/associate', requestData);
  }

  // GET

  async getMe(requestData) {
    return await this._get('/v1/me', requestData);
  }

  async tagList() {
    return await this._get('/v1/tag/list');
  }

  async me() {
    return await this._get('/v1/me');
  }

  async getConfig() {
    return await this._get('/v1/config');
  }

  async listStateIndicators() {
    return await this._get('/v1/remittance/indicators');
  }

  // PLAIN|TEXT
  async saveUserPreferences(requestData) {
    return await this._plain('/v1/user/preferences/save', requestData);
  }

  async listPersonGenders() {
    return await this._get('/v1/person/gender');
  }

  async listPersonTitles() {
    return await this._get('/v1/person/titles');
  }

  async listDiagnosisTypes() {
    return await this._get('/v1/diagnosis/types');
  }

  async listDiagnosisSeverity() {
    return await this._get('/v1/diagnosis/severity');
  }

  async appointmentInsuranceInfo(claim_id = null, appointment_id = null) {
    const requestData = {
      ...req_claim_id(claim_id),
      ...req_appointment_id(appointment_id)
    };
    return await this._call('/v1/appointment/insurance_detail', requestData);
  }

  // General search over GPs and Consultants.
  async searchDoctors(search_term = null, first_name = null, last_name = null, my = null, incl_gp = true) {
    const req = {
      general: search_term,
      first_name: first_name,
      last_name: last_name,
      my: my,
      incl_gp: incl_gp
    };
    return await this._call('/v1/search/doctors', req);
  }

  // General search for GPs
  async searchGeneralPractitioners(search_term = null) {
    const req = {
      general: search_term
    };
    return await this._call('/v1/search/gp/doctors', req);
  }

  // General search for GP practices
  async searchGeneralPractices(search_term = null) {
    const req = {
      general: search_term
    };
    return await this._call('/v1/search/gp/practices', req);
  }

  async getBedTypes() {
    return await this._get('/v1/assets/bed-types');
  }

  async login(email, password) {
    const req = {
      email: email,
      password: password
    };
    return await this._call('/v1/login', req);
  }

  async searchProcedureCodesByAppointmentId(req) {
    return await this._call('/v1/search/procedurecode', req);
  }

  async searchProcedureCodesByCategory(req) {
    return await this._call('/v1/search/procedurecode', req);
  }

  async message_create(req) {
    return await this._call('/v1/message/create', req);
  }

  async message_get(message_id, include_attachment_data) {
    const req = {
      ...req_message(message_id),
      include_attachment_data: include_attachment_data
    };
    return await this._call('/v1/message/get', req);
  }

  async message_list({ unread, draft, notes, assoc, sort_by, desc, sent, pagination, recipients }) {
    const req = {
      unread: unread,
      draft: draft,
      notes: notes,
      assoc: assoc,
      ...req_sort_by(sort_by, desc),
      sent: sent,
      pagination: pagination,
      recipients: recipients
    };

    return await this._call('/v1/message/list', req);
  }

  async message_list_notes({ unread, draft, notes, assoc, sort_by, desc, sent, pagination, recipients }) {
    const req = {
      unread: unread,
      draft: draft,
      notes: notes,
      assoc: assoc,
      sort_by,
      sent: sent,
      pagination: pagination,
      recipients: recipients
    };

    return await this._call('/v1/message/list', req);
  }

  async message_mark_seen(message_id) {
    const req = {
      ...req_message(message_id)
    };
    return await this._call('/v1/message/mark/seen', req);
  }

  async message_create_consultant_photo(req) {
    return await this._call('/v1/message/create/consultant/photo', req);
  }

  async message_delete(req) {
    return await this._call('/v1/message/delete', req);
  }

  async message_add_assoc(message_id, assoc_id) {
    const req = {
      ...req_message(message_id),
      ...req_assocs(assoc_id)
    };

    return await this._call('/v1/message/assoc/add', req);
  }

  async message_remove_assoc(message_id, assocs) {
    const req = {
      ...req_message(message_id),
      assocs: assocs
    };

    return await this._call('/v1/message/assoc/remove', req);
  }

  async file_add(req) {
    return await this._call('/v1/file/add', req);
  }

  async file_set_metadata(req) {
    return await this._call('/v1/file/metadata/set', req);
  }

  async file_get(req) {
    return await this._call('/v1/file/get', req);
  }

  async file_list(req) {
    return await this._call('/v1/file/list', req);
  }

  /**
   *
   * @param req
   * @return {Promise<{zip_name: string, b64data: string}>}
   */
  async file_zip(req) {
    return await this._call('/v1/file/zip', req);
  }

  async file_delete(req) {
    return await this._call('/v1/file/delete', req);
  }

  async list_insurance(req) {
    return await this._call('/v1/search/insurance', req);
  }

  /**
   * Search claim statements
   *
   * @param {number} consultant_id
   * @param {number | null} insurer_id
   * @param {OpenAPIDateRange | null} payment_date
   */
  async claim_statements_search(consultant_id, insurer_id, payment_date) {
    const req = {
      ...req_consultant_id(consultant_id),
      ...req_insurer_id(insurer_id),
      ...req_payment_date(payment_date)
    };
    return await this._call('/v1/search/claim-statements', req);
  }

  async remittance_scan(req) {
    return await this._call('/v1/remittance/scan', req);
  }

  async invoices_remittances_search(req) {
    return await this._call('/v1/search/invoices-remittances', req);
  }

  async claim_statement_file_search(
    consultant_id,
    consultant_name,
    consultant_person_id,
    consultant_file_name,
    invoice_ref,
    app_id,
    hospital_appointment_id,
    insurer_id,
    insurer_name,
    state,
    pagination
  ) {
    const req = {
      ...req_consultant(consultant_id, consultant_name, consultant_person_id),
      ...req_consultant_file(consultant_id, consultant_file_name),
      invoice_ref: invoice_ref,
      app_id: app_id,
      hospital_appointment_id: hospital_appointment_id,
      ...req_insurer(insurer_id, insurer_name),
      state: state,
      pagination: pagination
    };
    return await this._call('/v1/search/claim-statement-file', req);
  }

  async claim_statement_update(req) {
    return await this._call('/v1/claim-statement/entry/update', req);
  }

  async claim_statement_add(req) {
    return await this._call('/v1/claim-statement/entry/add', req);
  }

  async appointment_search(req) {
    return await this._call('/v1/search/appointment', req);
  }

  async appointment_create(req) {
    return await this._call('/v1/appointment/create', req);
  }

  /**
   * Create a hospital claim node, linked to a pre-existing appointment claim
   *
   * @param {number} claim_id - The ID of the pre-existing appointment claim
   * @param {string} reason - The reason for creating the hospital claim
   * @returns
   */
  async claim_hospital_create(claim_id, reason) {
    if (!claim_id) {
      throw 'claim_hospital_create: claim_id is not defined';
    }

    const requestData = {
      claim: {
        claim_id: claim_id
      },
      reason: reason
    };

    return await this._call('/v1/claim/hospital/create', requestData);
  }

  /**
   * Create a hospital claim-form, associated with a pre-existing appointment claim
   *
   * @param {number} claim_id - The ID of the pre-existing appointment claim
   * @param {string} hospital_name - The name of the hospital
   * @param {string} claim_form_type - The type of claim form (e.g., "vhi_hospital")
   * @param {string} reason - The reason for creating the hospital claim-form
   * @returns
   */
  async claim_form_hospital_create(claim_id, hospital_name, claim_form_type, reason = null) {
    if (!claim_id) {
      throw 'claim_form_hospital_create: claim_id is not defined';
    }
    if (!claim_form_type) {
      throw 'claim_form_hospital_create: claim_form_type is not defined';
    }

    const requestData = {
      claim_ref: {
        type: 'id',
        claim: {
          claim_type: 'id',
          claim_id: claim_id
        }
      },
      claim_form_type: claim_form_type
    };

    if (reason) {
      requestData.reason = reason;
    }

    return await this._call('/v1/claimform/hospital/create', requestData);
  }

  /**
   * List all defined claim-forms that are part of the hospital-claim for an appointment
   *
   * @param {Object} req - The request object containing claim and hospital information
   * @returns
   */
  async claim_form_hospital_list(req) {
    return await this._call('/v1/claimform/hospital/list', req);
  }

  async claim_form_hospital_get(req) {
    return await this._call('/v1/claimform/hospital/get', req);
  }

  async claim_form_hospital_update(req) {
    return await this._call('/v1/claimform/hospital/update', req);
  }


  /**
   * Create a hospital claim-form, associated with a pre-existing appointment claim
   *
   * @param {number} claim_id - The ID of the pre-existing appointment claim
   * @param {string} hospital_name - The name of the hospital
   * @param {string} claim_form_type - The type of claim form (e.g., "vhi_hospital")
   * @param {string} reason - The reason for creating the hospital claim-form
   * @returns
   */
  async claim_form_composite_create(claim_id, hospital_name, claim_form_type, reason = null) {
    if (!claim_id) {
      throw 'claim_form_composite_create: claim_id is not defined';
    }
    if (!claim_form_type) {
      throw 'claim_form_composite_create: claim_form_type is not defined';
    }

    const requestData = {
      claim_ref: {
        type: 'id',
        claim: {
          claim_type: 'id',
          claim_id: claim_id
        }
      },
      claim_form_type: claim_form_type
    };

    if (reason) {
      requestData.reason = reason;
    }

    return await this._call('/v1/claimform/composite/create', requestData);
  }

  /**
   * List all defined claim-forms that are part of the hospital-claim for an appointment
   *
   * @param {Object} req - The request object containing claim and hospital information
   * @returns
   */
  async claim_form_composite_list(req) {
    return await this._call('/v1/claimform/composite/list', req);
  }

  async claim_form_composite_get(req) {
    return await this._call('/v1/claimform/composite/get', req);
  }

  async claim_form_composite_update(req) {
    return await this._call('/v1/claimform/composite/update', req);
  }

  //waiting sms api
  async registration_state_change(req) {
    return await this._call('/v1/appointment/registration/state-change', req);
  }

  async requirement_update(req) {
    return await this._call('/v1/claimform/requirement/update', req);
  }

  async claim_create(claim_id, consultant_id, insurer_id, insurer_name, reason) {
    const req = {
      ...req_claim_ref(claim_id, consultant_id),
      ...req_insurer(insurer_id, insurer_name),
      reason: reason
    };
    return await this._call('/v1/claim/consultant/create', req);
  }

  async claimform_get(req) {
    return await this._call('/v1/claimform/get', req);
  }

  async claimform_consultant_create(req) {
    return await this._call('/v1/claimform/consultant/create', req);
  }

  async claimform_consultant_list(req) {
    return await this._call('/v1/claimform/consultant/list', req);
  }

  async claimform_consultant_get(req) {
    return await this._call('/v1/claimform/consultant/get', req);
  }

  async claimform_consultant_update(req) {
    return await this._call('/v1/claimform/consultant/update', req);
  }

  async claimform_update(req) {
    return await this._call('/v1/claimform/update', req);
  }

  async claimform_consultant_delete(req) {
    return await this._call('/v1/claimform/consultant/delete', req);
  }

  async claimform_composite_delete(req) {
    return await this._call('/v1/claimform/composite/delete', req);
  }

  async claimform_patient_list(req) {
    return await this._call('/v1/claimform/patient/list', req);
  }

  async claimform_patient_get(req) {
    return await this._call('/v1/claimform/patient/get', req);
  }

  async claimform_patient_update(req) {
    return await this._call('/v1/claimform/patient/update', req);
  }

  async signature_patient_get(req) {
    return await this._call('/v1/claimform/patient/signature/get', req);
  }

  async signature_patient_set(req) {
    return await this._call('/v1/claimform/patient/signature/set', req);
  }

  async claimform_pdf(claim_obj) {
    const req = {
      appointment_id: claim_obj.appointment_id,
      claim_id: claim_obj.claim_id,
      claim_form_id: claim_obj.claim_form_id
    };
    return await this._call('/v1/claimform/pdf', req);
  }

  async invoice_pdf(claim_obj) {
    let req = {
      claim_id: claim_obj.claim_id,
      appointment_id: claim_obj.appointment_id,
      claim_assoc: claim_obj.claim_assoc,
    };
    return await this._call('/v1/invoice/pdf', req);
  }

  async invoice_get(claim_obj) {
    let req = {
      claim_id: claim_obj.claim_id,
      appointment_id: claim_obj.appointment_id,
      claim_assoc: claim_obj.claim_assoc,
    };
    return await this._call('/v1/invoice/get', req);
  }

  async invoice_update(req) {
    return await this._call('/v1/invoice/update', req);
  }

  async composite_state_change(req) {
    return await this._call('/v1/claim/composite/state-change', req);
  }

  async consultant_state_change(req) {
    return await this._call('/v1/claim/consultant/state-change', req);
  }

  async list_facilities(req) {
    return await this._call('/v1/search/facility', req);
  }
}

export class OpenAPIJSON extends OpenAPI {
  content_type() {
    return 'application/json';
  }

  async claimSetFeatures(claim_id, claimform, invoice) {
    return await super.claimSetFeatures(claim_id, claimform, invoice);
  }

  async reportConsulantInsurerPayments(requestData) {
    let data = await super.reportConsulantInsurerPayments(requestData);
    return data.items;
  }

  async reportConsulantClaimOutstanding(requestData) {
    let data = await super.reportConsulantClaimOutstanding(requestData);
    return data.items;
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null} insurer_id
   * @param {string| null} insurer_name
   * @param {string| null} rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async reportConsulantClaimAged(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimAged(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      submission_date,
      claim_status,
      file
    );
    return data.items;
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null} insurer_id
   * @param {string| null} insurer_name
   * @param {string| null} rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async reportConsulantClaimSubmitted(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimSubmitted(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      submission_date,
      claim_status,
      file
    );
    return data;
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null}insurer_id
   * @param {string| null}insurer_name
   * @param {string| null}rollup
   * @param {OpenAPISubmissionDate | null} submission_date
   * @param {OpenAPIPaymentDate | null} payment_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async paidInvoices(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date = null,
    payment_date = null,
    claim_status = null,
    file = null
  ) {
    return await super.paidInvoices(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      submission_date,
      payment_date,
      claim_status,
      file
    );
  }

  async listStateIndicators() {
    let data = await super.listStateIndicators();
    return data;
  }

  //user calls
  async saveUserPreferences(requestData) {
    let data = await super.saveUserPreferences(requestData);
    return data;
  }

  async login(email, password) {
    return await super.login(email, password);
  }

  async me() {
    let data = await super.me();
    data.can_sign_claims = data.acls.indexOf('Claim::Sign') > -1;
    data.is_consultant = data.medic === true && data.can_sign_claims === true;
    return data;
  }

  async getConfig() {
    return await super.getConfig();
  }

  //claims calls
  async searchCompositeClaims(
      claim_id = null,
      patient_id = null,
      claim_composite_id = null,
      hospital_appointment_id = null,
      invoice_no = null,
      general = null,
      mrn = null,
      full_name = null,
      first_name = null,
      last_name = null,
      consultant_id = null,
      claim_state = null,
      tags = null,
      sort_by = null,
      pagination = null
  ) {
    let data = await super.searchCompositeClaims(
        claim_id,
        patient_id,
        claim_composite_id,
        hospital_appointment_id,
        invoice_no,
        general,
        mrn,
        full_name,
        first_name,
        last_name,
        consultant_id,
        claim_state,
        tags,
        sort_by,
        pagination
    );
    return data;
  }

  //claims calls
  async searchClaims(
    claim_id = null,
    patient_id = null,
    claim_consultant_id = null,
    hospital_appointment_id = null,
    invoice_no = null,
    general = null,
    mrn = null,
    full_name = null,
    first_name = null,
    last_name = null,
    consultant_id = null,
    claim_state = null,
    tags = null,
    sort_by = null,
    pagination = null
  ) {
    let data = await super.searchClaims(
      claim_id,
      patient_id,
      claim_consultant_id,
      hospital_appointment_id,
      invoice_no,
      general,
      mrn,
      full_name,
      first_name,
      last_name,
      consultant_id,
      claim_state,
      tags,
      sort_by,
      pagination
    );
    return data;
  }

  async searchClaimsMobile(requestData) {
    return await super.searchClaimsMobile(requestData);
  }

  async searchInsurances(insurer_name = null, pagination = null) {
    let data = await super.searchInsurances(insurer_name, pagination);
    return data;
  }

  // search patients calls
  async searchPatients(mrn = null, full_name = null, first_name = null, last_name = null, pagination = null) {
    let data = await super.searchPatients(mrn, full_name, first_name, last_name, pagination);
    return data;
  }

  async searchContracts(insurer_id = null, insurer_name = null, contract_name = null, pagination = null) {
    let data = await super.searchContracts(insurer_id, insurer_name, contract_name, pagination);
    return data;
  }

  async searchInvoice(
    claim_state = null,
    claim_phase = null,
    claim_id = null,
    insurer_id = null,
    insurer_name = null,
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    pagination = null
  ) {
    return await super.searchInvoice(
      claim_state,
      claim_phase,
      claim_id,
      insurer_id,
      insurer_name,
      consultant_id,
      consultant_name,
      consultant_person_id,
      pagination
    );
  }

  async setContractInsuranceProcedureCode(
    insurer_id = null,
    insurer_name = null,
    insurer_contract_id = null,
    price_list = null
  ) {
    let data = await super.setContractInsuranceProcedureCode(insurer_id, insurer_name, insurer_contract_id, price_list);
    return data;
  }

  async getClaimCount(start_date = null, end_date = null, consultants = null) {
    let data = await super.getClaimCount(start_date, end_date, consultants);
    return data;
  }

  async getClaimTagsCount(start_date = null, end_date = null, claim_state = null) {
    let data = await super.getClaimTagsCount(start_date, end_date, claim_state);
    return data;
  }

  async getInsurerClaimForms(
    insurer_id = null,
    insurer_name = null,
    claim_id = null,
    claim_consultant_id = null,
    appointment_id = null
  ) {
    let data = await super.getInsurerClaimForms(
      insurer_id,
      insurer_name,
      claim_id,
      claim_consultant_id,
      appointment_id
    );
    return data;
  }

  async getInsurancePolicyPlans(
    insurer_id = null,
    insurer_name = null,
    on_date = null,
    policy_name = null,
    pagination = null
  ) {
    let data = await super.getInsurancePolicyPlans(insurer_id, insurer_name, on_date, policy_name, pagination);
    return data;
  }

  async tagList() {
    let data = await super.tagList();
    return data;
  }

  async tagCreate(requestData) {
    let data = await super.tagCreate(requestData);
    return data;
  }

  async tagDelete(requestData) {
    let data = await super.tagDelete(requestData);
    return data;
  }

  async associateTag(requestData) {
    let data = await super.associateTag(requestData);
    return data;
  }

  async listPersonGenders() {
    return await super.listPersonGenders();
  }

  async listPersonTitles() {
    return await super.listPersonTitles();
  }

  async listDiagnosisTypes() {
    return await super.listDiagnosisTypes();
  }

  async listDiagnosisSeverity() {
    return await super.listDiagnosisSeverity();
  }

  async appointmentInsuranceInfo(claim_id = null, appointment_id = null) {
    return await super.appointmentInsuranceInfo(claim_id, appointment_id);
  }

  // General search over GPs and Consultants.
  async searchDoctors(search_term = null, first_name = null, last_name = null, my = null, incl_gp = true) {
    return await super.searchDoctors(search_term, first_name, last_name, my, incl_gp);
  }

  // General search for GPs
  async searchGeneralPractitioners(search_term = null) {
    return await super.searchGeneralPractitioners(search_term);
  }

  // General search for GP practices
  async searchGeneralPractices(search_term = null) {
    return await super.searchGeneralPractices(search_term);
  }

  async getBedTypes() {
    return await super.getBedTypes();
  }

  /**
   * Get a list of procedure codes by appointment id
   *
   * @param {number} appointment_id
   * @param {number} insurer_id
   */
  async searchProcedureCodesByAppointmentId(appointment_id, insurer_id) {
    if (!appointment_id) {
      throw 'searchProcedureCodesByAppointmentId: appointment_id is not defined';
    } else if (!insurer_id) {
      throw 'searchProcedureCodesByAppointmentId: insurer_id is not defined';
    }

    const req = {
      appointment: {
        type: 'id',
        appointment_id
      },
      insurer: {
        type: 'id',
        id: insurer_id
      }
    };
    return await super.searchProcedureCodesByAppointmentId(req);
  }

  /**
   * Get a list of procedure codes by category
   *
   * @param {array} categories
   * @param {number} appointment_id
   * @param {number} insurer_id
   *
   */
  async searchProcedureCodesByCategory(categories, appointment_id, insurer_id) {
    if (!categories.length) {
      throw 'searchProcedureCodesByCategory: categories are not defined';
    } else if (!appointment_id) {
      throw 'searchProcedureCodesByCategory: appointment_id is not defined';
    }

    const req = {
      category: categories,
      appointment: {
        type: 'id',
        appointment_id
      },
      insurer: {
        type: 'id',
        id: insurer_id
      }
    };
    return await super.searchProcedureCodesByCategory(req);
  }

  /**
   * Delete a message
   *
   * @param {number} message_id
   */
  async message_delete(message_id) {
    const req = {
      message: { id: message_id, type: 'id' }
    };
    return await super.message_delete(req);
  }

  /**
   * Get a message
   *
   * @param {number} message_id
   */
  async message_get({ message_id = null, include_attachment_data = false }) {
    if (message_id == null || message_id == undefined) {
      throw 'message_get: message_id is not defined';
    }
    return await super.message_get(message_id, include_attachment_data);
  }

  /**
   * List messages
   */
  async message_list({
    unread = null,
    draft = null,
    notes = null,
    assoc = null,
    sort_by = null,
    desc = true,
    sent = false,
    pagination = null,
    recipients = null
  }) {
    return await super.message_list({ unread, draft, notes, assoc, sort_by, desc, sent, pagination, recipients });
  }

  /**
   * List messages specific to an appointment claim.
   *
   * @param {number} claim_id
   */
  async claim_message_list(claim_id) {
    const req = {
      assoc: [{ claim_id: claim_id }]
    };
    return await super.message_list(req);
  }

  /**
   * List messages specific to a consultant claim.
   *
   * @param {number} claim_consultant_id
   * @param {boolean} notes_only If set true, then only retrieve message notes.
   */
  async claim_consultant_message_list(claim_consultant_id, notes_only = null) {
    let req = {
      assoc: [
        {
          assoc_type: 'consultantclaim',
          type: 'id',
          id: claim_consultant_id
        }
      ],
      sort_by: 'desc:created'
    };
    if (notes_only) {
      req['notes'] = true;
    }
    return await super.message_list_notes(req);
  }

  /**
   * List messages specific to a consultant claim.
   *
   * @param {number} claim_composite_id
   * @param {boolean} notes_only If set true, then only retrieve message notes.
   */
  async claim_composite_message_list(claim_composite_id, notes_only = null) {
    let req = {
      assoc: [
        {
          assoc_type: 'compositeclaim',
          type: 'id',
          id: claim_composite_id
        }
      ],
      sort_by: 'desc:created'
    };
    if (notes_only) {
      req['notes'] = true;
    }
    return await super.message_list_notes(req);
  }

  async message_mark_seen(message_id) {
    if (message_id == null || message_id == undefined) {
      throw 'message_mark_seen: message_id is not defined';
    }
    return await super.message_mark_seen(message_id);
  }

  async message_add_assoc(message_id, assoc_id) {
    if (message_id == null || message_id == undefined) {
      throw 'message_add_assoc: message_id is not defined';
    }
    if (assoc_id == null || assoc_id == undefined) {
      throw 'message_add_assoc: assoc is not defined';
    }
    return await super.message_add_assoc(message_id, assoc_id);
  }

  async message_remove_assoc(message_id, assocs) {
    if (message_id == null || message_id == undefined) {
      throw 'message_remove_assoc: message_id is not defined';
    }
    if (!assocs.length) {
      throw 'message_remove_assoc: assocs is not defined';
    }
    return await super.message_remove_assoc(message_id, assocs);
  }

  /**
   * Adds a Post-It style note and associates it to a consultant claim.
   *
   * @param {number} claim_consultant_id
   * @param {string} content
   */
  async claim_consultant_message_add(claim_consultant_id, note_content) {
    if (claim_consultant_id === null || claim_consultant_id === undefined) {
      throw 'claim_consultant_message_add: claim_consultant_id is not defined';
    }
    const req = {
      content: {
        content: note_content
      },
      assoc: [
        {
          assoc_type: 'consultantclaim',
          type: 'id',
          id: claim_consultant_id
        }
      ]
    };
    return await super.message_create(req);
  }

  /**
   * Adds a Post-It style note and associates it to a composite claim.
   *
   * @param {number} claim_composite_id
   * @param {string} content
   */
  async claim_composite_message_add(claim_composite_id, note_content) {
    if (claim_composite_id === null || claim_composite_id === undefined) {
      throw 'claim_consultant_message_add: claim_composite_message_add: claim_composite_id is not defined';
    }
    const req = {
      content: {
        content: note_content
      },
      assoc: [
        {
          assoc_type: 'compositeclaim',
          type: 'id',
          id: claim_composite_id
        }
      ]
    };
    return await super.message_create(req);
  }

  /**
   * Create a special consultant message that consists of a photo.
   *
   * @param {string} file_name
   * @param {string} data
   * @param {string} file_category
   * @param {string} mime_type
   * @returns
   */
  async message_create_consultant_photo({
    file_name,
    data,
    file_category,
    mime_type = null,
    content = null,
    subject = null
  }) {
    if (!file_name) {
      throw 'message_create_consultant_photo: file_name is not defined';
    } else if (!data) {
      throw 'message_create_consultant_photo: data is not defined';
    } else if (!file_category) {
      throw 'message_create_consultant_photo: file_category is not defined';
    }

    const req = {
      content: {
        content,
        subject
      },
      attachments: [
        {
          file_name,
          data,
          file_category,
          mime_type
        }
      ]
    };
    return await super.message_create_consultant_photo(req);
  }

  /**
   * Get a file
   *
   * @param {string | array} file_name
   * @param {object} assoc
   */
  async file_get(file_name, assoc) {
    if (!file_name) {
      throw 'file_get: file_name is not defined';
    } else if (!assoc) {
      throw 'file_get: assoc is not defined';
    }

    let req = null;
    if (Array.isArray(file_name)) {
      req = {
        files: file_name.map(f => {
          return {
            file_name: f,
            assoc: assoc
          };
        })
      };
    } else {
      req = {
        files: [
          {
            file_name: file_name,
            assoc: assoc
          }
        ]
      };
    }

    return await super.file_get(req);
  }

  /**
   * Add a file by assoc.
   *
   * @param {object} assoc
   * @param {array} file_content
   */
  async file_add_by_id(assoc, file_content) {
    if (!assoc) {
      throw 'file_add_by_id: assoc is not defined';
    } else if (!file_content.length) {
      throw 'file_add_by_id: file_content is empty';
    }

    const files = file_content.map(file => {
      return {
        file: file,
        assoc: assoc
      };
    });

    const req = {
      files: files
    };
    return await super.file_add(req);
  }

  /**
   * Set file metadata
   *
   * @param {object} file
   * @param {object} metadata
   */
  async file_set_metadata(file, metadata) {
    if (!file) {
      throw 'file_set_metadata: file is not defined';
    } else if (!metadata) {
      throw 'file_set_metadata: metadata is not defined';
    }

    const req = {
      file: file,
      metadata: metadata
    };
    return await super.file_set_metadata(req);
  }

  /**
   * List files by assoc type.
   *
   * @param {number} id
   * @param {string} assoc_type
   */
  async file_list_by_id(id, assoc_type) {
    if (!id) {
      throw 'file_list_by_id: id is not defined';
    } else if (!assoc_type) {
      throw 'file_list_by_id: assoc_type is not defined';
    }

    let req = {};
    switch (assoc_type) {
      case AssocType.ClaimNested:
        req = {
          assoc: {
            assoc_type: 'claimnested',
            claim_type: 'id',
            claim_id: id
          }
        };
        break;
      case AssocType.ConsultantClaim:
        req = {
          assoc: {
            assoc_type: 'consultantclaim',
            type: 'id',
            id: id
          }
        };
        break;
      case AssocType.Appointment:
        req = {
          assoc: {
            assoc_type: 'appointment',
            type: 'id',
            appointment_id: id
          }
        };
        break;
    }
    return await super.file_list(req);
  }

  /**
   * Construct a ZIP file consisting of the claim data for a specific consultant's claim
   *
   * @param {number} claim_consultant_id
   * @return {Promise<{zip_name: string, b64data: string}>}
   */
  async file_zip_composite_claim(claim_composite_id) {
    const req = {
      assoc: {
        assoc_type: 'compositeclaim',
        type: 'id',
        id: claim_composite_id
      }
    };
    return await super.file_zip(req);
  }

  /**
   * Construct a ZIP file consisting of the claim data for a specific consultant's claim
   *
   * @param {number} claim_consultant_id
   * @return {Promise<{zip_name: string, b64data: string}>}
   */
  async file_zip_consultant_claim(claim_consultant_id) {
    const req = {
      assoc: {
        assoc_type: 'consultantclaim',
        type: 'id',
        id: claim_consultant_id
      }
    };
    return await super.file_zip(req);
  }

  /**
   * Construct a ZIP file consisting of all claim data for all consultants and the hospital for a specific
   * appointment
   *
   * @param {number} claim_id
   * @return {Promise<{zip_name: string, b64data: string}>}
   */
  async file_zip_full_claim(claim_id) {
    const req = {
      assoc: {
        assoc_type: 'claimnested',
        claim_type: 'id',
        claim_id: claim_id
      }
    };
    return await super.file_zip(req);
  }

  /**
   * Delete a file
   *
   * @param {string} file_name
   * @param {object} assoc
   */
  async file_delete(file_name, assoc) {
    if (!file_name) {
      throw 'file_delete: file_name is not defined';
    } else if (!assoc) {
      throw 'file_delete: assoc is not defined';
    }

    const req = {
      files: [
        {
          file_name: file_name,
          assoc: assoc
        }
      ]
    };
    return await super.file_delete(req);
  }

  /**
   * List insurance contracts
   *
   */
  async list_insurance() {
    return await super.list_insurance({});
  }

  /**
   * Search claim statements
   *
   * @param {number} consultant
   * @param {number | null} insurer_id
   * @param {OpenAPIDateRange | null} payment_date
   */
  async claim_statements_search(consultant_id, insurer_id = null, payment_date = null) {
    return await super.claim_statements_search(consultant_id, insurer_id, payment_date);
  }

  /**
   * Scan remittance file
   *
   * @param {string} fileName
   * @param {object} assoc
   */
  async remittance_scan(fileName, assoc) {
    if (!fileName) {
      throw 'remittance_scan: fileName is not defined';
    } else if (!assoc) {
      throw 'remittance_scan: assoc is not defined';
    }

    const req = {
      file_name: fileName,
      assoc: assoc
    };
    return await super.remittance_scan(req);
  }

  /**
   * Search invoices and remittances
   *
   * @param {object} data
   */
  async invoices_remittances_search(data) {
    return await super.invoices_remittances_search(data);
  }

  /**
   * Search claim statement file
   *
   * @param {object} data
   */
  async claim_statement_file_search(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    consultant_file_name = null,
    invoice_ref = null,
    app_id = null,
    hospital_appointment_id = null,
    insurer_id = null,
    insurer_name = null,
    state = null,
    pagination = null
  ) {
    return await super.claim_statement_file_search(
      consultant_id,
      consultant_name,
      consultant_person_id,
      consultant_file_name,
      invoice_ref,
      app_id,
      hospital_appointment_id,
      insurer_id,
      insurer_name,
      state,
      pagination
    );
  }

  /**
   * Update claim statement entry
   *
   * @param {object} data
   */
  async claim_statement_update(data) {
    return await super.claim_statement_update(data);
  }

  /**
   * Add claim statement entry
   *
   * @param {object} data
   */
  async claim_statement_add(data) {
    return await super.claim_statement_add(data);
  }

  /**
   * Search appointment
   *
   * @param {object} data
   *
   */
  async appointment_search(data) {
    return await super.appointment_search(data);
  }

  async appointment_create(data) {
    return await super.appointment_create(data);
  }
  /**
   * Create Claim
   *
   * @param {object} data
   *
   */

  /**
   * Create a hospital claim node, linked to a pre-existing appointment claim
   *
   * @param {number} claim_id - The ID of the pre-existing appointment claim
   * @param {string} reason - The reason for creating the hospital claim
   * @returns
   */
  async claim_hospital_create(claim_id, reason = null) {
    return await super.claim_hospital_create(claim_id, reason);
  }

  /**
   * Create a hospital claim-form, associated with a pre-existing appointment claim
   *
   * @param {number} claim_id - The ID of the pre-existing appointment claim
   * @param {string} claim_form_type - The type of claim form (e.g., "vhi_hospital")
   * @param {string} reason - The reason for creating the hospital claim-form
   * @returns
   */
  async claim_form_hospital_create(claim_id, claim_form_type, reason = null) {
    return await super.claim_form_hospital_create(claim_id, claim_form_type, reason);
  }

  /**
   * List all defined claim-forms that are part of the hospital-claim for an appointment
   *
   * @param {Object} req - The request object containing claim and hospital information
   * @returns
   */
  async claim_form_hospital_list(req) {
    return await super.claim_form_hospital_list(req);
  }

  async claim_form_hospital_get(req) {
    return await super.claim_form_hospital_get(req);
  }

  async claim_form_hospital_update(req) {
    return await super.claim_form_hospital_update(req);
  }


  /**
   * Create a hospital claim-form, associated with a pre-existing appointment claim
   *
   * @param {number} claim_id - The ID of the pre-existing appointment claim
   * @param {string} claim_form_type - The type of claim form (e.g., "vhi_hospital")
   * @param {string} reason - The reason for creating the hospital claim-form
   * @returns
   */
  async claim_form_composite_create(claim_id, claim_form_type, reason = null) {
    return await super.claim_form_composite_create(claim_id, claim_form_type, reason);
  }

  /**
   * List all defined claim-forms that are part of the hospital-claim for an appointment
   *
   * @param {Object} req - The request object containing claim and hospital information
   * @returns
   */
  async claim_form_composite_list(req) {
    return await super.claim_form_composite_list(req);
  }

  async claim_form_composite_get(req) {
    return await super.claim_form_composite_get(req);
  }

  async claim_form_composite_update(req) {
    return await super.claim_form_composite_update(req);
  }

  async registration_state_change(data) {
    return await super.registration_state_change(data);
  }

  async requirement_update(data) {
    return await super.requirement_update(data);
  }

  async claim_create(claim_id = null, consultant_id = null, insurer_id = null, insurer_name = null, reason = null) {
    return await super.claim_create(claim_id, consultant_id, insurer_id, insurer_name, reason);
  }

  /**
   * List all appointment claims forms for a patient
   *
   * @param {number} appointment_id
   *
   */
  async claimform_patient_list(appointment_id) {
    if (!appointment_id) {
      throw 'claimform_patient_list: appointment_id is not defined';
    }

    return await super.claimform_patient_list({
      appointment: {
        type: 'id',
        appointment_id
      }
    });
  }

  /**
   * Get patient claimform
   *
   * @param {object} locator
   *
   */
  async claimform_patient_get(locator) {
    if (!locator) {
      throw 'claimform_patient_get: locator is not defined';
    }

    return await super.claimform_patient_get({ locator });
  }

  /**
   * Get patient signature
   *
   * @param {object} locator
   *
   */
  async signature_patient_get(locator) {
    if (!locator) {
      throw 'signature_patient_get: locator is not defined';
    }

    return await super.signature_patient_get({ locator });
  }

  /**
   * Set patient signature
   *
   * @param {object} locator
   * @param {string} signature
   *
   */
  async signature_patient_set(locator, signature) {
    if (!locator) {
      throw 'signature_patient_set: locator is not defined';
    } else if (!signature) {
      throw 'signature_patient_set: signature is not defined';
    }

    return await super.signature_patient_set({
      locator,
      signature
    });
  }

  /**
   * Create message
   *
   * @param {object} data
   *
   */
  async message_create(data) {
    return await super.message_create(data);
  }

  async invoice_get(data) {
    return await super.invoice_get(data);
  }

  async invoice_update(data) {
    return await super.invoice_update(data);
  }

  async composite_state_change(data) {
    return await super.composite_state_change(data);
  }

  async consultant_state_change(data) {
    return await super.consultant_state_change(data);
  }

  async list_facilities(data) {
    return await super.list_facilities(data);
  }

  /**
   * Create consultant claimform
   *
   * @param {number} claim_consultant_id
   * @param {number} insurer_id
   * @param {string} claim_form_type
   *
   */
  async claimform_consultant_create(claim_consultant_id, insurer_id, claim_form_type, reason = null) {
    if (!claim_consultant_id) {
      throw 'claimform_consultant_create: claim_consultant_id is not defined';
    } else if (!insurer_id) {
      throw 'claimform_consultant_create: insurer_id is not defined';
    } else if (!claim_form_type) {
      throw 'claimform_consultant_create: claim_form_type is not defined';
    }

    const req = {
      claim_ref: {
        id: claim_consultant_id,
        type: 'id'
      },
      insurer: {
        id: insurer_id,
        type: 'id'
      },
      claim_form_type
    };
    if (reason) req.reason = reason;
    return await super.claimform_consultant_create(req);
  }

  async claimform_get(appointment_id, claim_id, claim_form_id) {
    if (!appointment_id) {
      throw 'claimform_get: appointment_id is not defined';
    } else if (!claim_id) {
      throw 'claimform_get: claim_id is not defined';
    } else if (!claim_form_id) {
      throw 'claimform_get: claim_form_id is not defined';
    }
    const req = {
      appointment_id: appointment_id,
      claim_id: claim_id,
      claim_form_id: claim_form_id
    };

    return await super.claimform_get(req);
  }

  /**
   * Update consultant claimform
   *
   * @param {number} claim_data
   *
   */

  async claimform_update(req) {
    return await super.claimform_update(req);
  }

  /**
   * Delete consultant claimform
   * @param {number} claim_consultant_id
   * @param {number} claim_form_id
   *
   */
  async claimform_consultant_delete(claim_consultant_id, claim_form_id) {
    if (!claim_consultant_id) {
      throw 'claimform_consultant_delete: claim_consultant_id is not defined';
    } else if (!claim_form_id) {
      throw 'claimform_consultant_delete: claim_form_id is not defined';
    }

    const req = {
      claim_ref: {
        id: claim_consultant_id,
        type: 'id'
      },
      claim_form_id
    };
    return await super.claimform_consultant_delete(req);
  }


  /**
   * Delete composite claimform
   * @param {number} claim_composite_id
   * @param {number} claim_form_id
   *
   */
  async claimform_composite_delete(claim_composite_id, claim_form_id) {
    if (!claim_consultant_id) {
      throw 'claimform_composite_delete: claim_composite_id is not defined';
    } else if (!claim_form_id) {
      throw 'claimform_composite_delete: claim_form_id is not defined';
    }

    const req = {
      claim_ref: {
        id: claim_composite_id,
        type: 'id'
      },
      claim_form_id
    };
    return await super.claimform_composite_delete(req);
  }
}

// Methods for RPCs that expect a PDF blob returned.
export class OpenAPIPDF extends OpenAPI {
  content_type() {
    return 'application/pdf';
  }

  response_type() {
    // The data returned from the OpenAPI service is a binary string so for easiest
    // handling, we ask axios to store the response into an array buffer.
    return 'arraybuffer';
  }

  // Call to convert a PDF in an ArrayBuffer into an object suitable for display through
  // the iframe.
  to_blob(array_buf) {
    const pdf_blob = new Blob([array_buf], { type: this.content_type() });
    return URL.createObjectURL(pdf_blob);
  }

  // Call to trigger the browser to open PDF represented in a ArrayBuffer
  _render_pdf(array_buf) {
    const pdf_blob = new Blob([array_buf], { type: this.content_type() });
    const object_url = URL.createObjectURL(pdf_blob);
    let hiddenElement = document.createElement('a');
    hiddenElement.href = object_url;
    hiddenElement.download = 'report.pdf';
    hiddenElement.click();
  }

  _preview_pdf(array_buf) {
    const pdf_blob = new Blob([array_buf], { type: this.content_type() });
    const object_url = URL.createObjectURL(pdf_blob);
    let hiddenElement = document.createElement('a');
    hiddenElement.href = object_url;
    hiddenElement.target = '_blank';
    hiddenElement.click();
  }

  async reportConsulantInsurerPayments(requestData) {
    let data = await super.reportConsulantInsurerPayments(requestData);
    this._render_pdf(data);
  }

  async reportConsulantClaimOutstanding(requestData) {
    let data = await super.reportConsulantClaimOutstanding(requestData);
    this._render_pdf(data);
  }

  async reportConsulantClaimAged(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    start_date = null,
    end_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimAged(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_pdf(data);
  }

  async reportConsulantClaimSubmitted(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    start_date = null,
    end_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimSubmitted(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_pdf(data);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null}insurer_id
   * @param {string| null}insurer_name
   * @param {string| null}rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {OpenAPIDateRange | null} payment_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async paidInvoices(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date = null,
    payment_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.paidInvoices(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      submission_date,
      payment_date,
      claim_status,
      file
    );
    this._render_pdf(data);
  }

  async reportConsulantClaimOutstandingByMonth(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    start_date = null,
    end_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingByMonth(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_pdf(data);
  }

  async reportConsulantClaimOutstandingByInsurer(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    start_date = null,
    end_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingByInsurer(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_pdf(data);
  }

  async reportConsulantClaimOutstandingByProcedure(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    start_date = null,
    end_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingByProcedure(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_pdf(data);
  }

  async reportConsulantClaimOutstandingPended(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    start_date = null,
    end_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingPended(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_pdf(data);
  }

  async reportConsulantClaimOutstandingByRejected(requestData) {
    let data = await super.reportConsulantClaimOutstandingByRejected(requestData);
    this._render_pdf(data);
  }

  async reportConsulantClaimOutstandingRejected(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    start_date = null,
    end_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingRejected(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_pdf(data);
  }

  async reportConsulantClaimOutstandingAwaitingSignoff(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    admitted_start = null,
    admitted_end = null,
    discharge_start = null,
    discharge_end = null
  ) {
    let data = await super.reportConsulantClaimOutstandingAwaitingSignoff(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      admitted_start,
      admitted_end,
      discharge_start,
      discharge_end
    );
    this._render_pdf(data);
  }

  async invoicePdfPreview(claim_obj) {
    let data = await super.invoice_pdf(claim_obj);
    this._preview_pdf(data);
  }

  async claimformPdfPreview(claim_obj) {
    let data = await super.claimform_pdf(claim_obj);
    this._preview_pdf(data);
  }

  async claimformPdfDownload(claim_obj) {
    let data = await super.claimform_pdf(claim_obj);
    this._render_pdf(data);
    this._preview_pdf(data);
  }
}

// Calls service to request data in CSV format and triggers the browser to
// open that file.
export class OpenAPICSV extends OpenAPI {
  content_type() {
    return 'text/csv';
  }

  constructor(fname = null) {
    super();
    this.filename = fname || 'report.csv';
  }

  _render_csv(csv_data) {
    // When a € is present, we need to add the BOM (Byte Order Mark) onto the start of the CSV
    // file so that Windows will properly handle the character encoding as UTF-8 and not some default
    // windows code-page.
    let csvBlob = null;
    if (csv_data.indexOf('€') === -1) {
      csvBlob = new Blob([csv_data], { type: 'text/csv;charset=utf-8' });
    } else {
      csvBlob = new Blob(
        [
          new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
          csv_data
        ],
        { type: 'text/csv;charset=utf-8' }
      );
    }
    const csvURL = URL.createObjectURL(csvBlob);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = csvURL;
    hiddenElement.setAttribute('download', this.filename);

    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);

    URL.revokeObjectURL(csvURL);
  }

  async reportConsulantInsurerPayments(requestData) {
    let data = await super.reportConsulantInsurerPayments(requestData);
    this._render_csv(data);
  }

  async reportConsulantClaimOutstanding(requestData) {
    let data = await super.reportConsulantClaimOutstanding(requestData);
    this._render_csv(data);
  }

  async reportConsulantClaimAged(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    start_date = null,
    end_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimAged(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_csv(data);
  }

  async reportConsulantClaimSubmitted(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    start_date = null,
    end_date = null,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimSubmitted(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_csv(data);
  }

  /**
   *
   * @param {number | null} consultant_id
   * @param {string| null} consultant_name
   * @param {number | null} consultant_person_id
   * @param {number | null}insurer_id
   * @param {string| null}insurer_name
   * @param {string| null}rollup
   * @param {OpenAPIDateRange | null} submission_date
   * @param {OpenAPIDateRange | null} payment_date
   * @param {string| null} claim_status
   * @param file
   * @return {Promise<any>}
   */
  async paidInvoices(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date,
    payment_date,
    claim_status = null,
    file = null
  ) {
    let data = await super.paidInvoices(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      submission_date,
      payment_date,
      claim_status,
      file
    );
    this._render_csv(data);
  }

  async reportConsulantClaimOutstandingByMonth(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingByMonth(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      start_date,
      end_date,
      claim_status,
      file
    );
    this._render_csv(data);
  }

  async reportConsulantClaimOutstandingByInsurer(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingByInsurer(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      submission_date,
      claim_status,
      file
    );
    this._render_csv(data);
  }

  async reportConsulantClaimOutstandingByProcedure(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingByProcedure(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      submission_date,
      claim_status,
      file
    );
    this._render_csv(data);
  }

  async reportConsulantClaimOutstandingPended(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingPended(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      submission_date,
      claim_status,
      file
    );
    this._render_csv(data);
  }

  async reportConsulantClaimOutstandingByRejected(requestData) {
    let data = await super.reportConsulantClaimOutstandingByRejected(requestData);
    this._render_csv(data);
  }

  async reportConsulantClaimOutstandingRejected(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    rollup = null,
    submission_date,
    claim_status = null,
    file = null
  ) {
    let data = await super.reportConsulantClaimOutstandingRejected(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      rollup,
      submission_date,
      claim_status,
      file
    );
    this._render_csv(data);
  }

  async reportConsulantClaimOutstandingAwaitingSignoff(
    consultant_id = null,
    consultant_name = null,
    consultant_person_id = null,
    insurer_id = null,
    insurer_name = null,
    admitted_start = null,
    admitted_end = null,
    discharge_start = null,
    discharge_end = null
  ) {
    let data = await super.reportConsulantClaimOutstandingAwaitingSignoff(
      consultant_id,
      consultant_name,
      consultant_person_id,
      insurer_id,
      insurer_name,
      admitted_start,
      admitted_end,
      discharge_start,
      discharge_end
    );
    this._render_csv(data);
  }

  async searchProcedureCodes(
    claim_ref_id = null,
    consultant_id = null,
    appointment_id = null,
    insurer_id = null,
    insurer_name = null,
    insurer_contract_id = null,
    on_date = null,
    proc_code = null,
    proc_desc = null,
    general = null,
    procedure_codes = null,
    category = null,
    pagination = null
  ) {
    let data = await super.searchProcedureCodes(
      claim_ref_id,
      consultant_id,
      appointment_id,
      insurer_id,
      insurer_name,
      insurer_contract_id,
      on_date,
      proc_code,
      proc_desc,
      general,
      procedure_codes,
      category,
      pagination
    );
    this._render_csv(data);
  }


  async searchCompositeClaims(
      claim_id = null,
      patient_id = null,
      claim_composite_id = null,
      hospital_appointment_id = null,
      invoice_no = null,
      general = null,
      mrn = null,
      full_name = null,
      first_name = null,
      last_name = null,
      consultant_id = null,
      claim_state = null,
      tags = null,
      sort_by = null,
      pagination = null
  ) {
    this._render_csv(
        await super.searchCompositeClaims(
            claim_id,
            patient_id,
            claim_composite_id,
            hospital_appointment_id,
            invoice_no,
            general,
            mrn,
            full_name,
            first_name,
            last_name,
            consultant_id,
            claim_state,
            tags,
            sort_by,
            pagination
        )
    );
  }

  async searchClaims(
      claim_id = null,
      patient_id = null,
      claim_consultant_id = null,
      hospital_appointment_id = null,
      invoice_no = null,
      general = null,
      mrn = null,
      full_name = null,
      first_name = null,
      last_name = null,
      consultant_id = null,
      claim_state = null,
      tags = null,
      sort_by = null,
      pagination = null
    ) {
      this._render_csv(
        await super.searchClaims(
          claim_id,
          patient_id,
          claim_consultant_id,
          hospital_appointment_id,
          invoice_no,
          general,
          mrn,
          full_name,
          first_name,
          last_name,
          consultant_id,
          claim_state,
          tags,
          sort_by,
          pagination
        )
      );
    }
}

// Calls service to request data in plain text format
export class OpenAPIText extends OpenAPI {
  content_type() {
    return 'text/plain';
  }

  async savePreferences(requestData) {
    let data = await super.savePreferences(requestData);
    return data;
  }
}
