<script setup lang="ts">
import { BaseDatePicker, BaseInput, BaseRadioButton, BaseTextarea } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  pursueLegalClaim: {
    type: Boolean,
    default: null
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle
      id="vhi-injury-section-title"
      title="Injury Details - for completion in all cases involving injury (even if no third party is involved)"
    />
    <BaseDatePicker
      id="injury-date"
      class="mt-6"
      name="injuryDate"
      label="Date of injury"
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseInput
      id="injury-place"
      class="mt-6 sm:mt-12"
      name="injuryPlace"
      label="Place of injury"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('injury-place')"
    />
    <BaseTextarea
      id="injury-description"
      class="mt-6 sm:mt-12"
      name="injuryDescription"
      label="Brief description of how the injury occurred"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('injury-description')"
    />
    <BaseRadioButton
      name="pursueLegalClaim"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Do you intend to pursue a legal claim against a third party (parties)?"
      :edit-mode="editMode"
    />
    <BaseTextarea
      v-if="pursueLegalClaim"
      id="solicitor-name"
      name="solicitorName"
      class="mt-6 sm:mt-12 max-input-width-conditional"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="Name of solicitor"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('solicitor-name')"
    />
    <BaseTextarea
      v-if="pursueLegalClaim"
      id="solicitor-address"
      name="solicitorAddress"
      class="mt-6 sm:mt-12 max-input-width-conditional"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="Address of solicitor"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('solicitor-address')"
    />

    <p class="text-sm sm:text-base mt-6 sm:mt-12">
      In consideration of Vhi discharging my hospital and medical expenses to the extent of my cover limits and in
      accordance with the Rules of my contract with Vhi, I agree to include these expenses as part of my current (or
      future) claim against a third party(ies). Where I pursue a claim against a third party, either through the Courts
      or other Tribunals/Boards (and where I have legal representation), I hereby irrevocably authorise the solicitor(s)
      representing me in making that claim to furnish to Vhi an undertaking in the following form: “In consideration of
      Vhi discharging the eligible hospital and medical expenses of my client, I hereby agree to include as part of my
      client’s claim the monies so paid by Vhi (details of which will be supplied to me by Vhi) and subject to any court
      order to the contrary, to repay to Vhi - out of the net proceeds of the settlement that come into our hands – all
      monies recovered in respect of such expenses paid by Vhi.” Where my claim is adjudicated upon by the Injuries
      Board or the Criminal Injuries Compensation Tribunal and where I do not engage legal representation, I hereby
      agree to include as part of my claim the monies so paid by Vhi (details of which will be supplied to me by Vhi)
      and subject to any order/award to the contrary, to repay to Vhi - out of the net proceeds of the settlement that
      come into our hands - all monies recovered in respect of such expenses paid by Vhi. I further authorise Vhi to
      provide the Injuries Board, defence insurer and/or my legal representative with details of all claims paid by Vhi
      relating to my third party case and for the Injuries Board/my legal representative to release to Vhi full details
      of the Injuries Board assessment or other agreed settlement with a third party. In circumstances of an anticipated
      reduced settlement I agree to contact Vhi upon it being made known to me that monies so paid by Vhi may not be
      fully recoverable. When a reduced settlement has been agreed, I will provide Vhi with a Certificate from my legal
      representatives in the format agreed between the Law Society and Vhi confirming that the net proceeds recovered is
      the amount actually recovered. In addition, I agree to provide a Certificate from Counsel (if Counsel was
      instructed in relation to the settlement/hearing), confirming the veracity of the net proceeds recovered.
    </p>
  </div>
</template>
