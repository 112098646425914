<template>
  <div class="component-content-section">
    <div class="optional-nav">
      <SettingsMenu />
    </div>

    <div class="claim-title roboto">
      <span class="medosync-blue mr-2">Account Setting /</span> Tags
      <router-link class="inline-flex no-underline" to="/settings/create-tag">
        <button type="button" class="btn btn-lightblue btn-small ml-10">+ Add Tag</button>
        <button type="button" class="btn btn-lightblue btn-small ml-10" @click="resetTags()">Reset</button>
      </router-link>
    </div>

    <div v-if="userTags && !loading" class="flex flex-col mt-5">
      <table class="min-w-full">
        <thead>
          <tr>
            <th scope="col" class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">Tag Name</th>
            <th scope="col" class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">Colour</th>
            <th scope="col" class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">Type</th>
            <th scope="col" class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">Action</th>
          </tr>
        </thead>

        <tbody>
          <!-- Hospital tags -->
          <tr v-for="(tag, index) in hospitalTags" :key="index" class="bg-white">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ tag.name }}
            </td>
            <td class="text-center font-medium">
              <div v-if="tag.color" class="tag-color" :style="{ background: tag.color }"></div>
              <div v-if="!tag.color">No Color</div>
            </td>
            <td class="text-center font-medium text-green-600">Hospital</td>
            <td class="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
              <button @click="deleteTag(tag, 'hospital', index)" type="button" class="btn btn-lightblue btn-small ml-5">
                Delete
              </button>
            </td>
          </tr>

          <!-- User tags -->
          <tr v-for="(tag, index) in userTags" :key="index" class="bg-white">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ tag.name }}
            </td>
            <td class="text-center font-medium">No color</td>
            <td class="text-center font-medium text-purple-600">User</td>
            <td class="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
              <button @click="deleteTag(tag, 'user', index)" type="button" class="btn btn-lightblue btn-small ml-5">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import { userStore } from '@/store/user';

import SettingsMenu from '@/views/settings/menu/Index.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'SettingsTags',
  components: {
    SettingsMenu,
    ToastComponent
  },
  data() {
    return {
      userTags: [],
      hospitalTags: [],
      searchString: '',
      userPrefs: null,
      loading: true
    };
  },
  computed: {},

  methods: {
    async init() {
      this.loading = true;
      await this.getUserTagList();
      this.loadTags();
      this.loading = false;
    },

    loadTags() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      this.hospitalTags = ls.tagsLayout;
    },

    async getUserTagList() {
      const oapi = new OpenAPIJSON();
      const response = await oapi.tagList();
      this.userTags = response.user;
      //this.hospitalTags = response.hospital
    },

    async _build_delete_request(tag, type) {
      let requestData = {};

      const email = userStore.getters.user?.email;

      requestData.name = tag.name;
      if (type == 'user') {
        requestData.namespace = { email: email, user_type: 'email', namespace: 'user' };
      }
      return requestData;
    },

    async deleteTag(tag, type = 'user', index) {
      const requestData = await this._build_delete_request(tag, type);
      const oapi = new OpenAPIJSON();
      try {
        await oapi.tagDelete(requestData);
        if (type == 'user') {
          this.userTags.splice(index, 1);
        } else {
          this.hospitalTags.splice(index, 1);
        }
        this.$refs.toast.showToast('Tag Deleted!', 'fas fa-check', 'success');

        let ls = JSON.parse(localStorage.getItem('preferences'));
        ls.tagsLayout.forEach((item, index) => {
          if (item.name == tag.name) {
            ls.tagsLayout.splice(index, 1);
          }
        });
        localStorage.preferences = JSON.stringify(ls);
        await this.saveUserPreferences(ls);
      } catch (err) {
        return this.$refs.toast.showToast(err.message, 'fa-solid fa-triangle-exclamation', 'error');
      }
      this.init();
    },

    async saveUserPreferences(data) {
      const oapi = new OpenAPIJSON();

      let response = await oapi.saveUserPreferences(JSON.stringify(data));

      if (response && response.status && response.status >= 300) {
        this.$refs.toast.showToast(response.data.status.reason, 'fa-solid fa-triangle-exclamation', 'error');
      } else {
        this.$refs.toast.showToast('Tags preference successfully saved!', 'fas fa-check', 'success');
      }
    },

    async resetTags() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      ls.tagsLayout = null;
      localStorage.preferences = JSON.stringify(ls);
      await this.saveUserPreferences(ls);
    }
  },

  mounted() {
    this.init();
    let ls = JSON.parse(localStorage.getItem('preferences'));
    console.log('Tags', ls.tagsLayout);
  }
};
</script>

<style lang="scss" scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}

.roboto {
  font-family: 'Roboto';
}
.claim-title {
  font-size: 26px;
  font-weight: 600;
}
.btn {
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.btn-small {
  font-size: 14px;
  font-weight: 600;
}
.btn-lightblue {
  background-color: #00a7e1;
  color: #ffffff;
}
.btn-hollow-blue {
  background-color: rgba($color: #ffffff, $alpha: 0);
  color: #23a6f0;
  border: 1px solid #23a6f0;
}
.medosync-blue {
  color: #00a7e1;
}
.medosync-black {
  color: #1c1c1c;
}
table {
  font-size: 14px;
}
th {
  background-color: #d1d1d1;
}
tr:nth-child(odd) {
  background-color: #eeeff3;
}
tr:nth-child(even) {
  background-color: #ffffff;
}
.tag-color {
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
}
</style>
