<template>
  <div
    class="menu-transition overflow-hidden"
    :class="isActive ? 'component-content-section' : 'component-content-section-no-menu'"
  >
    <div class="optional-nav">
      <SettingsMenu @set-menu="setMenuActive" />
    </div>

    <div class="s-view-title">Menu Settings / <span>View</span></div>

    <div v-if="menuItems" class="custom-table">
      <div class="table-header">
        <div class="dashboard-item overflow-auto">
          <div class="item-grip">#</div>
          <div class="item-name" style="text-align: left">Field Name</div>
          <div class="item-active">Show</div>
        </div>
      </div>

      <div v-for="(item, index) in menuItems" :key="index" class="dashboard-item overflow-auto">
        <div class="item-grip">
          <i class="fas fa-link pl-1"></i>
        </div>
        <div class="item-name">
          {{ item.name }}
        </div>
        <div class="item-active">
          <Toggle v-model:checked="item.active" labelFalse="Hidden" labelTrue="Showing" />
        </div>
      </div>
    </div>

    <div class="s-btn-primary mt-5" @click="updatePreferences()">SAVE</div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import SettingsMenu from '@/views/settings/menu/Index.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import Toggle from '@/components/elements/Toggle.vue';
import { OpenAPIJSON } from 'api/openapi';

export default {
  name: 'Menu-settings',
  components: {
    Toggle,
    SettingsMenu,
    ToastComponent
  },
  data() {
    return {
      menuItems: [
        { name: 'Draft Claims', link: 'draft', count: 0, active: true },
        {
          name: 'Consultant Approval Requested',
          active: true,
          link: '/navigator/claims/ready_for_consultant_approval/none'
        },
        { name: 'Approved By Consultant', active: true, link: '/navigator/claims/consultant_approved/none' },
        { name: 'Ready For Submission', active: true, link: '/navigator/claims/ready_for_send_to_insurer/none' },
        { name: 'Sent To Billing', active: true, link: '/navigator/claims/manual_rejected/none' },
        { name: 'Sent Back To Billing', active: true, link: '/navigator/claims/rejected/none' },
        {
          name: 'Rejected By Consultant',
          active: true,
          link: '/navigator/claims/rejected_by_consultant_for_amendment/none'
        },
        { name: 'All Claims', active: true, link: '/navigator/claims/all/none' }
      ],
      isActive: true
    };
  },
  methods: {
    // apply perference if exist
    applyPreferences() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      let menuLayout = ls.menuLayout;

      if (menuLayout) {
        this.menuItems.forEach(m => {
          menuLayout.forEach(item => {
            if (m.name == item.name) {
              m.active = item.active;
            }
          });
        });
      }
    },

    updatePreferences() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      ls.menuLayout = this.menuItems;
      localStorage.preferences = JSON.stringify(ls);
      this.saveUserPreferences(ls);
    },

    async saveUserPreferences(data) {
      const oapi = new OpenAPIJSON();

      let response = await oapi.saveUserPreferences(JSON.stringify(data));

      if (response && response.status && response.status >= 300) {
        this.$refs.toast.showToast(response.data.status.reason, 'fa-solid fa-triangle-exclamation', 'error');
      } else {
        this.$refs.toast.showToast('Menu preference successfully saved!', 'fas fa-check', 'success');
      }
    },

    setMenuActive(bool) {
      this.isActive = bool;
    }
  },
  mounted() {
    this.applyPreferences();
  }
};
</script>

<style lang="scss" scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}

.item-grip {
  float: left;
  width: 30px;
  padding-left: 10px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.5;
  }
}

.item-name {
  float: left;
  width: 400px;
  padding-left: 45px;
}

.item-active {
  float: right;
  width: 150px;
}

.custom-table {
  width: 100%;
  margin-top: 30px;
  border: 1px solid #d1d1d1;
  border-radius: 8px 8px 0px 0px;
  overflow: auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #707070;

  .table-header {
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 7px 7px 0px 0px;
    background: #c3c6d2;
  }

  .dashboard-item {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #d1d1d1;
    overflow: hidden;
  }
}
</style>
