<template>
  <div>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <div v-if="hospitalGroupId === 2">
          <img class="mx-auto pl-5 h-16 w-auto" src="@/assets/img/bons-logo.png" alt="Workflow" />
        </div>
        <div v-if="hospitalGroupId === 1">
          <img class="mx-auto pl-5 h-16 w-auto" src="@/assets/img/medosync-logo.png" alt="Workflow" />
        </div>
        <h2 class="mt-5 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            <template v-if="hospitalGroupId === 1">Welcome to Medosync Navigator</template>
            <template v-if="hospitalGroupId === 2">Welcome to Bon Secours Hospital Consultant Portal</template>
          </a>
        </p>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div class="space-y-6">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700"> Email address </label>
              <div class="mt-1">
                <input
                  v-model="user.email"
                  type="email"
                  autocomplete="email"
                  data-test-id="username-input"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
              <div class="mt-1">
                <input
                  v-model="user.password"
                  type="password"
                  autocomplete="current-password"
                  data-test-id="password-input"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Remember me </label>
              </div>

              <div class="text-sm">
                <router-link to="/reset-password" href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </router-link>
              </div>
            </div>

            <div>
              <button
                @click="login()"
                type="submit"
                data-test-id="loginButton"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import { userStore } from '@/store/user';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'AuthLogin',
  components: { ToastComponent },
  data() {
    return {
      user: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    handleKeyup(e) {
      if (e.key === 'Enter') {
        this.login();
      }
    },

    async login() {
      // if email or password is empty return
      this.user.email = this.user.email.trim();
      this.user.password = this.user.password.trim();
      if (this.user.email.length < 4) {
        localStorage.removeItem('_token');
        this.$refs.toast.showToast('User name is too short', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }
      if (this.user.password.length < 4) {
        localStorage.removeItem('_token');
        this.$refs.toast.showToast('Password is too short', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      const oapi = new OpenAPIJSON();
      try {
        let response = await oapi.login(this.user.email, this.user.password);

        // Must force update of localStorage cache of /me data after login
        // otherwise swapping logins can use data from previous login.
        localStorage._token = response.access_token;

        await userStore.dispatch('getUserInfo');

        let envTokenExpire = import.meta.env.VITE_TOKEN_EXPIRY;

        const now = new Date();
        const expiration = new Date(now.getTime() + envTokenExpire * 60 * 1000);

        localStorage.setItem('tokenExpiration', expiration.toISOString());

        localStorage.setItem('selectedConsultantReport', '');
        await this.setPreferences(JSON.parse(userStore.getters.user?.preferences));
        let COMPOSITE_HOSTS = [
          'dev.hospital.medosync.com',
          'uat.hospital.medosync.com',
          'uat.beacon.medosync.com',
          'beacon.medosync.com'
        ];

        const hostname = window.location.hostname;

        if (COMPOSITE_HOSTS.includes(hostname)) {
          window.location.href = '/navigator/compositeclaims/draft/all';
        } else {
          window.location.href = '/navigator/claims/draft/all';
        }
      } catch (error) {
        console.log('login failed: ', error);
        if (Object.hasOwn(error, 'response') && Object.hasOwn(error.response, 'data')) {
          if (error.response?.data?.reason) {
            if (error.response.data.reason.startsWith('LOGIN-002')) {
              this.$refs.toast.showToast('Username is not known', 'fa-solid fa-triangle-exclamation', 'error');
              localStorage.removeItem('_token');
              return;
            } else if (error.response.data.reason.startsWith('LOGIN-001')) {
              this.$refs.toast.showToast(
                'Username or password is not known',
                'fa-solid fa-triangle-exclamation',
                'error'
              );
              localStorage.removeItem('_token');
              return;
            }
          }
        } else {
          this.$refs.toast.showToast('Service connection error', 'fa-solid fa-triangle-exclamation', 'error');
        }
        sessionStorage.clear();
        localStorage.removeItem('_token');
      }
    },

    async setPreferences(data) {
      //define preference object here
      //use whole object for saveUserPreferences()
      let preferences = {
        tableLayout: null,
        tagsLayout: null,
        menuLayout: null
      };

      // load data if it is already defined
      if (Object.keys(data).length > 0) {
        preferences = data;
      }

      // set table layout to default columns and columns width
      if (!data.tableLayout) {
        preferences.tableLayout = [
          {
            name: 'Index',
            active: true,
            width: 35
          },
          {
            name: 'Patient',
            active: true,
            width: 130
          },
          {
            name: 'MRN',
            active: true,
            width: 100
          },
          {
            name: 'AppID',
            active: true,
            width: 120
          },
          {
            name: 'LOS',
            active: true,
            width: 60
          },
          {
            name: 'Admitted',
            active: true,
            width: 100
          },
          {
            name: 'Discharged',
            active: true,
            width: 100
          },
          {
            name: 'Insurer',
            active: true,
            width: 130
          },
          {
            name: 'Invoice Consultant',
            active: true,
            width: 140
          },
          {
            name: 'Status',
            active: true,
            width: 130
          },
          {
            name: 'Note',
            active: true,
            width: 90
          },
          {
            name: 'Tag',
            active: true,
            width: 100
          },
          {
            name: 'Menage',
            active: true,
            width: 120
          },
          {
            name: 'Action',
            active: true,
            width: 100
          }
        ];
      }

      // if there are no tags defines get default tags for logged user
      if (!data.tagsLayout) {
        preferences.tagsLayout = await this.getTagList();
      }

      //save it to localStorage
      localStorage.preferences = JSON.stringify(preferences);
    },

    async getTagList() {
      const oapi = new OpenAPIJSON();
      let response = await oapi.tagList();
      console.log('response tags', response);

      response.hospital.forEach(item => {
        item.active = true;
        item.color = '#00A7E1';
      });

      return response.hospital;
    }
  },

  created() {
    localStorage.removeItem('_token');
    localStorage.removeItem('tokenExpiration');
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyup);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.handleKeyup);
  }
};
</script>

<style scoped></style>
