<template>
  <div
    class="menu-transition overflow-hidden"
    :class="isActive ? 'component-content-section' : 'component-content-section-no-menu'"
  >
    <div class="optional-nav">
      <SettingsMenu @set-menu="setMenu" />
    </div>

    <div class="s-view-title">Dashboard Settings / <span>View</span></div>

    <div class="custom-table">
      <div class="table-header">
        <div class="dashboard-item overflow-auto">
          <div class="item-grip">Sort</div>
          <div class="item-name" style="text-align: left">Field Name</div>
          <div class="item-active">Show</div>
        </div>
      </div>

      <draggable
        v-model="dashboardItems"
        group="people"
        @start="drag = true"
        @end="drag = false"
        item-key="id"
        class="col-12"
      >
        <template #item="{ element }">
          <div v-if="element.name != 'Manage' && element.name != 'Action'" class="dashboard-item overflow-auto">
            <div class="item-grip">
              <i class="fas fa-th"></i>
            </div>
            <div class="item-name">
              {{ element.name }}
            </div>
            <div class="item-active">
              <Toggle v-model:checked="element.active" labelFalse="Hidden" labelTrue="Showing" />
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <div class="s-btn-primary mt-5" @click="updatePreferences()">SAVE</div>

    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import SettingsMenu from '@/views/settings/menu/Index.vue';

import draggable from 'vuedraggable';
import Toggle from '@/components/elements/Toggle.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import { OpenAPIJSON } from 'api/openapi';

export default {
  name: 'Dashboard-settings',
  components: {
    draggable,
    Toggle,
    SettingsMenu,
    ToastComponent
  },
  data() {
    return {
      drag: false,
      dashboardItems: [
        { name: 'Index', active: true, width: 35 },
        { name: 'Patient', active: true, width: 130 },
        { name: 'MRN', active: true, width: 100 },
        { name: 'Appointment ID', active: true, width: 120 },
        { name: 'Length of Stay (LOS)', active: true, width: 60 },
        { name: 'Admitted', active: true, width: 100 },
        { name: 'Discharged', active: true, width: 100 },
        { name: 'Insurer', active: true, width: 130 },
        { name: 'Invoicing Consultant', active: true, width: 140 },
        { name: 'Status', active: true, width: 130 },
        { name: 'Note', active: true, width: 90 },
        { name: 'Tag', active: true, width: 100 },
        { name: 'Manage', active: true, width: 120 },
        { name: 'Action', active: true, width: 100 },
        { name: 'Hospital Claim Value', active: true, width: 60 },
        { name: 'Consultant Claim Value', active: false, width: 60 }, // [15]
        { name: 'Consultant Claim Paid Value', active: false, width: 120 }, // [16]
        { name: 'Consultant Claims Invoice Number', active: false, width: 100 } // [17]
      ],
      preferences: null,
      isActive: true
    };
  },
  methods: {
    // apply perference if exist
    applyPreferences() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      let tableLayout = ls.tableLayout;

      if (tableLayout) {
        this.dashboardItems.forEach(t => {
          tableLayout.forEach(item => {
            if (t.name == item.name) {
              t.active = item.active;
            }
          });
        });
      }
    },

    async saveUserPreferences(data) {
      const oapi = new OpenAPIJSON();

      let response = await oapi.saveUserPreferences(JSON.stringify(data));

      if (response && response.status && response.status >= 300) {
        this.$refs.toast.showToast(response.data.status.reason, 'fa-solid fa-triangle-exclamation', 'error');
      } else {
        this.$refs.toast.showToast('Dashboard preference successfully saved!', 'fas fa-check', 'success');
      }
    },

    updatePreferences() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      ls.tableLayout = this.dashboardItems;
      localStorage.preferences = JSON.stringify(ls);
      this.saveUserPreferences(ls);
    },
    setMenu(bool) {
      this.isActive = bool;
    }
  },
  mounted() {
    this.applyPreferences();
  }
};
</script>

<style lang="scss" scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}

.item-grip {
  float: left;
  width: 30px;
  padding-left: 10px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.5;
  }
}

.item-name {
  float: left;
  width: 400px;
  padding-left: 45px;
}

.item-active {
  float: right;
  width: 150px;
}

.custom-table {
  width: 100%;
  margin-top: 30px;
  border: 1px solid #d1d1d1;
  border-radius: 8px 8px 0px 0px;
  overflow: auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #707070;

  .table-header {
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 7px 7px 0px 0px;
    background: #c3c6d2;
  }

  .dashboard-item {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #d1d1d1;
    overflow: hidden;
  }
}
</style>
