<script setup lang="ts">
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import InputErrorMessage from './InputErrorMessage.vue';

defineComponent({ InputErrorMessage });

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  options: {
    type: Object,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  editMode: {
    type: Boolean,
    default: true
  },
  required: {
    type: Boolean,
    default: false
  }
});

const { handleChange, errorMessage, meta } = useField(() => props.name, undefined, {
  type: 'radio'
});

const styleHyphenFormat = (propertyName: string) => {
  function upperToHyphenLower(match: string, offset: number) {
    return (offset > 0 ? '-' : '') + match.toLowerCase();
  }
  return propertyName.replace(/[A-Z]/g, upperToHyphenLower);
};
</script>

<template>
  <div :id="`${styleHyphenFormat(name)}-container`">
    <div class="flex">
      <label class="text-sm sm:text-base font-bold" :class="{ 'text-medosync-red': errorMessage && required }">
        {{ label }}</label
      >
    </div>
    <div v-if="editMode" class="mt-3 sm:mt-4 flex flex-wrap gap-x-16 sm:gap-x-11 gap-y-3">
      <div v-for="(value, key) in options" :key="key" class="flex items-center">
        <label class="text-sm sm:text-base font-bold mr-6" :class="{ 'text-medosync-red': errorMessage && required }">
          {{ key }}
        </label>
        <input
          :name="name"
          :value="value"
          :checked="meta.initialValue === value || (meta.initialValue === undefined && value === null)"
          :disabled="!editMode"
          type="radio"
          class="custom-radio"
          :class="{
            'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': errorMessage && required
          }"
          @click="handleChange(value)"
        />
      </div>
    </div>
    <div v-else-if="meta.initialValue !== undefined" class="mt-3 sm:mt-4">
      <div v-for="(value, key) in options" :key="key" class="flex items-center">
        <label v-if="meta.initialValue === value" class="text-lg text-gray-500 ml-4 mr-6">
          {{ key }}
        </label>
      </div>
    </div>
    <div v-else class="mt-3 sm:mt-4">
      <div class="flex items-center">
        <label class="text-lg text-gray-500 ml-4 mr-6">N/A</label>
      </div>
    </div>
    <InputErrorMessage v-if="errorMessage && required">This field is required</InputErrorMessage>
  </div>
</template>

<style scoped>
.custom-radio {
  @apply appearance-none border-2 rounded-full h-10 w-10 sm:h-12 sm:w-12 cursor-pointer;
}

.custom-radio:checked {
  background-color: white;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #00a7e1;
}

.custom-radio:checked::before {
  content: '';
  display: block;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: #00a7e1;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
