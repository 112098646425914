<template>
    <div class="menu-transition" :class="isActive ? 'component-content-section' : 'component-content-section-no-menu'">
        <div class="optional-nav">
          <SettingsMenu @set-menu="setMenuActive" />
        </div>

        <div>

        <div class="claim-title medo-secondary">
          <router-link to="/settings/groups">
              <i class="fas fa-chevron-left medo-primary mr-5 custom-back"></i> 
          </router-link>
          Create new group
        </div>

        <div style="clear: both"></div>

        <!-- start -->
        <div class="col-12 mt-5 overflow-auto">
            <div class='col-6 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Group Name:</label>
                </div>
                <div class="mt-1">
                    <input v-model='group.name' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>

            <div class='col-6 pr-5'>
                <div class="flex justify-between">
                    <label for="email" class="block text-sm font-medium text-gray-700">Group Description:</label>
                    <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                </div>
                <div class="mt-1">
                    <input v-model='group.desc' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" aria-describedby="email-optional">
                </div>
            </div>


        </div>
        <!-- end -->

        <div style="clear: both"></div>


        <div class="col-12 mt-8 text-right">
          <button @click="createGroup" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Create Group
          </button>
        </div>

      </div>

      <ToastComponent ref="toast"></ToastComponent>
    </div>
</template>

<script>
import api from 'api/api'
import SettingsMenu from '@/views/settings/menu/Index.vue'
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'Create-group',
  components: {
    SettingsMenu,
    ToastComponent
  },
  data () {
    return {
      group: {
        name: "",
        desc: ""
      },
      isActive: true,
    }
  },
  computed: {

  },
  methods: {
      async createGroup() {
        await api.createAclGroup(this.group.name, this.group.desc)
            .then(() => {
              this.$refs.toast.showToast('New Group Added!', 'fas fa-check', 'success');
            })
            .catch((error) => {
              this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
            });
      },
      setMenuActive (bool) {
        this.isActive = bool;
      }
  },
  created () {
    
  },
  mounted () {
    
  }
}
</script>

<style lang='scss' scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #EDEDED;
}

  .custom-back {
      font-size: 28px;
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
          opacity: 0.65;
      }
  }

  .claim-title  {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 600;
  }

  .custom-input {
    height: 40px;
    padding-left: 8px;
    line-height: 40px;
    border: 1px solid #dedede;
  }

  .custom-select {
    height: 40px;
    padding-left: 8px;
    line-height: 40px;
    border: 1px solid #dedede;
  }
</style>